import React, {useState} from "react";
import {Link} from "react-router-dom";
import './Favorites.scss'
import {useSelector} from "react-redux";
import Icon from "@material-ui/core/Icon";
import {getFiltersTitles, getParentCategories, getFiltersIds} from "../../store/selectors/filters";
import When from '../../components/When'
import {format} from "date-fns";

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}

const FavoriteCard = ({data, onClick, district}) => {
    const filtersTitles = useSelector(getFiltersTitles)
    const [showAll, setShowAll] = useState({state: false, value: 3});
    const parentIds = useSelector(getParentCategories)

    const selectedClassifiers = (items) => {
        const classifiersData = []
        filtersTitles.forEach((item) => {
            items.results[0].classifier_ids && items.results[0].classifier_ids.forEach((classif) => {
                if (item.id === classif) {
                    classifiersData.push(item)
                }
            })
        })
        if (classifiersData.length) {
            return (
                <div className='selected-classifier'>
                    {
                        classifiersData && classifiersData.map((item) => {
                            return (
                                <span className='item'>{item.title}</span>
                            )
                        }).slice(0, !showAll.state ? 0 : classifiersData.length)
                    }
                    <When is={classifiersData && classifiersData.length >= 1}>
                        <span
                            style={{backgroundColor: '#DE8F00'}}
                            onClick={() => {
                                setShowAll({state: !showAll.state, value: !showAll.showAll ? 4 : classifiersData.length})
                            }}
                            className='item show-more'>
                            {
                                !showAll.state ? (`Показати обранi категорії (${classifiersData && classifiersData.length})`) : 'Сховати'
                            }
                        </span>
                    </When>
                </div>
            )
        } else {
            return null
        }
    }
    const selectDistrictRouting = (statistic) => {
        if(statistic.results[0].classifier_ids !== null){
            const classifier_ids = statistic.results[0].classifier_ids;
            let district_id = statistic.results[0].district_ids[0];
            return (
                <Link
                    to={`/dashboard/main-departments/service-requests/statistics-by-district/${classifier_ids}/${district_id}/${statistic.results[0].created_after}/${statistic.results[0].created_before}/${false}/${statistic.results[0].is_accident === null ? null : true}`}
                    className='card-title'>
                    <span className='title-name'>
                        {statistic.title.text}
                        <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                    </span>
                </Link>
            )
        }else{
            const classifier_ids = statistic.results[0].classifier_ids;
            let district_id = statistic.results[0].district_ids[0]
            return (
                <Link
                    to={`/dashboard/main-departments/service-requests/statistics-by-district/${classifier_ids}/${district_id}/${statistic.results[0].created_after}/${statistic.results[0].created_before}/${false}/${statistic.results[0].is_accident === null ? null : true}`}
                    className='card-title'>
                    <span className='title-name'>
                        {statistic.title.text}
                        <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                    </span>
                </Link>
            )
        }
    }
    const selectSummaryDistrictRouting = (statistic, sum) => {
        if(statistic.results[0].classifier_ids !== null){
            const classifier_ids = statistic.results[0].classifier_ids;
            let district_id = statistic.results[0].district_ids[0];
            return (
                <Link
                    // to={`/dashboard/main-departments/service-requests/classifiers/${classifier_ids}/district/${district_id}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}`}>
                    to={`/dashboard/main-departments/service-requests/statistics-by-district/${classifier_ids}/${district_id}/${statistic.results[0].created_after}/${statistic.results[0].created_before}/${false}/${statistic.results[0].is_accident === null ? null : true}`}>

                    <div className='count general-count'>
                        <span>Усього</span>
                        <span className='sum-arrow'>
                            <span className='summary-text'>{sum}</span>
                            <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                        </span>
                    </div>
                </Link>
            )
        }else{
            let district_id = statistic.results[0].district_ids[0]
            return (
                <Link
                    to={`/dashboard/main-departments/service-requests/district/${district_id}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}/${statistic.results[0].is_accident === null ? null : true}`}>
                     <div className='count general-count'>
                        <span>Усього</span>
                        <span className='sum-arrow'>
                            <span className='summary-text'>{sum}</span>
                            <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                        </span>
                    </div>
                </Link>
            )
        }
    }

    const selectStatusRouting = (status) => {
        if(status.classifier_ids !== null) {
            return (
                <Link
                    to={`/dashboard/main-departments/service-requests/category-district-details/district-category-status-classifiers/${status.district_ids}/${status.id}/${status.classifier_ids}/${status.created_after}/${status.created_before}/${status.is_accident === null ? null : true}`}
                    className='list-item'>
                    {status.name} <span
                    style={{color: status.id === 11 ? '#138481' : '#FD0101'}}> {status.events_count}</span>
                    <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                </Link>
            )
        }else{
            return (
                <Link
                    to={`/dashboard/main-departments/service-requests/category-district-details/district-category-status/${status.district_ids}/${status.id}/${status.created_after}/${status.created_before}/${status.is_accident === null ? null : true}`}
                    className='list-item'>
                    {status.name} <span
                    style={{color: status.id === 11 ? '#138481' : '#FD0101'}}> {status.events_count}</span>
                    <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                </Link>
            )
        }

    }
    return (
        <div>
            {
                data && data.map((statistic) => {
                    const sum = statistic.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next);
                    return (
                        <div className="requests-summary">
                            <div className="card-details">
                                <div className="card-date">
                                    <span>На основі даних від {format(new Date(statistic.results[0].created_after), `dd-MM-yyyy`)} до {format(new Date(statistic.results[0].created_before), `dd-MM-yyyy`)}</span>
                                </div>
                                <div className='card-header'>
                                    {
                                        statistic.title.type === 'classifier' || statistic.title.type === 'classifier-top' ?
                                            <Link
                                                to={statistic.title.type === 'classifier' ? `/dashboard/main-departments/service-requests/classifier/${statistic.title.id}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}/${statistic.results[0].is_accident === null ? null : true}` : `/dashboard/main-departments/service-requests/classifier/${statistic.results[0].classifier_ids}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}/${statistic.results[0].is_accident === null ? null : true}`}
                                                className='card-title'>
                                                <span className='title-name'>
                                                    {statistic.title.text}
                                                    <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                                                </span>
                                            </Link>
                                            :
                                            selectDistrictRouting(statistic)
                                    }

                                    <Icon onClick={() => onClick(statistic)} style={{color: '#DE8F00'}}>star</Icon>
                                </div>
                                <When is={statistic.results[0].is_accident !== null}>
                                    <div className='accident-title'>Аварiна</div>
                                </When>
                                <When is={district}>
                                    {selectedClassifiers(statistic)}
                                </When>

                                    {
                                        statistic.title.type === 'classifier' || statistic.title.type === 'classifier-top' ?
                                            <Link
                                                to={statistic.title.type === 'classifier' ? `/dashboard/main-departments/service-requests/classifier/${statistic.title.id}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}/${statistic.results[0].is_accident === null ? null : true}` : `/dashboard/main-departments/service-requests/classifier/${statistic.results[0].classifier_ids}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}/${statistic.results[0].is_accident === null ? null : true}`}>
                                                <div className='count general-count'>
                                                    <span>Усього</span>
                                                        <span className='sum-arrow'>
                                                        <span className='summary-text'>{sum}</span>
                                                        <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                                    </span>
                                                </div>
                                            </Link>
                                            :
                                            selectSummaryDistrictRouting(statistic, sum)
                                    }
                                <ul className='details-list'>
                                    {
                                        statistic.results.map((status) => {
                                            return (
                                                <li key={status.id}>
                                                    <When is={status.events_count === 0}>
                                                        <div className='overlay'></div>
                                                    </When>
                                                    {
                                                        status.district_ids === null ?
                                                        <Link
                                                            to={`/dashboard/main-departments/favorites/status/${status.id}/classifier/${status.classifier_ids[0]}/startDate/${status.created_after}/endDate/${status.created_before}`}
                                                            className='list-item'>
                                                            {status.name} <span
                                                            style={{color: status.id === 11 ? '#138481' : '#FD0101'}}> {status.events_count}</span>
                                                            <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                                        </Link> : selectStatusRouting(status)
                                                    }

                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

export default FavoriteCard;
