import {EventsApiService} from "../../api/events.api";
import {getEventsSuccess, httpLoadingStart, httpLoadingEnd} from './actionCreators'
import {format} from "date-fns";


export const getEvents = (district, id, classifierId, startDate, endDate, isAccident) => {
    return (dispatch, getState) => {
        dispatch(httpLoadingStart());
        const state = getState()
        const object = {
            districtIds: district,
            statusIds: id && id !== 'null' ? id : '',
            classifierIds: classifierId ? classifierId : '',
            createdAfter: format(new Date(startDate), `yyyy-MM-dd'T'HH:mm:ss`),
            createdBefore: format(new Date(endDate), `yyyy-MM-dd'T'HH:mm:ss`),
            isAccident: isAccident,
            noPage: true
        }
        const paramsString = Object
            .entries(object)
            .filter(object => object[1])
            .map((object) => {
                return object.join('=');
            }).join('&');
        EventsApiService.getEvents(paramsString)
            .then(response => {
                dispatch(httpLoadingEnd());
                dispatch(getEventsSuccess(response));
            })
            .catch(err => {
            });
    };
};

