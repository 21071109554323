import React, {useEffect, useState} from "react";
import './../ServiceRequests.scss'
import {Link, useParams} from "react-router-dom";
import TimeFilter from "../../../components/timeFilter/TimeFilter";
import {useDispatch, useSelector} from "react-redux";
import {
    getDistrictStatistics,
    getSummaryStatisticsByClassifiers,
} from '../../../store/actions/stats';

import {
    getStatistics, getStatisticsByDistrict
} from '../../../store/selectors/stats'
import {
    setTabsView,
} from "../../../store/actions/actionCreators";
import When from "../../../components/When";
import Icon from "@material-ui/core/Icon";
import RequestsSummaryStatusLine from "../../../components/requestsSummaryStatusLine/RequestsSummaryStatusLine";
import {addToFavorite, removeFavoriteClassifier} from "../../../store/actions/favorites";
import Header from "../../../components/Header/Header";

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}

const StatisticByDistrictsCategorySecondLevel = () => {
    const params = useParams()
    const statisticsByCat = useSelector(getStatistics)
    const statisticsByDistrict = useSelector(getStatisticsByDistrict)
    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });
    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSummaryStatisticsByClassifiers(params && params.district_id && params.district_id ? params.district_id :[1, 2, 3, 4, 5, 6, 7, 8, 9], params.classifiers, startDate ? startDate : params.startDate, endDate ? endDate : params.endDate, params.isAccident))
    }, [settings]);

    const [isFavorite, updateFavorite] = useState(false);

    const {
        user: {
            settings: {
                timePeriod: {
                    period
                }
            }
        }
    } = useSelector(state => {
        return state.auth;
    });

    const {
        accidentState
    } = useSelector(state => {
        return state.stats;
    });

    const toggleFavorite = (statistic) => {
        const data = {
            classifierIds: statistic.title.id,
            title: statistic.title.text,
            type: 'classifier',
            isAccident: accidentState ? true : null,
            timePeriod: {
                period: period,
                startDate: startDate,
                endDate: endDate
            }
        }
        dispatch(addToFavorite(data))
        updateFavorite(!isFavorite)

        setTimeout(() => {
            updateFavorite(false)
        }, 2000)
    }
    const removeFromFavoriteClassifier = (item) => {
        dispatch(removeFavoriteClassifier(item))
    }

    return (
        <div>
            <Header
                showFilters={true}
                headerTitle={'Звернення до служби 1562'}/>
                <div className="service-page">
                    {
                        statisticsByCat && statisticsByCat.length ? statisticsByCat.map((statistic, index) => {
                            const sum = statistic.results.length ? statistic.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : 0;
                            return (
                                <div key={index} className="requests-summary">
                                    <div className="card-details">
                                        <When is={isFavorite}>
                                            <div className='success-alert'>
                                                Картка добавлена в улюблене
                                            </div>
                                        </When>
                                        <div className='card-header'>
                                            <div className='card-title'>
                                                <Link
                                                    to={`/dashboard/main-departments/service-requests/district/${statistic.results[0].district_ids[0]}/classifiers/${statistic.results[0].classifier_ids[0]}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}`}>
                                            <span className='title-name'>
                                                {statistic.title.text}
                                                <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                                            </span>
                                                </Link>
                                            </div>
                                            {
                                                statistic.title.isFavorite ?
                                                    <Icon onClick={() => removeFromFavoriteClassifier(statistic)}
                                                          style={{color: '#DE8F00'}}>star</Icon>
                                                    :
                                                    <Icon onClick={() => toggleFavorite(statistic)}
                                                          style={{color: '#D8D8D8'}}>star_border</Icon>
                                            }
                                        </div>
                                        <Link
                                            to={`/dashboard/main-departments/service-requests/district/${statistic.results[0].district_ids[0]}/classifiers/${statistic.results[0].classifier_ids[0]}/startDate/${statistic.results[0].created_after}/endDate/${statistic.results[0].created_before}`}>
                                            <div className='count general-count'>
                                                <span>Усього</span>
                                                <span className='sum-arrow'>
                                            <span className='summary-text'>{sum}</span>
                                            <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                        </span>
                                            </div>
                                        </Link>
                                        {/*<div className='count'>*/}
                                        {/*    <span>В тому числі аварій</span>*/}
                                        {/*    <span className='summary-text'>{statistic.title.accidentCount}</span>*/}
                                        {/*</div>*/}
                                        <div className="card-status-line">
                                            <RequestsSummaryStatusLine data={statistic.results}/>
                                        </div>
                                        <ul className='details-list'>
                                            {
                                                statistic.results.map((status) => {
                                                    return (
                                                        <li key={status.id}>
                                                            <When is={status.events_count === 0}>
                                                                <div className='overlay'></div>
                                                            </When>
                                                            <Link
                                                                to={`/dashboard/main-departments/service-requests/district/${status.district_ids[0]}/classifiers/${status.classifier_ids[0]}/status/${status.id}/startDate/${status.created_after}/endDate/${status.created_before}`}
                                                                className='list-item'>
                                                                {status.name}
                                                                <span style={{color: status.id === 11 ? '#138481' : '#FD0101'}}>
                                                            {status.events_count}
                                                        </span>
                                                                <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )
                        }) : null
                    }
                </div>
        </div>
    )
};

export default StatisticByDistrictsCategorySecondLevel;
