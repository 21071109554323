import React, {useEffect} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import './App.scss';
import {useDispatch, useSelector} from "react-redux";
import * as authActions from './store/actions/auth';
import Login from "./containers/Login/Login";
import Dashboard from "./containers/Dashboard/Dashboard";
import LoadingSpinner from "./components/loadingSpinner/LoadingSpinner";
import {ThemeProvider} from '@material-ui/core/styles';
import {MaterialTheme} from "./assets/MaterialTheme";
import {AppConstants} from "./App.Constants";


const App = () => {

    const dispatch = useDispatch();
    const tok = localStorage.getItem(AppConstants.keys.authToken);
    // const isAuthorized = useSelector(state => {
    //   return state.auth.token !== null;
    // });
    const token = useSelector(state => {
        return state.auth.token;
    });


    useEffect(() => {
        dispatch(authActions.authCheckState());
    }, []);

    let routes;

    if (tok) {
        routes = (
            <Switch>
                <Route path="/dashboard/main-departments" component={Dashboard}/>
                <Redirect to="/dashboard/main-departments"/>
            </Switch>
        );
    } else {
        routes = (
            <Switch>
                <Route path="/" exact component={Login}/>
                <Redirect to="/"/>
            </Switch>
        );
    }

    return (
        <div className="App">
            <ThemeProvider theme={MaterialTheme}>
                {routes}
                <LoadingSpinner/>
            </ThemeProvider>
        </div>
    );
};

export default withRouter(App);
