import {createReducer} from "@reduxjs/toolkit";
import { getEventsSuccess, serInitialState } from "../actions/actionCreators";

const initialState = {
    events: {
        count: null,
        next: null,
        previous: null,
        result: []
    }
};

const reducer = createReducer(initialState, {
    [getEventsSuccess]: (state, action) => {
        state.events = {
            // count: action.payload.count,
            // next: action.payload.next,
            // previous: action.payload.previous,
            result: action.payload

        }
    },
    [serInitialState]: (state, action) => {
        return initialState
    },
})


export default reducer;
