import React from "react";
import {Link, useParams} from "react-router-dom";
import When from "../../../components/When";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSelector} from "react-redux";
import '../../../components/loadingSpinner/LoadingSpinner.scss'
import {getFiltersTitles} from "../../../store/selectors/filters";
import {GetTopLevelStats} from "../../../store/selectors/stats";

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}

const StatisticByClassifiers = ({parentIds, district_id, classifiersParams}) => {
    const params = useParams()
    const filtersTitles = useSelector(getFiltersTitles)
    const statsWithTitles = useSelector(GetTopLevelStats)
    const {
        statisticsLoading, topLevelStatistic
    } = useSelector(state => {
        return state.stats;
    });
    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });
    const classifiersIds = params.classifiers ? JSON.parse("[" + params.classifiers + "]") : parentIds
    const getCategoryTitles = (statistic) => {
        return parentIds.map((title)=>{
            if(title.idsArray.toString() === statistic[0].classifier_ids.toString()){
                return title.title
            }
        })
    }
    return (
        statsWithTitles && statsWithTitles.slice().sort((a,b) => {
            return b.sumCount - a.sumCount
        }).map((stat)=> {
            const item = stat.arr
            const sum = item.length ? item.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : '--';
            return (
                <div className="requests-summary">
                    <div className="card-details">
                        <div className='card-header'>
                            <div className='card-title'>
                                <Link
                                    to={params.isSecondLevel === 'true' ? `/dashboard/main-departments/service-requests/sub-category/${item[0].classifier_ids}/${item[0].created_after}/${item[0].created_before}/${params.isAccident}/general-districts/${item[0].district_ids}/${params.isSecondLevel}/events/` : `/dashboard/main-departments/service-requests/statistics-by-district/${item[0].classifier_ids}/${item[0].district_ids}/${item[0].created_after}/${item[0].created_before}/${params.isSecondLevel}/${params.isAccident}/all-sub-category`}>

                                    <span className='title-name'>
                                        {
                                            params.isSecondLevel === 'true' && params.classifiers ? stat.title[0].title : getCategoryTitles(item)
                                        }
                                        <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className='count general-count'>
                            <span>Усього</span>
                            <span className='summary-text'>{sum}</span>
                        </div>
                        <ul className='details-list'>
                            {
                                item && item.map((status) => {
                                    return (
                                        <li>
                                            <When is={status.events_count === 0}>
                                                <div className='overlay'></div>
                                            </When>
                                            <Link
                                                to={params.isSecondLevel === 'true' ? `/dashboard/main-departments/service-requests/sub-category/${status.classifier_ids}/${status.created_after}/${status.created_before}/${params.isAccident}/general-districts/${district_id}/${params.isSecondLevel}/${status.id}/events/` : `/dashboard/main-departments/service-requests/statistics-by-district/${status.classifier_ids}/${district_id}/${status.created_after}/${status.created_before}/${false}/${params.isAccident}/${status.id}/sub-category`}
                                                className='list-item'>
                                                {status.name}
                                                <span
                                                    style={{color: status.id === 11 ? '#138481' : '#FD0101'}}>{status.events_count}</span>
                                                <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <When is={statisticsLoading}>
                            <div className='preloader-wrapper'>
                                <CircularProgress size={50}/>
                            </div>
                        </When>
                    </div>
                </div>
            )
        })
    )
};

export default StatisticByClassifiers;

