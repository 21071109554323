import React from "react";
import {Link,useParams} from "react-router-dom";
import "./breadcrumbs.scss";
import Icon from "@material-ui/core/Icon";

const Breadcrumbs = ({crumbs}) => {
    if (crumbs.length <= 1) {
        return null;
    }
    return (
        <div className='breadcrumbs'>
            {crumbs.map(({name, path}, key) =>
                key + 1 === crumbs.length ? (
                   <span className='item active' key={key}>
                    {name + ' '}
                  </span>
                ) : (
                    <Link className='item' key={key} to={path}>
                        <Icon className='icon'>apartment</Icon>
                        {name + ' - '}
                    </Link>
                )
            )}
        </div>
    );
};
export default Breadcrumbs;
