import React, {useEffect} from "react";
import "./TimeFilter.scss";
import {FormControl,RadioGroup, FormControlLabel,Radio} from '@material-ui/core';
import Picker from "../../components/datePicker/Picker";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Icon from "@material-ui/core/Icon";
import {useDispatch, useSelector} from "react-redux";
import format from "date-fns/format";
import When from '../../components/When'
import {
    updateEndDate,
    updateStartDate,
    setPickerState,
    setStatsEmpty
} from '../../store/actions/actionCreators'
import { updateUserSettings } from '../../store/actions/auth'
import {
    startOfWeek,
    endOfWeek,
    endOfMonth,
    startOfMonth,
    startOfDay,
    endOfDay,
    startOfYear,
    endOfYear
} from "date-fns";
const periodData = {
    month: {
       text: "Показники за поточний місяць"
    },
    week: {
        text: 'Показники за поточний тиждень'
    },
    day: {
        text: 'Показники за сьогодні'
    },
    year: {
        text: 'Показники за поточний рiк'
    },
    allTime: {
      text: 'Показники за весь час'
    },
    datePeriod: {
        text: 'Показники за перiод дат'
    }
}
const TimeFilter = () => {
    const dispatch = useDispatch();
    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });
    const {
        classifiers, timePeriod
    } = useSelector(state => {
        return state.auth.user.settings;
    });
    const date = new Date()

    const [period, setPeriod] = React.useState('month');
    const [isShow, setShow] = React.useState(false)

    const currentPeriod = periodData[period]
    useEffect(() => {
        if(timePeriod.period !== undefined){
            setPeriod(timePeriod.period)
        }
    }, [timePeriod.period]);
    useEffect(()=> {
        if(timePeriod.period !== undefined){
        switch (timePeriod.period) {
            case 'month':
                dispatch(updateStartDate(format(startOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('month'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'month',
                        startDate: format(startOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'week':
                dispatch(updateStartDate(format(startOfWeek(date, {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfWeek(date, {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('week'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'week',
                        startDate: format(startOfWeek(new Date(date), {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfWeek(new Date(date), {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'day':
                dispatch(updateStartDate(format(startOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('day'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'day',
                        startDate: format(startOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'year':
                dispatch(updateStartDate(format(startOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('year'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'year',
                        startDate: format(startOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'allTime':
                dispatch(updateStartDate(format(new Date('1970-01-01T03:00:00'), `yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(updateEndDate(format(endOfDay(new Date()),`yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(setStatsEmpty())
                dispatch(setPickerState('allTime'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'allTime',
                        startDate: format(new Date('1970-01-01T03:00:00'), `yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfDay(date),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'datePeriod':
                dispatch(setPickerState('datePeriod'));
                dispatch(setStatsEmpty())
                break;
            default:
                console.log( 'default' );
        }
        }
    }, [timePeriod.period])

    const handleChange = (event, period) => {
        switch (period) {
            case 'month':
                dispatch(updateStartDate(format(startOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('month'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'month',
                        startDate: format(startOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfMonth(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'week':
                dispatch(updateStartDate(format(startOfWeek(date, {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfWeek(date, {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('week'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'week',
                        startDate: format(startOfWeek(new Date(date), {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfWeek(new Date(date), {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'day':
                dispatch(updateStartDate(format(startOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('day'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'day',
                        startDate: format(startOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfDay(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'year':
                dispatch(updateStartDate(format(startOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(updateEndDate(format(endOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)));
                dispatch(setStatsEmpty())
                dispatch(setPickerState('year'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'year',
                        startDate: format(startOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfYear(new Date(date)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'allTime':
                dispatch(updateStartDate(format(new Date('1970-01-01T03:00:00'), `yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(updateEndDate(format(endOfDay(new Date()),`yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(setStatsEmpty())
                dispatch(setPickerState('allTime'));
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'allTime',
                        startDate: format(new Date('1970-01-01T03:00:00'), `yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfDay(date),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                break;
            case 'datePeriod':
                dispatch(setPickerState('datePeriod'));
                dispatch(setStatsEmpty())
                break;
            default:
                console.log( 'default' );
        }
        setPeriod(event.target.value);
        if(period !== 'datePeriod'){
            setShow(!isShow)
        }
    };

    return (
        <div className='open-time-picker'>
            <div className='time-picker-header' onClick={()=>setShow(!isShow)}>
                 <span className='date-block'>
                     <span className='date-text'>{currentPeriod.text}</span>
                     <When is={period === 'datePeriod'}>
                         <span>
                            {format(new Date(startDate), 'dd.MM.yyyy')} - {new Date(endDate) && format(new Date(endDate), 'dd.MM.yyyy')}
                        </span>
                     </When>
                 </span>
                <When is={!isShow}>
                    <Icon className='arrow-down'>keyboard_arrow_down</Icon>
                </When>
            </div>
            <div className={`time-picker-wrapper  ${isShow ? 'show' : '' }`}>
                <Icon onClick={()=>setShow(!isShow)} className='arrow-up'>keyboard_arrow_up</Icon>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={period} onChange={handleChange}>
                        <FormControlLabel
                            value="month"
                            control={
                                <Radio
                                    checkedIcon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>}
                                    icon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>
                                    }/>
                            }
                            label="Показники за поточний місяць" />
                        <FormControlLabel
                            value="week"
                            control={
                                <Radio
                                    checkedIcon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>}
                                    icon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>
                                    }/>
                            }
                            label="Показники за поточний тиждень" />
                        <FormControlLabel
                            value="day"
                            control={
                                <Radio
                                    checkedIcon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>}
                                    icon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>
                                    }/>
                            }
                            label="Показники за сьогодні" />
                        <FormControlLabel
                            value="year"
                            control={
                                <Radio
                                    checkedIcon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>}
                                    icon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>
                                    }/>
                            }
                            label="Показники за поточний рiк" />
                        <FormControlLabel
                            value="allTime"
                            control={
                                <Radio
                                    checkedIcon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>}
                                    icon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>
                                    }/>
                            }
                            label="Показники за весь час" />
                        <FormControlLabel
                            value="datePeriod"
                            control={
                                <Radio
                                    checkedIcon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>}
                                    icon={
                                        <FiberManualRecordIcon
                                            fontSize={'inherit'}/>
                                    }/>
                            }
                            label="Показники за перiод дат" />
                    </RadioGroup>
                </FormControl>
                <Picker setShow={setShow} value={period}/>
            </div>
        </div>
    )
};

export default TimeFilter;
