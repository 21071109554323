import MainDepartments from "../containers/MainDepartments/MainDepartments";
import ServiceRequests from "../containers/1562/ServiceRequests";
import EmergencyService from "../containers/1562/emergency-service/EmergencyService";
import StatisticByDistricts from "../containers/1562/statisticByDistricts/StatisticByDistricts";
import Districts from "../containers/1562/districts/Districts";
import DistrictsByFilters from "../containers/1562/districtsByFilters/DistrictsByFilters";
import EventsDetails from "../containers/1562/eventsDetails/EventsDetails";
import Favorites from "../containers/Favorites/Favorites";
import Profile from "../containers/Profile/Profile";
import ChangePassword from "../containers/Profile/changePassword";
import EconomicsService from "../containers/EconomicsService/EconomicsService";
import Products from "../containers/EconomicsService/Products/Products";
import ProductsDetails from "../containers/EconomicsService/Products/ProductsDetails";
import TopCategoryDetails from "../containers/1562/topCategoryDetails/TopCategoryDetails";
import TopCategoryDetailsByStatus from "../containers/1562/topCategoryDetails/TopCategoryDetailsByStatus";
import React from "react";
import DistrictCategoryStatus from "../containers/1562/districtCategoryStatus/DistrictCategoryStatus";
import StatisticByDistrictsCategorySecondLevel from "../containers/1562/statisticByDistricts/StatisticByDistrictsCategorySecondLevel";
import MayorProgram from "../containers/MayorProgram/MayorProgram";
import ProgramDetails from "../containers/MayorProgram/Prorgam/ProgramDetails";
import ProgramDetailsStatuses from "../containers/MayorProgram/Prorgam/ProgramDetailsStatuses";

export default [
    {
        path: '/dashboard/main-departments',
        name: 'Показники департаментiв',
        Component: MainDepartments
    },
    {
        path: '/dashboard/main-departments/service-requests',
        name: '1562',
        Component: ServiceRequests
    },
    {
        path: '/dashboard/main-departments/service-requests/emergency-service',
        name: 'Аварії',
        Component: EmergencyService
    },
    // {
    //     path: '/dashboard/main-departments/all-departments',
    //     name: 'Усі департаменті',
    //     Component: AllDepartments
    // },
    {
        path: '/dashboard/main-departments/favorites',
        name: 'Улюбленi',
        Component: Favorites
    },
    {
        path: '/dashboard/main-departments/profile',
        name: 'Профайл користувача',
        Component: Profile
    },
    {
        path: '/dashboard/main-departments/profile/changePassword',
        name: 'Змiна паролю',
        Component: ChangePassword
    },
    // economics service
    // {
    //     path: '/dashboard/main-departments/economics-service',
    //     name: 'Інформація щодо мінімальних цін на соціально значущі товари',
    //     Component: EconomicsService
    // },
    {
        path: '/dashboard/main-departments/economics-service/product-list/',
        name: 'Інформація щодо мінімальних цін на соціально значущі товари',
        Component: Products
    },
    {
        path: '/dashboard/main-departments/economics-service/product-list/:productId/details',
        name: 'Цiни',
        Component: ProductsDetails
    },

    /////
    // districts
    {
        path: '/dashboard/main-departments/service-requests/district/:district_id/startDate/:startDate/endDate/:endDate/',
        name: 'Статистика звернень',
        Component: StatisticByDistricts
    },
    {
        path: '/dashboard/main-departments/service-requests/district-classifiers/:classifiers/:district_id/:startDate/:endDate/',
        name: 'Статистика звернень',
        Component: StatisticByDistricts
    },

    {
        path: '/dashboard/main-departments/service-requests/district/:district_id/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/district/:district_id/classifiers/:classifiersId/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/event-details/district/:district_id/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/event-details/classifiers/:classifiersId/district/:district_id/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/classifiers/:classifiersId/status/:id/district/:district_id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/district/:district_id/classifiers/:classifiersId/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/district-statistics-classifier/:district_id/classifiers/:classifiersId/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: StatisticByDistricts
    },
    {
        path: '/dashboard/main-departments/service-requests/district-statistics/:district_id/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: StatisticByDistricts
    },


    // {
    //     path: '/dashboard/main-departments/service-requests/category-details/:classifiers/:startDate/:endDate/',
    //     name: 'Деталі категорій',
    //     Component: TopCategoryDetails
    // },
    {
        path: '/dashboard/main-departments/service-requests/category-district-details/:district_id/:classifiers/:startDate/:endDate/',
        name: 'Деталі категорій',
        Component: TopCategoryDetailsByStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/category-district-details/district-category-status/:classifiers/:district_id/:status/:startDate/:endDate/:isAccident',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/category-district-details/district-category-status/:district_id/:status/:startDate/:endDate/:isAccident',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/category-district-details/district-category-status/:district_id/:startDate/:endDate/:isAccident',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/category-district-details/district-category-status-classifiers/:district_id/:id/:classifiers/:startDate/:endDate/:isAccident',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
    {   path: '/dashboard/main-departments/service-requests/events/:id/:district_id/:classifiers/:startDate/:endDate/:isAccident',
        name: 'Заявки',
        Component: EventsDetails
    },
    /// statuses

    {
        path: '/dashboard/main-departments/service-requests/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Райони',
        Component: Districts
    },
    {
        path: '/dashboard/main-departments/service-requests/classifiers/:classifiersId/status/:id/startDate/:startDate/endDate/:endDate/',
        name: 'Райони',
        Component: Districts
    },
    // {
    //     path: '/dashboard/main-departments/service-requests/classifier/:classifiersId/startDate/:startDate/endDate/:endDate/',
    //     name: 'Статистика звернень',
    //     Component: DistrictsByFilters
    // },
    {
        path: '/dashboard/main-departments/service-requests/category-second-level/:district_id/:classifiersId/:startDate/:endDate/',
        name: 'Статистика звернень второй уровень район',
        Component: StatisticByDistrictsCategorySecondLevel
    },
    // {
    //     path: '/dashboard/main-departments/service-requests/events-details/:district_id/:id',
    //     name: 'Статистика звернень',
    //     Component: EventsDetails
    // },
    {
        path: '/dashboard/main-departments/service-requests/status/:id/startDate/:startDate/endDate/:endDate/events-details/:district_id',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/status/:id/classifier/:classifiersId/startDate/:startDate/endDate/:endDate/events-details/:district_id',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/districts-list/status/:id/classifier/:classifiersId/startDate/:startDate/endDate/:endDate/',
        name: 'Райони',
        Component: Districts
    },
    {
        path: '/dashboard/main-departments/service-requests/districts-list/classifier/:classifiersId/startDate/:startDate/endDate/:endDate/',
        name: 'Райони',
        Component: Districts
    },
    {
        path: '/dashboard/main-departments/service-requests/districts-list/isDistrict/:isDistrict/status/:id/classifier/:classifiersId/startDate/:startDate/endDate/:endDate/',
        name: 'Райони',
        Component: Districts
    },
    {
        path: '/dashboard/main-departments/service-requests/districts-list/status/:id/classifier/:classifiersId/startDate/:startDate/endDate/:endDate/events-details/:district_id',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/classifier/:classifiersId/status/:id/events-details/:district_id/startDate/:startDate/endDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },

    {
        path: '/dashboard/main-departments/service-requests/category-district-details-status/events-details/:id/:classifiersId/:startDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },

    {
        path: '/dashboard/main-departments/service-requests/category-district-details/events-details/:district_id/:classifiersId/:startDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/category-district-events/events-details/:district_id/:classifiersId/:startDate/:endDate/',
        name: 'Заявки',
        Component: EventsDetails
    },
    //favorite
    // {
    //     path: '/dashboard/main-departments/favorite/:district_id/',
    //     name: 'Статистика звернень',
    //     Component: StatisticByDistricts
    // },
    // {
    //     path: '/dashboard/main-departments/favorite-classifiers/:district_id/:classifier_ids',
    //     name: 'Статистика звернень',
    //     Component: StatisticByDistricts
    // },
    // {
    //     path: '/dashboard/main-departments/favorite-status/:id/:classifiersId',
    //     name: 'Райони',
    //     Component: Districts
    // },
    // {
    //     path: '/dashboard/main-departments/favorite-district-classifier-status/:district_id/:classifiersId/:id',
    //     name: 'Заявки',
    //     Component: EventsDetails
    // },
    // {
    //     path: '/dashboard/main-departments/favorite-district-status/:district_id/:id',
    //     name: 'Заявки',
    //     Component: EventsDetails
    // },
    {
        path: '/dashboard/main-departments/favorites/status/:id/classifier/:classifiersId/startDate/:startDate/endDate/:endDate',
        name: 'Заявки',
        Component: EventsDetails
    },

    // mayor program

    {
        path: '/dashboard/main-departments/mayor-program',
        name: 'Звернення до міського голови',
        Component: MayorProgram
    },
    {
        path: '/dashboard/main-departments/mayor-program/:programType',
        name: 'Детальнiше',
        Component: ProgramDetails
    },
    {
        path: '/dashboard/main-departments/mayor-program/:typeId/statuses/:programType',
        name: 'Детальнiше',
        Component: ProgramDetailsStatuses
    },
    // {
    //     path: '/dashboard/main-departments/economics-service/product-list/:typeId/:productId/details',
    //     name: 'Цiни',
    //     Component: ProductsDetails
    // },



    ///////

    {
        path: '/dashboard/main-departments/service-requests/classifier/:classifiers/startDate/:startDate/endDate/:endDate/:isAccident',
        name: 'Статистика звернень',
        Component: DistrictsByFilters
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident',
        name: 'Деталі по категорії',
        Component: TopCategoryDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/classifier/:isAccident',
        name: 'Під категорії за районами',
        Component: DistrictsByFilters
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:id/classifier/events-details/:district_id/:isAccident',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/classifier/:id/district/:isDistrict/:isAccident',
        name: 'Райони',
        Component: Districts
    },

    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/classifier/:id/district/:isDistrict/sub-categories/:district_id/:isAccident',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/:id/:classifiers/:startDate/:endDate/:isDistrict/:isAccident',
        name: 'Райони',
        Component: Districts
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/classifier/:id/district/:isDistrict/:district_id/events-details/:isAccident',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident/general-districts/:district_id/:isSecondLevel/events/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident/general-districts/:district_id/:isSecondLevel',
        name: 'Статистика звернень',
        Component: StatisticByDistricts
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident/general-districts/:district_id/:isSecondLevel/:id/',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident/general-districts/:district_id/:isSecondLevel/:id/events/',
        name: 'Заявки',
        Component: EventsDetails
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident/:district_id/:id',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident/:district_id/:id',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/statistics-by-district/:classifiers/:district_id/:startDate/:endDate/:isSecondLevel/:isAccident',
        name: 'Статистика звернень',
        Component: StatisticByDistricts
    },
    {
        path: '/dashboard/main-departments/service-requests/statistics-by-district/:classifiers/:district_id/:startDate/:endDate/:isSecondLevel/:isAccident/all-sub-category',
        name: 'Статистика звернень',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/statistics-by-district/:classifiers/:district_id/:startDate/:endDate/:isSecondLevel/:isAccident/:id/sub-category',
        name: 'Статистика звернень',
        Component: DistrictCategoryStatus
    },
    {
        path: '/dashboard/main-departments/service-requests/sub-category/:classifiers/:startDate/:endDate/:isAccident/:district_id/:id/favorite',
        name: 'Деталі категорій',
        Component: DistrictCategoryStatus
    },
];

