import React, {useEffect} from "react";
import './Districts.scss'
import DepartmentList from "../../../components/departmentList/DepartmentList";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {
    getDistrictsStatisticsByStatus,
    getStatisticByStatusAndCategory
} from '../../../store/actions/stats'
import Header from "../../../components/Header/Header";
import {serInitialState} from "../../../store/actions/actionCreators";

const Districts = () => {
    const dispatch = useDispatch();
    const params = useParams()

    const { districtStatisticsByStatus } = useSelector(state => {
        return state.stats;
    });
    useEffect(() => {
        dispatch(getDistrictsStatisticsByStatus(params.id, params.classifiers, params.startDate, params.endDate, params.isAccident));
        // dispatch(getStatisticByStatusAndCategory(params, JSON.parse("[" + params.classifiersId + "]")));
        return () => {
            dispatch(serInitialState());
        }
    }, [params])

    return (
        <div id='scrolled-cont' className='page-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Список районів'}/>
            <div className='content service-page'>
                <div className="container">
                    {
                        districtStatisticsByStatus.length ?
                        <DepartmentList isDistrict={params.isDistrict} districtStatistics={districtStatisticsByStatus}/>
                        :
                        <div className='empty-screen'>По вашому запиту даних немає</div>
                    }

                </div>
            </div>
        </div>
    )
};

export default Districts;
