import axios from 'axios';
import store from './store/store';
import {AppConstants} from "./App.Constants";
import { logout } from "./store/actions/auth";
let AppAxios = axios.create({
    baseURL: AppConstants.api.apiBaseUrl
});
AppAxios.interceptors.response.use(response => {
    return response;
}, error => {
    // if(error.response.status === 401){
    //     store.dispatch(logout())
    // }
    return Promise.reject(error);
});
const token = localStorage.getItem(AppConstants.keys.authToken);
AppAxios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null;

const listener = () => {
    const token = localStorage.getItem(AppConstants.keys.authToken);
    AppAxios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : null;
}
store.subscribe(listener);

export default AppAxios;
