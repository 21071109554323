import React from "react";
import "./Error.scss";

const Error = (props) => {
    return (
        <div className="error-wrapper">
            <span className="error-text">{props.error}</span>
        </div>
    )
};

export default Error;
