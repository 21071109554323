import React, {useEffect, useState} from "react";
import "./Header.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    Link, NavLink, useParams, useHistory, useRouteMatch
} from "react-router-dom";
import {
    closeFilters,
    toggleFilters
} from '../../store/actions/actionCreators'
import When from '../../components/When'
import Icon from "@material-ui/core/Icon";
import {getFiltersTitles, getParentCategories} from "../../store/selectors/filters";
import {getReceptionTypes} from "../../store/actions/mayorProgram";
import routes from "../../textConstants/routes";

const districts = {
    1: 'Немишлянський',
    2: 'Індустріальний',
    3: 'Шевченківський',
    4: 'Київський',
    5: 'Московський',
    6: 'Холодногірський',
    7: 'Новобаварський',
    8: 'Основ\'янський',
    9: 'Слобідський',
}
const statuses = {
    1: 'Прийнята 1562',
    2: 'Відхилена виконавцем',
    3: 'Передане виконавцю',
    4: 'Закрита 1562',
    5: 'Знята заявником',
    6: 'Переадресована',
    7: 'Інформаційна',
    8: 'Виконана виконавцем',
    9: 'Контроль 1562',
    10: 'Контроль КАВ',
    11: 'Закрита КАО',
    12: 'Кап. ремонт',
    13: 'Безгоспні',
    14: 'Юр. обробка',
}

const Header = ({headerTitle,showFilters}) => {
    const params = useParams()
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();
    const filtersTitles = useSelector(getFiltersTitles)
    const parentIds = useSelector(getParentCategories)
    const [paths, setPaths] = useState([]);

    const show = useSelector(state => {
        return state.filters.show;
    });
    const {
        productsTypes
    } = useSelector(state => {
        return state.economics;
    });
    const {
        classifiers
    } = useSelector(state => {
        return state.filters;
    });
    const {
        accidentState
    } = useSelector(state => {
        return state.stats;
    });
    const {
        receptionTypes
    } = useSelector(state => {
        return state.mayorProgram;
    });


    const typeNames = (id) => {
        const name = productsTypes.filter(type => id ? type.id.toString() === id.toString() : '')
        return name.length ? name[0].name : ''
    }
    const programTypesName = (id) => {
        const name = receptionTypes && receptionTypes.filter(program => id ? program.id.toString() === id.toString() : '')
        return name && name.length && name[0].name
    }
    const classifiersNames = (id) => {
        const ids = id && id ? JSON.parse("[" + id + "]") : []
        if(ids && ids.length > 1){
            const nameTitle = parentIds.filter((title)=>{
                if(title.idsArray.toString() === ids.toString()){
                    return title.title
                }
            })
            return nameTitle && nameTitle.length ? nameTitle[0].title : ''
        }else{
             const nameTitle = filtersTitles.filter((title)=>{
                if(title.id.toString() === ids.toString()){
                    return title.title
                }
            })
            return nameTitle && nameTitle.length ? nameTitle[0].title : ''
        }
    }
    const generateTitle = () => {
        return (
            <span className='titles-list'>
                <When is={params.id}>
                    <span className='title-item'>Статус - {params.id === 11 ? 'У роботі' : 'Вирішено' }</span>
                </When>
                <When is={params.district_id}>
                    <span className='title-item'> {districts[params.district_id]} - Район</span>
                </When>
                <When is={params.classifiers}>
                    <span className='title-item classifiers'>{classifiersNames(params.classifiers)}</span>
                </When>
                <When is={params.typeId}>
                    <span className='title-item'>{typeNames(params.typeId)}</span>
                </When>
                <When is={params.programType}>
                    <span className='title-item'>{programTypesName(params.programType)}</span>
                </When>
            </span>
        )
    }

    useEffect(() => {
        const listen = (location, action) => {
            const actions = {
                PUSH: () => [...paths, location.pathname],
                POP: () => [...paths.slice(0, paths.length - 1)],
                REPLACE: () => [...paths.slice(0, paths.length - 1), location.pathname]
            };

            const getPaths = actions[action];
            if (getPaths) {
                setPaths(getPaths());
            }
        };

        const unregister = history.listen(listen);
        return () => unregister();
    }, [history, paths]);

    const goBackScreen = () =>{
        const crumbs = routes
            .filter(({ path }) => match.path.includes(path))
            .map(({ path, ...rest }) => ({
                path: Object.keys(match.params).length
                    ? Object.keys(match.params).reduce(
                        (path, param) => path.replace(
                            `:${param}`, match.params[param]
                        ), path
                    )
                    : path,
                ...rest
            }));
        if(show){
            dispatch(closeFilters())
        }else{
            history.push(crumbs[crumbs.length -2].path)
        }
    }
    return (
        <div className={`header ${accidentState ? 'isAccident' : ''}`}>
            {
                window.location.pathname === '/dashboard/main-departments' ?
                    <Link
                        onClick={() => dispatch(toggleFilters(false))}
                        to="/" className='header-logo'/>
                    :
                    <button className='back-arrow-wrapper' onClick={() => goBackScreen()}>
                        <Icon className="back-arrow">keyboard_backspace</Icon>
                    </button>
            }
            <h4><span className='title'>{headerTitle}</span> {generateTitle()}</h4>
            <div className='header-nav'>
                <ul>
                    <When is={showFilters}>
                        <li><span onClick={() => dispatch(toggleFilters(!show))} className='settings-btn'/></li>
                    </When>
                    <li onClick={() => dispatch(toggleFilters(false))}>
                        <NavLink to="/dashboard/main-departments/profile" className='profile'>
                            <Icon style={{color: '#3B4857', fontSize: 29}}>account_circle</Icon>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default Header;
