import React from 'react'
import { ReactComponent as Indicators } from '../assets/images/indicators.svg'
import { ReactComponent as Star } from '../assets/images/star.svg'
import { ReactComponent as Buildings } from '../assets/images/buildings.svg'
import { ReactComponent as General } from '../assets/images/general.svg'
import { ReactComponent as Fire } from '../assets/images/fire.svg'
export const footerNavMainDepartment = [
    {
        link: '/dashboard/main-departments',
        icon: <Indicators />,
        text: 'Показники',
        className: ''
    },
    {
        link: '/dashboard/main-departments/favorites',
        icon: <Star />,
        text: 'Улюблені',
        className: ''
    },
]
export const footerNavServiceRequest = [
    {
        link: '/dashboard/main-departments/service-requests',
        icon: <General />,
        text: 'Загальнi',
        className: ''
    },
    {
        link: '/dashboard/main-departments/service-requests/emergency-service',
        icon: <Fire />,
        text: 'Аварії',
        className: 'isAccident'

    },
]

