import React, {useEffect, useState} from "react";
import './Products.scss'
import {useParams} from 'react-router-dom'
import Header from "../../../components/Header/Header";
import ProductList from "../../../components/productsList/ProductList";
import {useDispatch, useSelector} from "react-redux";
import {getProductsList, getProductsTypes} from "../../../store/actions/economics";
import Search from "../../../components/search/Search";
import * as _ from "lodash";
import {addToFavorite, removeFavoriteProducts} from '../../../store/actions/favorites'
import {getProducts} from "../../../store/selectors/products";
import {AppConstants} from "../../../App.Constants";

const Products = () => {
    const [showFlash, showExistFavoriteFlash] = useState(false);
    const products = useSelector(getProducts)
    const params = useParams()
    const {
        typeName, productsTypes
    } = useSelector(state => {
        return state.economics;
    });
    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });
    const {
        user: {
            settings: {
                timePeriod: {
                    period
                }
            }
        }
    } = useSelector(state => {
        return state.auth;
    });
    const dispatch = useDispatch();

     useEffect(()=>{

        dispatch(getProductsList())
    }, [])

    useEffect(() => {
        updateProducts(products.productsList);
    }, [products.productsList]);

    const [productsList, updateProducts] = useState([]);

    const search = (event) => {
        const value = event.target.value.toLowerCase();
        let clonedProductsList = _.cloneDeep(products.productsList);
        clonedProductsList = clonedProductsList.map(product => {
            return product.filter(product => {
                const title = product.title.toLowerCase();
                const productMatch = value.length ? title.indexOf(value) > -1 : true;
                return productMatch;
            });
        });
        updateProducts(clonedProductsList);

    };
    const toggleFavorite = (item) => {
        const data = {
            classifierIds: [],
            productId: item[0].type.id,
            type: 'products',
            title: item[0].type.name,
            timePeriod: {
                period: period,
                startDate: startDate,
                endDate: endDate
            }
        }
        dispatch(addToFavorite(data))
        showExistFavoriteFlash(true)
        setTimeout(() => {
            showExistFavoriteFlash(false)
        }, 2000)
    }
    const removeFromFavoriteProducts = (item) => {
        dispatch(removeFavoriteProducts(item))
    }
    // const productsListSortFunction = () => {
    //     console.log('productsList',productsList)
    //     return productsList.sort((a, b) => {
    //
    //         return a.latest_price.price - b.latest_price.price
    //     });
    //
    // }
    // productsListSortFunction()
    return (
        <div className='page-wrapper economic-department'>
            <Header
                showFilters={false}
                headerTitle={'Інформація щодо мінімальних цін на соціально значущі товари'}/>
            <div className="container">
                <div className="search-container">
                    <Search onChange={search}/>
                </div>
                {
                    productsList.map(productItem =>(
                        <ProductList
                            showFlash={showFlash}
                            removeFromFavorites={removeFromFavoriteProducts}
                            addToFavorite={toggleFavorite}
                            title={typeName}
                            products={productItem}/>
                    ))
                }

            </div>
        </div>
    )
};

export default Products;
