import React from "react";
import {useDispatch, useSelector} from "react-redux";
import './Profile.scss';
import Header from "../../components/Header/Header";
import When from '../../components/When'
import {changeUserPassword} from '../../store/actions/auth'
import Icon from "@material-ui/core/Icon";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [error, setError] = React.useState(false);
    const [isEmptyField, showEmptyFieldError] = React.useState(false);
    const [visibleNew, setToggleVisibleNew] = React.useState(false);
    const [visibleConfirm, setToggleVisibleConfirm] = React.useState(false);
    const [errorLength, setErrorPasswordLenght] = React.useState(false);

    const { passwordChanged } = useSelector(state => {
        return state.auth;
    });
    const changePassword = () => {
        if(newPassword === confirmPassword && newPassword.length >= 8 && confirmPassword.length >= 8){
            dispatch(changeUserPassword(newPassword))
            setErrorPasswordLenght(false)
            setError(false)
        }else if(newPassword === '' || confirmPassword === ''){
            showEmptyFieldError(true)
        }else if(newPassword !== confirmPassword && newPassword.length >= 8 && confirmPassword.length >= 8){
            setError(true)
            setErrorPasswordLenght(false)
        }else if(newPassword.length <= 8 || confirmPassword.length <= 8){
            setErrorPasswordLenght(!errorLength)
            showEmptyFieldError(false)
        }
    }

    return (
        <div className='profile-page'>
            <Header
                showFilters={false}
                headerTitle={'Змiна паролю'}/>
            <div className='content'>
                <div className='change-password-view'>
                    <label>Новий пароль</label>
                    <div className='input-block'>
                        <input onChange={(event) => setNewPassword(event.target.value)} value={newPassword} className='input' type={visibleNew ? 'text' : 'password'} placeholder={''}/>
                        <span className='showPassword' onClick={() => setToggleVisibleNew(!visibleNew)}>
                            <Icon>{visibleNew ? 'visibility_off' : 'visibility'}</Icon>
                        </span>
                        {/*visibility_off*/}
                    </div>
                    <label>Підтвердіть новий пароль</label>
                    <div className='input-block'>
                        <input onChange={(event) => setConfirmPassword(event.target.value)} value={confirmPassword} type={visibleConfirm ? 'text' : 'password'} className='input'  placeholder={''}/>
                        <span className='showPassword' onClick={()=> setToggleVisibleConfirm(!visibleConfirm)}>
                            <Icon>{visibleConfirm ? 'visibility_off' : 'visibility'}</Icon>
                        </span>
                    </div>
                    <When is={error}>
                        <div className='error-text'>Паролі не співпадають</div>
                    </When>
                    <When is={isEmptyField}>
                        <div className='error-text'>Запонiть усi поля</div>
                    </When>
                    <When is={errorLength}>
                        <div className='error-text'>Пароль повинен бути не меньше 8 символів</div>
                    </When>
                    <When is={passwordChanged}>
                        <div className='success-text'>Пароль успішно змiнено</div>
                    </When>
                    <button onClick={()=>changePassword()}>Змiнити</button>
                </div>
            </div>
        </div>
    )
};

export default ChangePassword;
