import {StatisticsApiService} from "../../api/stats.api";
import {
    setProductsList,
    setProductsTypes,
    setProductStatistic, httpLoadingEnd, setSummaryStatisticsByClassifiers
} from './actionCreators'
import {EconomicsApiService} from "../../api/economics";
import {AppConstants} from "../../App.Constants";

export const getProductsTypes = () => {
    return (dispatch, getState) => {
        const state = getState()
        EconomicsApiService.getProductsTypes()
            .then((response) => {
                localStorage.setItem(AppConstants.keys.productTypes, JSON.stringify(response.results));
                dispatch(setProductsTypes(response));

            })
            .catch(err => {
            });
    };
};
export const getProductsList = () => {
    return (dispatch, getState) => {
        const typesProduct = localStorage.getItem(AppConstants.keys.productTypes);
        const state = getState()
        const productArray = JSON.parse(typesProduct)
        // const typeId = id
        // const currentFilters = state.stats.currentFilters;
        // const paramsString = Object
        //     .entries(currentFilters)
        //     .filter(currentFilters => currentFilters[1])
        //     .map((currentFilters) => {
        //         return currentFilters.join('=');
        //     }).join('&');
        const promises = productArray.map(type => EconomicsApiService.getProducts(`${type.id}`))
        Promise.all(promises).then((responses)=>{
            if(responses.length){
                dispatch(httpLoadingEnd());
                dispatch(setProductsList(responses));
            }else {
                dispatch(httpLoadingEnd());
            }
        }).catch(()=>{

        })
        // EconomicsApiService.getProducts()
        //     .then((response) => {
        //         dispatch(setProductsList(response));
        //     })
        //     .catch(err => {
        //     });
    };
};
export const getProductsPriceStatisticById = (id) => {
    return (dispatch, getState) => {
        EconomicsApiService.getProductById(id)
            .then((response) => {
                dispatch(setProductStatistic(response));
            })
            .catch(err => {
            });
    };
};
