import React, {useEffect, useState} from "react";
import './../ServiceRequests.scss'
import Search from "../../../components/search/Search";
import TimeFilter from "../../../components/timeFilter/TimeFilter";
import {useDispatch, useSelector} from "react-redux";
import Icon from "@material-ui/core/Icon";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Footer from '../../../components/Footer/Footer'
import {footerNavServiceRequest} from '../../../textConstants/textConstants'
import DepartmentFilters from "../../../components/departmentFilters/DepartmentFilters";
import {CSSTransition} from "react-transition-group";
import {
    getTopLevelStatistics
} from '../../../store/actions/stats';
import {
    getStatistics,
    getCurrentFilters,
    getStatisticsByDistrict,
    getGeneralStatistics, getTopLevelStatisticsData
} from '../../../store/selectors/stats'
import {
    setTabsView,
    setAccidentState,
    setStatsEmpty
} from "../../../store/actions/actionCreators";
import * as _ from "lodash";
import Header from "../../../components/Header/Header";
import {updateUserSettings} from "../../../store/actions/auth";
import When from '../../../components/When'
import DistrictsTab from './../DistrictsTab'
import StatusTab from './../StatusTab'
import {getFiltersIds, getParentCategories} from '../../../store/selectors/filters'
import Spiner from "../../../assets/images/logo-spinner.gif";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const ServiceRequests = () => {
    const statistics = useSelector(getStatistics)
    const generalStats = useSelector(getGeneralStatistics)
    const filters = useSelector(getCurrentFilters)
    const statisticsByDistrict = useSelector(getStatisticsByDistrict)
    const topLevelStatisticsWithTitles = useSelector(getTopLevelStatisticsData)
    const parentIds = useSelector(getParentCategories)
    const [currentFilters, setCurrentFilters] = React.useState([]);
    const [generalStartDistricts, updateStartDistricts] = useState([]);
    const [generalStartStatistics, updateStartStatistics] = useState([]);
    const {
        currentFilters: {classifierIds}, statisticsCounter, tabValue, accidentState
    } = useSelector(state => {
        return state.stats;
    });

    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });

    const {
        favoritesList
    } = useSelector(state => {
        return state.favorites;
    });
    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });
    const {
        timePeriod
    } = useSelector(state => {
        return state.auth.user.settings;
    });
    const dispatch = useDispatch();

    const [value, setValue] = React.useState(tabValue);

    useEffect(() => {
        dispatch(setAccidentState(true))
    }, []);

    useEffect(() => {
        dispatch(getTopLevelStatistics([1, 2, 3, 4, 5, 6, 7, 8, 9], parentIds, startDate, endDate, true))
    }, [settings.classifiers, accidentState, startDate, value]);

    useEffect(() => {
        updateStartStatistics(topLevelStatisticsWithTitles)
        updateStatistics(topLevelStatisticsWithTitles)
    }, [parentIds, startDate, endDate, value, favoritesList, topLevelStatisticsWithTitles]);


    useEffect(() => {
        updateStartDistricts(statisticsByDistrict);
        updateDistricts(statisticsByDistrict);
    }, [statisticsByDistrict, favoritesList]);

    useEffect(() => {
        setCurrentFilters(filters);
    }, [filters]);

    const showFilters = useSelector(state => {
        return state.filters.show;
    });

    const handleChange = (event, newValue) => {
        dispatch(setTabsView(newValue))
        setValue(newValue);
    };
    const [generalStatistics, updateStatistics] = useState([]);
    const [generalDistricts, updateDistricts] = useState([]);

    const search = (event) => {
        if (value === 1) {
            const val = event.target.value.toLowerCase();
            let newDistricts = _.cloneDeep(generalStartDistricts);
            newDistricts = newDistricts.filter(distr => {
                const title = distr.title.text !== undefined ? distr.title.text.toLowerCase() : '';
                const districtMatch = val.length ? title.indexOf(val) > -1 : true;
                return districtMatch;
            });
            updateDistricts(newDistricts);

        } else {
            const value = event.target.value.toLowerCase();
            let newStatistics = _.cloneDeep(generalStartStatistics);
            newStatistics = newStatistics.filter(stats => {
                const title = stats.title && stats.title.title !== undefined ? stats.title.title.toLowerCase() : '';
                const statsMatch = value.length ? title.indexOf(value) > -1 : true;
                return statsMatch;
            });
            updateStatistics(newStatistics);
        }
    };

    const removeFilters = (filter) => {
        const newFilters = currentFilters.filter((item) => {
            if(filter.children){
                filter.children.filter(childItem => item.id !== childItem.id)
            }else {
                return item.id !== filter.id
            }
        })
        setCurrentFilters(newFilters);
        const ids = newFilters.map((filter) => {
            return filter.id
        })
        dispatch(setStatsEmpty())
        dispatch(updateUserSettings({
            classifiers: ids,
            timePeriod: timePeriod
        }));
    }
    // const [showAll, setShowAll] = useState(false);
    // const visibleFilters = currentFilters.slice(0, showAll ? currentFilters.length : 5);

    return (
        <div id='scrolled-cont' className='page-wrapper'>
            <Header
                showFilters={true}
                headerTitle={'Звернення до служби 1562'}/>
            <div className={`service-page`}>
                <CSSTransition
                    in={showFilters}
                    timeout={300}
                    classNames="filters"
                    unmountOnExit
                >
                    <DepartmentFilters/>
                </CSSTransition>
                <div className="container">
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="За категоріями"/>
                            <Tab label="За районом"/>
                        </Tabs>
                    </AppBar>
                    <TimeFilter/>
                </div>
                <div className="container">
                    <div className="line"></div>
                </div>
                {/*<ul className='current-filters-list'>*/}
                {/*    {*/}
                {/*        visibleFilters.map((filter) => {*/}
                {/*            return (*/}
                {/*                <li key={filter.title}>*/}
                {/*                    <span onClick={() => removeFilters(filter)} className='item'>*/}
                {/*                        {filter.title}*/}
                {/*                        <Icon className='remove-filter'>close</Icon>*/}
                {/*                    </span>*/}
                {/*                </li>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*    <When is={currentFilters.length > 5}>*/}
                {/*        <li>*/}
                {/*            <span*/}
                {/*                onClick={() => {*/}
                {/*                    setShowAll(!showAll)*/}
                {/*                }}*/}
                {/*                className='item show-more'>*/}
                {/*                {*/}
                {/*                    !showAll ? ('Показати ще ' + (currentFilters.length - visibleFilters.length)) : 'Сховати'*/}
                {/*                }*/}
                {/*            </span>*/}
                {/*        </li>*/}
                {/*    </When>*/}
                {/*</ul>*/}
                {/*<When is={visibleFilters.length}>*/}
                {/*    <div className="container">*/}
                {/*        <div className="line"></div>*/}
                {/*    </div>*/}
                {/*</When>*/}
                <div className="container">
                    <Search onChange={search}/>
                    <TabPanel value={value} index={0}>
                        <StatusTab
                            parentIds={parentIds}
                            statisticsCount={statisticsCounter}
                            topLevelStatistic={generalStatistics}
                            statistics={generalStats}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <DistrictsTab generalDistricts={generalDistricts}/>
                    </TabPanel>
                </div>
            </div>
            <Footer footerNav={footerNavServiceRequest}/>
            <When is={!generalStatistics.length}>
                <div className="loading-spinner-overlay">
                    <img src={Spiner} alt="Spiner"/>
                </div>
            </When>
        </div>
    )
};

export default ServiceRequests;
