import {createSelector} from '@reduxjs/toolkit'

const productsSelector = state => state.economics.products;
const favoritesListSelector = state => state.favorites.favoritesList;

export const getProducts = createSelector([productsSelector,favoritesListSelector],(products, favoritesList) => {
    const favoriteListProductsIds = {};
    const favoriteIds = {};
    favoritesList && favoritesList.results.forEach((favorite)=>{
        favoriteListProductsIds[favorite.data.productId] = true;
        favoriteIds[favorite.data.productId] = favorite.id
    })
    const product = {
        productsList: products.productsList.map((item, i) => {
            return item.map(product => {
                return {
                    ...product,
                    info: {
                        type: 'events',
                        favoriteId: favoriteIds[product.type.id],
                        isFavorite: favoriteListProductsIds[product.type.id]
                    }
                }
            })

        })
    }
    return product

})
