import React, {useEffect, useState} from "react";
import './ServiceRequests.scss'
import {Link} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import GeneralStatistic from './GeneralStatistic'
import {useDispatch, useSelector} from "react-redux";
import {
    addToFavorite,
    removeFavoriteClassifier,
    removeFavoriteTopLevelClassifier
} from '../../store/actions/favorites'
import When from '../../components/When'
import {getSummaryStatisticsByClassifiers} from "../../store/actions/stats";
import format from "date-fns/format";
import * as _ from "lodash";
import {getFiltersIds} from "../../store/selectors/filters";
import CircularProgress from "@material-ui/core/CircularProgress";
import '../../components/loadingSpinner/LoadingSpinner.scss'
import {getFiltersTitles} from '../../store/selectors/filters'
import RequestsSummaryStatusLine from "../../components/requestsSummaryStatusLine/RequestsSummaryStatusLine";
import {setStatsEmpty} from "../../store/actions/actionCreators";
import {updateUserSettings} from "../../store/actions/auth";
import {getCurrentFilters} from "../../store/selectors/stats";

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}
const StatusTab = ({statistics, statisticsCount, generalStatistics, parentIds, topLevelStatistic}) => {
    const dispatch = useDispatch();
    const classifiersTitles = useSelector(getFiltersTitles)
    const filters = useSelector(getCurrentFilters)
    const [isFavorite, updateFavorite] = useState(false);
    const [currentFilters, setCurrentFilters] = React.useState([]);
    const [showFlash, showExistFavoriteFlash] = useState(false);
    const [classifiersId, updateClassifiersId] = useState('');
    const filtersIds = useSelector(getFiltersIds)
    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });
    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });
    const {
        user: {
            settings: {
                classifiers,
                timePeriod: {
                    period
                }
            }
        }
    } = useSelector(state => {
        return state.auth;
    });
    const {
        timePeriod
    } = useSelector(state => {
        return state.auth.user.settings;
    });
    const {
        favoritesList
    } = useSelector(state => {
        return state.favorites;
    });
    const {
        accidentState,statisticsByClassifiers, statisticsLoading
    } = useSelector(state => {
        return state.stats;
    });

    useEffect(() => {
        updateClassifiersId(classifiers.toString())
    }, [generalStatistics])

    const toggleFavorite = (statistic) => {
        const data = {
            classifierIds: statistic.title.idsArray,
            title: statistic.title.title,
            type: 'classifier-top',
            isAccident: accidentState ? true : null,
            timePeriod: {
                period: period,
                startDate: startDate,
                endDate: endDate
            }
        }
        dispatch(addToFavorite(data))
        updateFavorite(!isFavorite)

        setTimeout(() => {
            updateFavorite(false)
        }, 2000)
    }
    const removeFromFavoriteClassifier = (item) => {
        dispatch(removeFavoriteTopLevelClassifier(item))
    }

    const getCategoryTitles = (statistic) => {
        return parentIds.map((title)=>{
            if(title.idsArray.toString() === statistic[0].classifier_ids.toString()){
                return title.title
            }
        })
    }
    const removeFilters = (filter) => {
        const newFilters = currentFilters.filter((item) => {
            if(filter.children){
                filter.children.filter(childItem => item.id !== childItem.id)
            }else {
                return item.id !== filter.id
            }
        })
        setCurrentFilters(newFilters);
        const ids = newFilters.map((filter) => {
            return filter.id
        })
        dispatch(setStatsEmpty())
        dispatch(updateUserSettings({
            classifiers: ids,
            timePeriod: timePeriod
        }));
    }
    const [showAll, setShowAll] = useState({state: false, value: 3});
    return (
        <div id='scrolled-cont'>
            {
                topLevelStatistic && topLevelStatistic && topLevelStatistic.length ? topLevelStatistic.slice().sort((a,b) => {
                    return b.sumCount - a.sumCount
                }).map((statistic, index) => {
                    const sum = statistic.statistics && statistic.statistics.length ? statistic.statistics.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : 0;
                    return (
                        <div key={index} className="requests-summary">
                            <div className="card-details">
                                <When is={isFavorite}>
                                    <div className='success-alert'>
                                        Картка добавлена в улюблене
                                    </div>
                                </When>
                                <When is={showFlash}>
                                    <div className='success-alert exist'>
                                        Эта карточка уже добавленна в избранное
                                    </div>
                                </When>
                                <div className='card-header'>
                                    <div className='card-title'>
                                        <Link
                                            to={statistic && statistic.statistics && statistic.statistics[0].classifier_ids.length === 1 ? `/dashboard/main-departments/service-requests/classifier/${statistic.statistics[0].classifier_ids}/startDate/${statistic.statistics[0].created_after}/endDate/${statistic.statistics[0].created_before}/${statistic.statistics[0].is_accident}` : `/dashboard/main-departments/service-requests/sub-category/${statistic.statistics[0].classifier_ids}/${startDate}/${endDate}/${statistic.statistics[0].is_accident}`}>
                                            <span className='title-name'>
                                                {getCategoryTitles(statistic.statistics)}
                                                <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                                            </span>
                                        </Link>
                                        {
                                            statistic.isFavorite ?
                                                <Icon onClick={() => removeFromFavoriteClassifier(statistic)}
                                                      style={{color: '#DE8F00'}}>star</Icon>
                                                :
                                                <Icon onClick={() => toggleFavorite(statistic)}
                                                      style={{color: '#D8D8D8'}}>star_border</Icon>
                                        }
                                    </div>
                                </div>
                                <Link
                                    to={statistic && statistic.statistics && statistic.statistics[0].classifier_ids.length === 1 ? `/dashboard/main-departments/service-requests/classifier/${statistic.statistics[0].classifier_ids}/startDate/${statistic.statistics[0].created_after}/endDate/${statistic.statistics[0].created_before}/${statistic.statistics[0].is_accident}` : `/dashboard/main-departments/service-requests/sub-category/${statistic.statistics[0].classifier_ids}/${startDate}/${endDate}/${statistic.statistics[0].is_accident}`}>
                                    <div className='count general-count'>
                                        <span>Усього</span>
                                        <span className='sum-arrow'>
                                            <span className='summary-text'>{sum}</span>
                                            <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                        </span>
                                    </div>
                                </Link>
                                {/*<div className='count'>*/}
                                {/*    <span>В тому числі аварій</span>*/}
                                {/*    <span className='summary-text'>{statistic.title.accidentCount}</span>*/}
                                {/*</div>*/}
                                <div className="card-status-line">
                                    <RequestsSummaryStatusLine data={statistic.statistics}/>
                                </div>
                                <ul className='details-list'>
                                    {
                                        statistic.statistics.map((status) => {
                                            return (
                                                <li key={status.id}>
                                                    <When is={status.events_count === 0}>
                                                        <div className='overlay'></div>
                                                    </When>
                                                    <Link
                                                        to={`/dashboard/main-departments/service-requests/${status.id}/${status.classifier_ids}/${status.created_after}/${status.created_before}/${true}/${status.is_accident}`}
                                                        // to={`/dashboard/main-departments/service-requests/districts-list/status//classifier//startDate//endDate/${status.created_before}`}
                                                        className='list-item'>
                                                        {status.name}
                                                        <span style={{color: status.id === 11 ? '#138481' : '#FD0101'}}>
                                                            {status.events_count}
                                                        </span>
                                                        <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                {/*<div className="card-footer">*/}
                                {/*    <span>На основі даних від 02.03.2020</span>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )
                }) : null
            }
            {/*{*/}
            {/*    generalStatistics && generalStatistics.length ? generalStatistics.map((statistic, index) => {*/}
            {/*        const sum = statistic.results.length ? statistic.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : 0;*/}
            {/*        return (*/}
            {/*            <div key={index} className="requests-summary">*/}
            {/*                <div className="card-details">*/}
            {/*                    <When is={isFavorite}>*/}
            {/*                        <div className='success-alert'>*/}
            {/*                            Картка добавлена в улюблене*/}
            {/*                        </div>*/}
            {/*                    </When>*/}
            {/*                    <When is={showFlash}>*/}
            {/*                        <div className='success-alert exist'>*/}
            {/*                            Эта карточка уже добавленна в избранное*/}
            {/*                        </div>*/}
            {/*                    </When>*/}
            {/*                    <div className='card-header'>*/}
            {/*                        <div className='card-title'>*/}
            {/*                            <Link*/}
            {/*                                to={`/dashboard/main-departments/service-requests/classifier/${statistic.title.id}/startDate/${statistic.results.length ? statistic.results[0].created_after : null}/endDate/${statistic.results.length ? statistic.results[0].created_before : null}`}>*/}
            {/*                                <span className='title-name'>*/}
            {/*                                    {statistic.title.text}*/}
            {/*                                    <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>*/}
            {/*                                </span>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                        {*/}
            {/*                            statistic.title.isFavorite ?*/}
            {/*                                <Icon onClick={() => removeFromFavoriteClassifier(statistic)}*/}
            {/*                                      style={{color: '#DE8F00'}}>star</Icon>*/}
            {/*                                :*/}
            {/*                                <Icon onClick={() => toggleFavorite(statistic)}*/}
            {/*                                      style={{color: '#D8D8D8'}}>star_border</Icon>*/}
            {/*                        }*/}
            {/*                    </div>*/}
            {/*                    <Link*/}
            {/*                        to={`/dashboard/main-departments/service-requests/classifier/${statistic.title.id}/startDate/${statistic.results.length ? statistic.results[0].created_after : null}/endDate/${statistic.results.length ? statistic.results[0].created_before : null}`}>*/}
            {/*                        <div className='count general-count'>*/}
            {/*                            <span>Усього</span>*/}
            {/*                            <span className='sum-arrow'>*/}
            {/*                                <span className='summary-text'>{sum}</span>*/}
            {/*                                <Icon className='arrow-right'>keyboard_arrow_right</Icon>*/}
            {/*                            </span>*/}
            {/*                        </div>*/}
            {/*                    </Link>*/}
            {/*                    /!*<div className='count'>*!/*/}
            {/*                    /!*    <span>В тому числі аварій</span>*!/*/}
            {/*                    /!*    <span className='summary-text'>{statistic.title.accidentCount}</span>*!/*/}
            {/*                    /!*</div>*!/*/}
            {/*                    <ul className='details-list'>*/}
            {/*                        {*/}
            {/*                            statistic.results.map((status) => {*/}
            {/*                                return (*/}
            {/*                                    <li key={status.id}>*/}
            {/*                                        <When is={status.events_count === 0}>*/}
            {/*                                            <div className='overlay'></div>*/}
            {/*                                        </When>*/}
            {/*                                        <Link*/}
            {/*                                            to={`/dashboard/main-departments/service-requests/districts-list/status/${status.id}/classifier/${status.classifier_ids[0]}/startDate/${status.created_after}/endDate/${status.created_before}`}*/}
            {/*                                            className='list-item'>*/}
            {/*                                            {status.name}*/}
            {/*                                            <span style={{color: colors[status.id]}}>*/}
            {/*                                                {status.events_count}*/}
            {/*                                            </span>*/}
            {/*                                            <Icon className='arrow-right'>keyboard_arrow_right</Icon>*/}
            {/*                                        </Link>*/}
            {/*                                    </li>*/}
            {/*                                )*/}
            {/*                            })*/}
            {/*                        }*/}
            {/*                    </ul>*/}
            {/*                    /!*<div className="card-footer">*!/*/}
            {/*                    /!*    <span>На основі даних від 02.03.2020</span>*!/*/}
            {/*                    /!*</div>*!/*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    }) : null*/}
            {/*}*/}
            <When is={statisticsLoading}>
                <div className='preloader-wrapper'>
                    <CircularProgress size={50}/>
                </div>
            </When>
        </div>

    )
};

export default StatusTab;
