import React, {useEffect} from "react";
import './Products.scss'
import './../MayorProgram.scss'
import {useParams, Link} from 'react-router-dom'
import Header from "../../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import Icon from "@material-ui/core/Icon";
import { getReceptionById, getReceptionStatistic } from '../../../store/actions/mayorProgram'

const ProgramDetailsStatuses = () => {
    const params = useParams()
    const {
        reseptions, receptionStatistic
    } = useSelector(state => {
        return state.mayorProgram;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getReceptionById(params.programType))
    }, [params.id])
    return (
        <div className='page-wrapper mayor-program'>
            <Header
                showFilters={false}
                headerTitle={'Інформація щодо мінімальних цін на соціально значущі товари'}/>
            <div className="container">
                <div className='product-statistic-list'>
                    <div className="product-list-wrapper">
                        <h2>{reseptions && reseptions.length && reseptions[0].type.name} прийом</h2>
                        <ul className='product-list'>
                            {/*{*/}
                            {/*    reseptions && reseptions.counters.map(reseption => {*/}
                            {/*        return (*/}
                            {/*            <li className='list-item'>*/}
                            {/*                <div className='name'>*/}
                            {/*                    {reseption.name}*/}
                            {/*                </div>*/}
                            {/*                /!*<div className="prices"><span>{productDetails && productDetails.latest_price.price} грн</span></div>*!/*/}
                            {/*            </li>*/}
                            {/*        )*/}
                            {/*    })*/}
                            {/*}*/}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProgramDetailsStatuses;
