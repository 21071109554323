import React from "react";
import "./DepartmentCard.scss";
import {
    Link
} from "react-router-dom";
import When from '../When'
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import {ThemeProvider} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear
} from "date-fns";

const DepartmentCard = ({departmentTitle, background, link, disabled, generalStatistics, showStats, className, loading, settings}) => {

    const getSum = (generalStatistics) => {
        return generalStatistics && generalStatistics.length && generalStatistics.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next);
    }

    const selectDatePeriod = () => {
        if(settings && settings.period === 'week'){
            return 'за поточний тиждень'
        }else if(settings && settings.period === 'month'){
            return 'за поточний місяць'

        }else if(settings && settings.period === 'year'){
            return 'за поточний рік'
        }else if(settings && settings.period === 'allTime'){
            return 'за весь час'
        }else if(settings && settings.period === 'day'){
            return 'за сьогодні'
        }else if(settings && settings.period === 'allTime'){
            return 'за весь час'
        }else if(settings && settings.period === 'datePeriod'){
            return <span>з {format(new Date(settings.startDate), 'dd.MM.yyyy')} по {format(new Date(settings.endDate), 'dd.MM.yyyy')}</span>
        }
    }
    const mergedStatuses = () => {
        const mergedArray = []
        const notSolved = []
        const statusesArray = []

        generalStatistics && generalStatistics.forEach(status => {
            if(status.id === 11){
                mergedArray.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})
        return (
            mergedArray && mergedArray.map(statistic => {
                return (
                    <When is={statistic.events_count !== 0}>
                        <li><span>{statistic.name}</span><span>{statistic.events_count}</span></li>
                    </When>
                )
            })
        )
    }
    return (
        <div className={`department-card ${background} ${className}`}>
            <span className='overlay-abso'></span>
            <When is={disabled}>
                <div className='overlay'></div>
            </When>
            <Link to={link} className="department-card-overlay">
                <h2>{departmentTitle}</h2>
                <When is={showStats}>
                    <div className='statistic-wrapper'>
                        <h3>Загальна кiлькiсть звернень по Харкову {selectDatePeriod()} - <span className='summary-events'>{getSum(generalStatistics)}</span> </h3>
                        <ul className='statistic-list'>
                            {
                                mergedStatuses()
                            }
                        </ul>
                        <When is={loading}>
                            <div className='spiner'>
                                <CircularProgress color={'#ffffff'} size={50}/>
                            </div>
                        </When>
                    </div>
                </When>
            </Link>

        </div>
    )
};

export default DepartmentCard;
