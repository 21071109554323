import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/auth';
import filters from "./reducers/filters";
import http from "./reducers/http";
import stats from "./reducers/stats";
import events from "./reducers/events";
import economics from "./reducers/economics";
import favorites from "./reducers/favorites";
import mayorProgram from "./reducers/mayorProgram";

const store = configureStore({
    reducer: {
        auth,
        filters,
        http,
        stats,
        events,
        economics,
        favorites,
        mayorProgram
    }
});

export default store;
