export const AppConstants = {
    api: {
        apiBaseUrl: process.env.REACT_APP_BASE_API_URL,
    },
    keys: {
        authToken: 'access',
        refreshToken: 'refresh',
        email: 'email',
        productTypes: 'productTypes',
    },
    regexp: {
        emailRegexp: "^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
    }
};
