import {createAction} from "@reduxjs/toolkit";
import * as actionTypes from "./actionTypes";

export const authStart = createAction('AUTH_START');
export const authSuccess = createAction('AUTH_SUCCESS');
export const authFail = createAction('AUTH_FAIL');
export const logoutSuccess = createAction('AUTH_LOGOUT');
export const setUser = createAction('AUTH_SET_USER');
export const setUserSettings = createAction('AUTH_SET_USER_SETTINGS');
export const setChangePasswordSuccess = createAction('SET_CHANGE_PASSWORD_SUCCESS');

export const getEventsSuccess = createAction('GET_EVENTS_SUCCESS');

export const toggleFilters = createAction('TOGGLE_FILTERS')
export const closeFilters = createAction('CLOSE_FILTERS')
export const updateStartDate = createAction('UPDATE_START_DATE')
export const updateEndDate = createAction('UPDATE_END_DATE')
export const setPickerState = createAction('SET_PICKER_STATE')
export const setClassifiers = createAction('SET_CLASSIFIERS')

export const httpLoadingStart = createAction('HTTP_LOADING_START')
export const httpLoadingEnd = createAction('HTTP_LOADING_END')

export const setGeneralStatisticTopLevel = createAction('SET_GENERAL_STATISTIC_TOP_LEVEL')
export const setCategoryByStatus = createAction('SET_CATEGORY_BY_STATUS')
export const setSummaryStatisticsSuccess = createAction('GET_SUMMARY_STATISTICS_SUCCESS')
export const setSummaryStatisticsDistrictsSuccess = createAction('GET_SUMMARY_STATISTICS_DISTRICT_SUCCESS')
export const setSummaryStatisticsCount = createAction('SET_SUMMARY_STATISTICS_COUNT')
export const setAccidentCount = createAction('SET_ACCIDENT_COUNT')
export const setAccidentCountByClassifiers = createAction('SET_ACCIDENT_COUNT_BY_CLASSIFIERS')
export const setAccidentCountByDistrict = createAction('SET_ACCIDENT_COUNT_BY_DISTRICT')
export const setSummaryStatisticsByClassifiers = createAction('SET_STATISTICS_BY_CLASSIFIERS')
export const setSummaryStatisticsByClassifiersUpdate = createAction('SET_STATISTICS_BY_CLASSIFIERS_UPDATE')
export const setDistrictStatistics = createAction('SET_DISTRICT_STATISTICS')
export const setDistrictsStatisticsByStatus = createAction('SET_DISTRICTS_BY_STATUS')
export const setDistrictsStatisticsByClassifiers = createAction('SET_DISTRICTS_BY_CLASSIFIERS')
export const setDistrictId = createAction('SET_DISTRICT_ID')
export const setTabsView = createAction('SET_TABS_VIEW')

//economics department
export const setProductsList = createAction('SET_PRODUCTS_LIST')
export const setProductsTypes = createAction('SET_PRODUCTS_TYPES')
export const setType = createAction('SET_TYPE')
export const setProductStatistic = createAction('SET_PRODUCT_STATISTIC')
//favorite
export const addToFavoriteSuccess = createAction('ADD_TO_FAVORITE_SUCCESS')
export const removeFavoriteSuccess = createAction('REMOVE_FAVORITE_SUCCESS')
export const getFavoriteSuccess = createAction('GET_FAVORITE_SUCCESS')
export const setFavorites = createAction('SET_FAVORITE')
export const setFavoritesDistrict = createAction('SET_FAVORITE_DISTRICT')
export const setFavoritesDataClassifier = createAction('SET_FAVORITE_DATA_CLASSIFIER')
export const setFavoritesDataClassifierTop = createAction('SET_FAVORITE_DATA_CLASSIFIER_TOP')
export const setFavoritesDataDistrict = createAction('SET_FAVORITE_DATA_DISTRICT')
export const setFavoritesDataEvents = createAction('SET_FAVORITE_DATA_EVENTS')
export const setFavoritesDataProducts = createAction('SET_FAVORITE_DATA_PRODUCTS')
export const setFavoritesGeneralStatistic = createAction('SET_FAVORITE_GENERAL_STATISTICS')
export const setFavoritesGeneralStatisticDistrict = createAction('SET_FAVORITE_GENERAL_STATISTICS_DISTRICT')
export const serInitialState = createAction('SET_INITIAL_STATE')
export const setAccidentState = createAction('SET_ACCIDENT_STATE')
export const setStatsEmpty = createAction('SET_STATS_EMPTY')

export const statisticsLoadingStart = createAction('STATISTIC_LOADING_START')
export const statisticsLoadingEnd = createAction('STATISTIC_LOADING_END')


export const setReceptionTypes = createAction('SET_RECEPTION_TYPES')
export const setReception = createAction('SET_RECEPTION')
export const setReceptionStatistic = createAction('SET_RECEPTION_STATISTIC')
// export const removeItemFavorite = createAction('REMOVE_ITEM_FAVORITE')
// export const updateFavoriteListData = createAction('UPDATE_FAVORITE_LIST_DATA')
