import {StatisticsApiService} from "../../api/stats.api";
import {
    setSummaryStatisticsSuccess,
    setSummaryStatisticsCount,
    setSummaryStatisticsByClassifiers,
    setDistrictStatistics,
    setDistrictsStatisticsByStatus,
    setDistrictsStatisticsByClassifiers,
    httpLoadingStart,
    httpLoadingEnd,
    setSummaryStatisticsDistrictsSuccess,
    setGeneralStatisticTopLevel,
    statisticsLoadingStart,
    statisticsLoadingEnd,
    setCategoryByStatus
} from './actionCreators'
import {
    format
} from "date-fns";

export const getTopLevelStatistics = (data, parentIds, startDate, endDate, isAccident) => {
    if(parentIds && parentIds[0] && parentIds[0].idsArray){
        return (dispatch, getState) => {
            const state = getState()
            const promises = parentIds.map(id => {
                return (
                    StatisticsApiService.getStatistics(`classifierIds=${id.idsArray && id.idsArray ? id.idsArray.toString() : id}&districtIds=${data}&createdBefore=${endDate}&createdAfter=${startDate}&isAccident=${isAccident}&noPage=true`)
                )
            })
            Promise.all(promises).then((responses)=>{
                dispatch(setGeneralStatisticTopLevel(responses))
            }).catch(()=>{

            })

        };
    }else{
        return (dispatch, getState) => {
            const state = getState()
            const promises = parentIds.map(id => {
                return (
                    StatisticsApiService.getStatistics(`classifierIds=${id.toString()}&districtIds=${data}&createdBefore=${endDate}&createdAfter=${startDate}&isAccident=${isAccident}&noPage=true`)
                )
            })
            Promise.all(promises).then((responses)=>{
                dispatch(setGeneralStatisticTopLevel(responses))
            }).catch(()=>{

            })

        };
    }

};
export const getSummaryStatistics = (data, classifierIds) => {
    return (dispatch, getState) => {
        const state = getState()
        const currentFilters = {
            classifierIds: classifierIds && classifierIds ? classifierIds.toString() : '',
            districtIds: data,
            createdAfter: state.auth.datePeriod.startDate,
            createdBefore: state.auth.datePeriod.endDate,
            isAccident: state.stats.accidentState ? true : null

        }
        const paramsString = Object
            .entries(currentFilters)
            .filter(currentFilters => currentFilters[1])
            .map((currentFilters) => {
                return currentFilters.join('=');
            }).join('&');
        StatisticsApiService.getSumStatistics(paramsString)
            .then((response) => {
                const obj = {results: response}

                dispatch(setSummaryStatisticsSuccess(obj));

            })
            .catch(err => {
                console.log('err',err)
            });
        // StatisticsApiService.eventsCount(paramsString)
        //     .then(response => {
        //         dispatch(setSummaryStatisticsCount(response));
        //     })
        //     .catch(err => {
        //         console.log('err',err)
        //     });
    };
};
export const getSummaryStatisticsDistricts = (data, classifierIds, startDate, endDate, isAccident) => {
    return (dispatch, getState) => {
        const state = getState()
        const arrayOfId = [];
        // console.log('classifierIds',classifierIds)
        // if(classifierIds && classifierIds[0].idsArray){
        //     classifierIds.forEach(item =>{
        //         if(item.idsArray){
        //             item.idsArray.forEach(arrayId => arrayOfId.push(arrayId))
        //         }else{
        //             arrayOfId.push(item)
        //         }
        //     })
        // }else{
        //     return classifierIds
        // }

        const currentFilters = {
            classifierIds: classifierIds,
            districtIds: data,
            createdAfter: startDate,
            createdBefore: endDate,
            isAccident: isAccident
        }
        const paramsString = Object
            .entries(currentFilters)
            .filter(currentFilters => currentFilters[1])
            .map((currentFilters) => {
                return currentFilters.join('=');
            }).join('&');
        StatisticsApiService.getSumStatistics(paramsString)
            .then((response) => {
                const obj = {results: response}
                dispatch(setSummaryStatisticsDistrictsSuccess(obj));
            })
            .catch(err => {
                console.log('err',err)
            });
        StatisticsApiService.eventsCount(paramsString)
            .then(response => {
                dispatch(setSummaryStatisticsCount(response));
            })
            .catch(err => {
                console.log('err',err)
            });

        // StatisticsApiService.eventsCount(`${paramsString}&isAccident=true`)
        //     .then(response => {
        //         dispatch(setAccidentCount(response));
        //     })
        //     .catch(err => {
        //         console.log('err',err)
        //     });
    };
};

export const getSummaryStatisticsByClassifiers = (data, classifierArray, startDate, endDate, isAccident) => {
    return (dispatch, getState) => {
        const state = getState()
        const createdAfter = startDate;
        const createdBefore = endDate;
        const classifierIds = []
        state.filters.classifiers.forEach((dataItem) => {
            if(dataItem.children){
                return dataItem.children.forEach((child) => {
                    classifierIds.push(child.id)
                })
            }
        })
        const classifiersIds = JSON.parse("[" + classifierArray + "]");
        const prom = classifiersIds;
        if(state.http.loading){
            return
        }else{
            dispatch(httpLoadingStart());
        }
        const promises = prom.map(id => StatisticsApiService.getStatistics(`classifierIds=${id}&${data !== undefined ? `districtIds=${data}` :''}&createdBefore=${createdBefore}&createdAfter=${createdAfter}&isAccident=${isAccident}&noPage=true`))
        Promise.all(promises).then((responses)=>{
            const array = []
            responses.forEach(res => {
                const obj = {results: res}
                array.push(obj)
            })
            if(responses.length){
                dispatch(httpLoadingEnd());
                dispatch(setSummaryStatisticsByClassifiers(array));
            }else {
                dispatch(httpLoadingEnd());
            }
        }).catch(()=>{

        })
    };
};

export const getDistrictStatistics = (data, startDate, endDate, classifiers, isAccident) => {
    return (dispatch, getState) => {
        dispatch(httpLoadingStart());
        const state = getState()
        const arrayOfId = [];
        const classifiersArray = typeof classifiers === 'string' ? JSON.parse("[" + classifiers + "]") : classifiers
        classifiersArray.forEach(item =>{
            if(item.idsArray){
                item.idsArray.forEach(arrayId => arrayOfId.push(arrayId))
            }else{
                arrayOfId.push(item)
            }
        })
        const prom = classifiers
        const promises = data.map(id => StatisticsApiService.getDistrictStatistics(`districtIds=${id}&classifierIds=${classifiers}&createdBefore=${endDate}&createdAfter=${startDate}&isAccident=${isAccident}&noPage=true`))
            Promise.all(promises).then((response)=>{
                const array = []
                response.forEach(res => {
                    const obj = {results: res}
                    array.push(obj)
                })
                dispatch(httpLoadingEnd());
                dispatch(setDistrictStatistics(array));
            }).catch(()=>{

            })
    };
};

export const getDistrictsStatisticsByStatus= (id,classifiersId, startDate, endDate, isAccident) => {
    return (dispatch, getState) => {
        dispatch(httpLoadingStart());
        dispatch(setDistrictsStatisticsByStatus([]));
        const state = getState()
        const object = {
            statusIds: id,
            classifierIds: classifiersId,
            createdAfter: format(new Date(startDate), `yyyy-MM-dd'T'HH:mm:ss`),
            createdBefore: format(new Date(endDate), `yyyy-MM-dd'T'HH:mm:ss`),
            isAccident: isAccident
        }
        const paramsString = Object
            .entries(object)
            .filter(object => object[1])
            .map((object) => {
                return object.join('=');
            }).join('&');

        StatisticsApiService.districtsStatsCount(paramsString)
            .then(response => {
                dispatch(httpLoadingEnd());
                dispatch(setDistrictsStatisticsByStatus(response));
            })
            .catch(err => {
            });
    };
};
export const getDistrictsStatisticsByClassifiers= (id, startDate, endDate) => {
    return (dispatch, getState) => {
        const state = getState()
        const object = {
            classifierIds: id,
            createdAfter: startDate,
            createdBefore: endDate,
            isAccident: state.stats.accidentState ? true : null,
            noPage: true
        }
        const paramsString = Object
            .entries(object)
            .filter(object => object[1])
            .map((object) => {
                return object.join('=');
            }).join('&');

        StatisticsApiService.districtsByClassifiers(paramsString)
            .then(response => {
                dispatch(setDistrictsStatisticsByClassifiers(response));
            })
            .catch(err => {
            });
    };
};

export const getStatisticByStatusAndCategory = (data, classifiers, isAccident, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(httpLoadingStart());
        const state = getState()
        const arrayOfId = [];
        state.filters.classifiers.forEach((dataItem) => {
            dataItem.children && dataItem.children.forEach((child) => {
                arrayOfId.push(child.id)
                child.children && child.children.forEach((childOfChild) => {
                    arrayOfId.push(childOfChild.id)
                })
            })
        })
        const promises = classifiers && classifiers.map(id => StatisticsApiService.eventsCount(`districtIds=${data.district_id}&${data && data.id ? `statusIds=${data.id}` : ''}&classifierIds=${id && id.idsArray ? id.idsArray : id}&createdBefore=${endDate}&createdAfter=${startDate}&isAccident=${isAccident}`))
        Promise.all(promises).then((response)=>{

            dispatch(httpLoadingEnd());
            dispatch(setCategoryByStatus(response));
        }).catch(()=>{
            dispatch(httpLoadingEnd());
        })
    };
};

export const getDistrictsFn = (data, classifiers) => {
    return (dispatch, getState) => {
        StatisticsApiService.getDistricts()
            .then((response) => {
            })
            .catch(err => {
                console.log('err',err)
            });
    };
};
