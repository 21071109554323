import {FiltersApiService} from "../../api/filters.api";
import {getNestedChildren} from "../../shared/utility";
import {
    setClassifiers,
    httpLoadingEnd,
    httpLoadingStart
} from './actionCreators'
import { logout } from './auth'

export const getClassifiers = () => {
    return (dispatch, getState) => {
        const classifiers = getState().filters.classifiers;
        if (classifiers.length) {
            dispatch(setClassifiers(classifiers));
        } else {
            dispatch(httpLoadingStart());
            FiltersApiService.getAll()
                .then(({results}) => {
                    const classifiers = getNestedChildren(results, null, 'parent_classifier_id');
                    dispatch(setClassifiers(classifiers));
                })
                .catch(error => {
                    console.log('err', error)
                    // if(error.response.status === 401){
                    //     dispatch(logout())
                    // }
                }).finally(() => dispatch(httpLoadingEnd()));
        }
    };
};
