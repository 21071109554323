import React, {useEffect} from "react";
import './Products.scss'
import {useParams, Link} from 'react-router-dom'
import Header from "../../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import { getProductsPriceStatisticById } from "../../../store/actions/economics";
import Icon from "@material-ui/core/Icon";
import When from '../../../components/When'
const Products = () => {
    const params = useParams()
    const {
        productDetails
    } = useSelector(state => {
        return state.economics;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductsPriceStatisticById(params.productId))
    }, [params.productId])

    const checkPrice = (product) => {
        if (product.growth_percent === '0.00' || product.growth_percent === null) {
            return;
        } else if (product.growth_percent !== null && product.growth_percent.indexOf('-') !== -1) {
            return 'price-down'
        } else {
            return 'price-up'
        }
    }

    const checkArrow = (product) => {
        if (product.growth_percent === '0.00' || product.growth_percent === null) {
            return <span>--</span>;
        } else if (product.growth_percent !== null && product.growth_percent.indexOf('-') !== -1) {
            return <Icon>arrow_downward</Icon>
        } else {
            return <Icon>arrow_upward</Icon>
        }
    }

    return (
        <div className='page-wrapper economic-department'>
            <Header
                showFilters={false}
                headerTitle={'Інформація щодо мінімальних цін на соціально значущі товари'}/>
            <div className="container">
                <div className='product-statistic-list'>
                    <div className="product-list-wrapper">
                        <h2 className='card-header'>
                            <Link to={`/dashboard/main-departments/economics-service/product-list/`}>
                                <Icon
                                    style={{color: '#202020', marginRight: 10}}>keyboard_backspace</Icon>
                            </Link>
                            {productDetails.title}
                        </h2>
                            <ul className='product-list'>
                                <When is={productDetails.latest_price.market_price !== null && productDetails.latest_price.supermarket_price !== null}>
                                    <When is={productDetails.latest_price.market_price !== null || productDetails.latest_price.supermarket_price !== null}>
                                        <li className='list-item'>
                                            <div className='name'>Середня цiна - ринки</div>
                                            <div
                                                className="prices">{productDetails && productDetails.latest_price.market_price ? productDetails.latest_price.market_price + ' грн' : '--'}</div>
                                        </li>
                                    </When>
                                    <When is={productDetails.latest_price.supermarket_price !== null || productDetails.latest_price.market_price !== null}>
                                        <li className='list-item'>
                                            <div className='name'>
                                                Середня цiна - маркети
                                            </div>
                                            <div
                                                className="prices">{productDetails && productDetails.latest_price.supermarket_price ? productDetails.latest_price.supermarket_price + ' грн' : '--'}</div>
                                        </li>
                                    </When>
                                </When>
                                <li className='list-item'>
                                    <div className='name'>
                                        Середня загальна
                                    </div>
                                    <div className="prices"><span>{productDetails && productDetails.latest_price.price} грн</span></div>
                                </li>
                                <li className='list-item'>
                                    <div className='name'>
                                        Динамiка з початку мiсяця
                                    </div>
                                    <div className={`prices ${checkPrice(productDetails)}`}>
                                        <span>{productDetails.latest_price.price} грн</span>
                                        <span style={{padding: 5}}> / </span>
                                        <span>{productDetails.growth_percent && productDetails.growth_percent.indexOf('-') !== -1 ? Math.round(productDetails.growth_percent.substr(1)) + '%' : (productDetails.growth_percent === null ? '0%' : Math.round(productDetails.growth_percent) + '%')}</span>
                                        <span className='arrow-block'>
                                            {checkArrow(productDetails)}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Products;
