import React, {useEffect, useState} from "react";
import {footerNavMainDepartment, footerNavServiceRequest} from '../../textConstants/textConstants'
import Footer from "../../components/Footer/Footer";
import {Link, NavLink} from "react-router-dom";
import Header from "../../components/Header/Header";
import './Favorites.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    getFavorite,
    getFavoriteStatsClassifier,
    getFavoriteStatsDistrict,
    removeFavoriteClassifier,
    removeFavoriteDistict,
    removeFavoriteEvents,
    getFavoriteEvents,
    getFavoriteProducts,
    removeFavoriteProducts,
    getFavoriteGeneralStatistics,
    getFavoriteGeneralStatisticsDistricts,
    getFavoriteStatsClassifierTop
} from '../../store/actions/favorites'
import {sortFavorite, mergeFavorite} from "../../store/selectors/favorite";
import FavoriteCard from './FavoriteCard'
import FavoriteEventCard from './FavoriteEventCard'
import ProductList from "../../components/productsList/ProductList";
import FavoriteGeneralStatistics from "./FavoriteGeneralStatistics";
import {AppConstants} from "../../App.Constants";
import {setAccidentState} from "../../store/actions/actionCreators";

const districts = {
    1: 'Немишлянський',
    2: 'Індустріальний',
    3: 'Шевченківський',
    4: 'Київський',
    5: 'Московський',
    6: 'Холодногірський',
    7: 'Новобаварський',
    8: 'Основ\'янський',
    9: 'Слобідський',
}

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}
const Favorites = () => {
    const favorites = useSelector(sortFavorite)
    const favoritesData = useSelector(mergeFavorite)
    const dispatch = useDispatch();
    const {
        favoritesListEvents
    } = useSelector(state => {
        return state.favorites;
    });

    useEffect(() => {
        dispatch(setAccidentState(false))
        dispatch(getFavorite())
    }, []);
    useEffect(() => {
        dispatch(getFavoriteProducts(favorites.productsFavorite));
        dispatch(getFavoriteStatsClassifier(favorites.classifiersFavorite));
        dispatch(getFavoriteStatsClassifierTop(favorites.classifiersTopFavorite));
        dispatch(getFavoriteStatsDistrict(favorites.districtsFavorite));
        dispatch(getFavoriteEvents(favorites.eventsFavorite));
        dispatch(getFavoriteGeneralStatistics(favorites.generalStatsFavorite));
        dispatch(getFavoriteGeneralStatisticsDistricts(favorites.generalStatsFavoriteDistrict));
    }, [favorites]);

    const removeFromFavoriteClassifier = (item) => {
        dispatch(removeFavoriteClassifier(item))
    }
    const removeFromFavoriteDistirct = (item) => {
        dispatch(removeFavoriteDistict(item))
    }
    const removeFromFavoriteEvents = (item) => {
        dispatch(removeFavoriteEvents(item))
    }
    const removeFromFavoriteProducts = (item) => {
        dispatch(removeFavoriteProducts(item))
    }

    return (
        <div className='favorites-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Улюбленi'}/>
            <div className="content-child">
                <FavoriteGeneralStatistics
                    data={favoritesData.generalStatisticsList}/>
                <FavoriteGeneralStatistics
                    data={favoritesData.generalStatisticsDistrictList}/>
                <FavoriteCard
                    onClick={removeFromFavoriteClassifier}
                    data={favoritesData.classifiers}/>
                <FavoriteCard
                    onClick={removeFromFavoriteClassifier}
                    data={favoritesData.classifiersTop}/>
                <FavoriteCard
                    district
                    onClick={removeFromFavoriteDistirct}
                    data={favoritesData.districts}/>
                <FavoriteEventCard
                    onClick={removeFromFavoriteEvents}
                    data={favoritesData.events}/>
                {favoritesData.productsList.map((product) => <ProductList
                    favorite
                    removeFromFavorites={removeFromFavoriteProducts}
                    title={product.productList[0].type.name}
                    products={product.productList}/>)}

            </div>
            <Footer footerNav={footerNavMainDepartment}/>
        </div>
    )
};

export default Favorites;
