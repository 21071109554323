import React, {useState} from "react";
import './../ServiceRequests.scss'
import {Link, useParams} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import {useDispatch, useSelector} from "react-redux";
import {
    addToFavorite,
    removeFavoriteClassifier
} from '../../../store/actions/favorites'
import When from '../../../components/When'
import RequestsSummaryStatusLine from "../../../components/requestsSummaryStatusLine/RequestsSummaryStatusLine";

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}
const ByCategory = ({statisticsByCat}) => {
    const dispatch = useDispatch();

    const [isFavorite, updateFavorite] = useState(false);

    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });
    const {
        user: {
            settings: {
                timePeriod: {
                    period
                }
            }
        }
    } = useSelector(state => {
        return state.auth;
    });

    const {
        accidentState
    } = useSelector(state => {
        return state.stats;
    });

    const toggleFavorite = (statistic) => {
        const data = {
            classifierIds: statistic.title.id,
            title: statistic.title.text,
            type: 'classifier',
            isAccident: accidentState ? true : null,
            timePeriod: {
                period: period,
                startDate: startDate,
                endDate: endDate
            }
        }
        dispatch(addToFavorite(data))
        updateFavorite(!isFavorite)

        setTimeout(() => {
            updateFavorite(false)
        }, 2000)
    }
    const removeFromFavoriteClassifier = (item) => {
        dispatch(removeFavoriteClassifier(item))
    }

    return (
        <div>
            {
                statisticsByCat && statisticsByCat.length ? statisticsByCat.slice().sort((a,b) => {
                    return b.sumCount - a.sumCount
                }).map((statistic, index) => {
                    const sum = statistic.results.length ? statistic.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : 0;
                    return (
                        <div key={index} className="requests-summary">
                            <div className="card-details">
                                <When is={isFavorite}>
                                    <div className='success-alert'>
                                        Картка добавлена в улюблене
                                    </div>
                                </When>
                                <div className='card-header'>
                                    <div className='card-title'>
                                        <Link
                                            to={`/dashboard/main-departments/service-requests/sub-category/${statistic && statistic.results[0].classifier_ids}/${startDate}/${endDate}/classifier/${statistic && statistic.results[0].is_accident}`}>
                                            <span className='title-name'>
                                                {statistic.title.text}
                                                <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                                            </span>
                                        </Link>
                                    </div>
                                    {
                                        statistic.title.isFavorite ?
                                            <Icon onClick={() => removeFromFavoriteClassifier(statistic)}
                                                  style={{color: '#DE8F00'}}>star</Icon>
                                            :
                                            <Icon onClick={() => toggleFavorite(statistic)}
                                                  style={{color: '#D8D8D8'}}>star_border</Icon>
                                    }
                                </div>
                                <Link
                                    to={`/dashboard/main-departments/service-requests/sub-category/${statistic && statistic.results[0].classifier_ids}/${startDate}/${endDate}/classifier/${statistic && statistic.results[0].is_accident}`}>
                                    <div className='count general-count'>
                                        <span>Усього</span>
                                        <span className='sum-arrow'>
                                            <span className='summary-text'>{sum}</span>
                                            <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                        </span>
                                    </div>
                                </Link>
                                {/*<div className='count'>*/}
                                {/*    <span>В тому числі аварій</span>*/}
                                {/*    <span className='summary-text'>{statistic.title.accidentCount}</span>*/}
                                {/*</div>*/}
                                <div className="card-status-line">
                                    <RequestsSummaryStatusLine data={statistic.results}/>
                                </div>
                                <ul className='details-list'>
                                    {
                                        statistic.results.map((status) => {
                                            return (
                                                <li key={status.id}>
                                                    <When is={status.events_count === 0}>
                                                        <div className='overlay'></div>
                                                    </When>
                                                    <Link
                                                        to={`/dashboard/main-departments/service-requests/sub-category/${status.classifier_ids}/${status.created_after}/${status.created_before}/classifier/${status.id}/district/${false}/${status.is_accident}`}
                                                        className='list-item'>
                                                        {status.name}
                                                        <span style={{color: status.id === 11 ? '#138481' : '#FD0101'}}>
                                                            {status.events_count}
                                                        </span>
                                                        <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                }) : null
            }
        </div>

    )
};

export default ByCategory;
