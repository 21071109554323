import axios from '../App.Axios';
import {AppConstants} from "../App.Constants";

export class EconomicsApiService {
    static getProductsTypes(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `economics_service/products-types/`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getProducts(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `economics_service/products/?noPage=true&typeIds=${data}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getProductById(id) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `economics_service/products/${id}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
}

