import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom'
import * as actions from "../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import ListDetails from '../../../components/listDetails/ListDetails'
import DepartmentFilters from "../../../components/departmentFilters/DepartmentFilters";
import {CSSTransition} from "react-transition-group";
import Header from "../../../components/Header/Header";
import {addToFavorite, getFavorite, removeFavoriteEvents} from "../../../store/actions/favorites";
import When from "../../../components/When";
import {getEvents} from "../../../store/selectors/events";
import {serInitialState} from "../../../store/actions/actionCreators";

const EventsDetails = () => {
    const eventsList = useSelector(getEvents)
    const dispatch = useDispatch();
    const params = useParams()
    const [isFavorite, updateFavorite] = useState(false);
    const [showFlash, showExistFavoriteFlash] = useState(false);
    useEffect(() => {
        dispatch(actions.getEvents(params.district_id && params.district_id ? params.district_id : null, params.id, params.classifiers ? params.classifiers : '',  params.startDate, params.endDate, params.isAccident));
        return () => {
            dispatch(serInitialState());
        }
    },[params]);
    const showFilters = useSelector(state => {
        return state.filters.show;
    });
    const {
        favoritesList
    } = useSelector(state => {
        return state.favorites;
    });

    useEffect(() => {
        dispatch(getFavorite())
    }, []);

    const {
        user: {
            settings: {
                timePeriod: {
                    period, startDate, endDate
                }
            }
        }
    } = useSelector(state => {
        return state.auth;
    });
    const toggleFavorite = (item) => {
        const classifiers = []
        item.classifiers.forEach(classifier => classifiers.push(classifier.id))
        const data = {
            eventId: item.event_id,
            classifierIds: classifiers,
            type: 'event-details',
            timePeriod: {
                period: period,
                startDate: startDate,
                endDate: endDate
            }
        }
        dispatch(addToFavorite(data))
        updateFavorite(!isFavorite)
        setTimeout(() => {
            showExistFavoriteFlash(false)
        }, 2000)

        setTimeout(() => {
            updateFavorite(false)
        }, 2000)
    }
    const removeFromFavoriteEvents = (item) => {
        dispatch(removeFavoriteEvents(item))
    }
    return (
        <div id='scrolled-cont' className='page-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Заявки'}/>
            <CSSTransition
                in={showFilters}
                timeout={300}
                classNames="filters"
                unmountOnExit
            >
                <DepartmentFilters/>
            </CSSTransition>
            <div className='content service-page'>
                <div className="container">
                    {/*<Search/>*/}
                    <When is={isFavorite}>
                        <div className='success-alert'>
                            Картка добавлена в улюблене
                        </div>
                    </When>
                    <When is={showFlash}>
                        <div className='success-alert exist'>
                            Эта карточка уже добавленна в избранное
                        </div>
                    </When>
                    <ListDetails
                        addToFavorite={toggleFavorite}
                        removeFromFavorite={removeFromFavoriteEvents}
                        events={eventsList}/>
                </div>
            </div>
        </div>
    )
};

export default EventsDetails;
