import React, {useEffect} from "react";
import './DistrictsByFilters.scss'
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {
    getDistrictsStatisticsByClassifiers
} from '../../../store/actions/stats'
import Header from "../../../components/Header/Header";
import When from "../../../components/When";

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}
// const statusesList = [
//     {
//         status_name: 'Не вирішено',
//         events_count: 0,
//     },
//     {
//         status_name: 'Вирішено',
//         events_count: 0,
//     },
//     {
//         status_name: 'Вирішено виконавцем',
//         events_count: 0,
//     },
//     {
//         status_name: 'У роботі',
//         events_count: 0,
//     },
//     {
//         status_name: 'Роз\'яснено',
//         events_count: 0,
//     }
// ]
const DistrictsByFilters = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const { districtStatisticsByClassifiers } = useSelector(state => {
        return state.stats;
    });
    useEffect(() => {
        dispatch(getDistrictsStatisticsByClassifiers(params.classifiers, params.startDate, params.endDate));
    }, [params])
    // const showStatusesList = (item) => {
    //     const newStatusesList = [...statusesList]
    //     const statusesObject = {};
    //     item.events_counts.forEach(itemStatus => {
    //         statusesObject[itemStatus.status_name] = itemStatus.status_name
    //     })
    //     const filteredStatusesList = newStatusesList.filter(status => status.status_name !== statusesObject[status.status_name])
    //
    //     return (
    //         filteredStatusesList.map(status => {
    //             return (
    //                 <li>
    //                     <When is={status.events_count === 0}>
    //                         <div className='overlay'></div>
    //                     </When>
    //                     {status.status_name}
    //                     <span style={{color: colors[status.status_id]}}>
    //                         {status.events_count}
    //                     </span>
    //                 </li>
    //             )
    //         })
    //     )
    // }



    const statusesNames = (item) => {
        const mergedArray = []
        const notSolved = []
        item.events_counts.forEach(status => {
            if(status.status_id === 11){
                mergedArray.push({...status, status_name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.push({...notSolved[0], status_id: [1,2,3,4,5,6,7,8,9,10,12,13,14], status_name: 'У роботі', events_count: summaryCount})

        return (
            mergedArray && mergedArray.length && mergedArray.map((status) => {
                let statusId = status.status_id;
                let district_id = item.district_id;
                if(status.status_name !== null){
                    return (
                        <li >
                            <When is={status.events_count === 0}>
                                <div className='overlay'></div>
                            </When>
                            <Link
                                to={`/dashboard/main-departments/service-requests/sub-category/${params.classifiers}/${params.startDate}/${params.endDate}/${statusId}/classifier/events-details/${district_id}/${params.isAccident}`}
                                className='list-item'>
                                {status.status_name}
                                <span style={{color: status.status_id === 11 ? '#138481' : '#FD0101'}}>{status.events_count}</span>
                            </Link>
                        </li>
                    )
                }

            })
        )
    }
    return (
        <div className='page-wrapper requests-summary districts-filters'>
            <Header
                showFilters={false}
                headerTitle={'Список районів за Категорією'}/>
            <div className='content service-page'>
                <div className="container">
                    {
                        districtStatisticsByClassifiers.slice().sort((a,b) => {
                            const aStatus = a.events_counts.find((status)=> status.status_id === 1 ) || {}
                            const bStatus = b.events_counts.find((status)=> status.status_id === 1 ) || {}
                            return bStatus.events_count - aStatus.events_count
                        }).map((item) => {
                            const statisticsSum = item.events_counts.length ? item.events_counts.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : '0';
                            return(
                                <div className="card-details">
                                    <div className='card-header'>
                                        <span className='card-title'>
                                            <span>{item.district_name}</span>
                                        </span>
                                    </div>
                                    <ul className='details-list'>
                                        <li className='count'>
                                            <span className='title'>Усього</span>
                                            <span className='summary-text'>{statisticsSum}</span>
                                        </li>
                                        {statusesNames(item)}
                                    </ul>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
};

export default DistrictsByFilters;
