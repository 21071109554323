import React, {useEffect, useState} from "react";
import "./DepartmentFilters.scss";
import Search from "../search/Search";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from "@material-ui/core/Checkbox";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {useDispatch, useSelector} from "react-redux";
import {
    setStatsEmpty,
    toggleFilters,
} from '../../store/actions/actionCreators';
import {updateUserSettings} from '../../store/actions/auth';
import * as _ from 'lodash';
import {getFiltersIds} from '../../store/selectors/filters';
import {number} from "prop-types";

const DepartmentFilters = () => {
    const [selectedIdis, setSelectedIdis] = useState({})
    const [classifiers, updateClassifiers] = useState([]);
    const [selected, updateSelected] = useState([]);
    const [isSearch, updateIsSearch] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const [expandedChild, setExpandedChild] = useState(null);
    const parentsIds = useSelector(getFiltersIds)
    const dispatch = useDispatch();

    const data = useSelector(state => state.filters.classifiers);
    const hide = useSelector(state => !state.filters.show);
    const userSettings = useSelector(state => state.auth.user.settings);
    const {
        timePeriod
    } = useSelector(state => {
        return state.auth.user.settings;
    });
    useEffect(() => {
        updateClassifiers(data);
    }, [data]);

    useEffect(() => {
        const userSelected = userSettings ? (userSettings.classifiers || []) : [];
        const selectedIds = userSelected.reduce((acc, id) => {
            acc[id] = true;
            return acc;
        }, {});
        setSelectedIdis(selectedIds)
        updateSelected(userSelected);
    }, [userSettings]);

    useEffect(() => {
        if (hide) {
            const arrayOfIds = Object.entries(selectedIdis).filter((item) => item[1]).map((item) => item[0]).filter(id => parentsIds.resultArraysId.includes(Number(id)))
            const arr = JSON.parse("[" + arrayOfIds + "]")
            dispatch(setStatsEmpty())
            dispatch(updateUserSettings({
                classifiers: arr,
                timePeriod: timePeriod
            }));
        }
    }, [hide, selected, dispatch, parentsIds]);

    const getCategoriesIds = (category, checked, ids = {}) => {
        let result = {...ids, [category.id]: checked};

        if (!category.children) {
            return result;
        }

        category.children.forEach(child => {
            result = {...result, ...getCategoriesIds(child, checked, result)}
        });

        return result;
    }

    const isIndeterminate = (item) => {
        if (!item.children) {
            return false
        }
        let selected = [];

        item.children.forEach((child) => {
            const isSelected = selectedIdis[child.id] || isIndeterminate(child)
            if (isSelected) {
                selected.push(child.id)
            }

        })
        return selected.length && selected.length < item.children.length
    }

    const handleCategoryClick = (event, item) => {
        const checked = event.target.checked;
        const categoriesIds = getCategoriesIds(item, checked, {});
        setSelectedIdis({...selectedIdis, ...categoriesIds});
    };

    const isCategorySelected = (item) => {
        const isSelected = Boolean(selectedIdis[item.id]);
        if (!item.children) {
            return isSelected
        }
        return item.children.every((child) => {
            return isCategorySelected(child)
        })
    };


    const searchInClassifier = (classifier, term, result = []) => {
        const classifierTitle = classifier.title.toLowerCase();

        if (classifierTitle.indexOf(term) !== -1) {
            result.push(classifier);
        }

        if (!classifier.children) {
            return result;
        }

        classifier.children.forEach((child) => {
            const childResult = searchInClassifier(child, term);

            if (childResult.length) {
                result = result.concat(childResult);
            }
        });

        return result;
    };

    const getSearchResult = (classifiers, term) => {
        let result = [];

        classifiers.forEach((classifier) => {
            result = result.concat(searchInClassifier(classifier, term));
        });

        return result;
    };


    const search = (event) => {
        const term = event.target.value.toLowerCase();
        const newClassifiers = getSearchResult(data, term);

        updateClassifiers(term.length ? newClassifiers : data);
        updateIsSearch(!!term.length);
        setExpanded(null);
    };

    const handleExpand = filterId => {
        setExpanded(!(expanded === filterId) ? filterId : null);
    };
    const handleExpandChild = filterId => {
        setExpandedChild(!(expandedChild === filterId) ? filterId : null);
    };

    const filterEvents = () => {
        dispatch(toggleFilters())
    };

    return (
        <div className="department-filters">
            <div className="filters-card">
                <div className="filters-card-header">
                    Рiвнi Категорій
                </div>
                <div className="filters-card-search">
                    <Search onChange={search}/>
                </div>
                <div className="filters-card-list">
                    {
                        classifiers.map((item) => {
                            return (
                                <ExpansionPanel key={item.id} expanded={isSearch || expanded === item.id}>
                                    <ExpansionPanelSummary
                                        expandIcon={item.children && item.children.length ?
                                            <ExpandMoreIcon fontSize="large" color="primary"/> : null}
                                        aria-label="Expand"
                                        onClick={() => handleExpand(item.id)}
                                        aria-controls="additional-actions1-content"
                                        id="additional-actions1-header"
                                    >
                                        <FormControlLabel
                                            aria-label={item.title}
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<Checkbox
                                                onChange={(event) => handleCategoryClick(event, item)}
                                                checked={isCategorySelected(item)}
                                                indeterminate={isIndeterminate(item)}
                                                checkedIcon={<span className="check-box check-box-checked"/>}
                                                indeterminateIcon={<span
                                                    className="check-box check-box-indeterminate"/>}
                                                icon={<span className="check-box"/>}
                                            />}
                                            label={item.title}
                                        />
                                    </ExpansionPanelSummary>
                                    {
                                        item.children && item.children.map(child => {
                                            return (
                                                <ExpansionPanel className='children-panel' key={child.id}
                                                                expanded={isSearch || expandedChild === child.id}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={child.children && child.children.length ?
                                                            <ExpandMoreIcon fontSize="large" color="primary"/> : null}
                                                        aria-label="Expand"
                                                        onClick={() => handleExpandChild(child.id)}
                                                        aria-controls="additional-actions1-content"
                                                        id="additional-actions1-header"
                                                    >
                                                        <FormControlLabel
                                                            aria-label={child.title}
                                                            onClick={event => event.stopPropagation()}
                                                            onFocus={event => event.stopPropagation()}
                                                            control={<Checkbox
                                                                onChange={(event) => handleCategoryClick(event, child)}
                                                                checked={isCategorySelected(child)}
                                                                indeterminate={isIndeterminate(child)}
                                                                checkedIcon={<span
                                                                    className="check-box check-box-checked"/>}
                                                                indeterminateIcon={<span
                                                                    className="check-box check-box-indeterminate"/>}
                                                                icon={<span className="check-box"/>}
                                                            />}
                                                            label={child.title}
                                                        />
                                                    </ExpansionPanelSummary>
                                                    {child.children && child.children.length ? (
                                                        <ExpansionPanelDetails>
                                                            {
                                                                child.children.map(childed => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            aria-label={childed.title}
                                                                            onClick={event => event.stopPropagation()}
                                                                            onFocus={event => event.stopPropagation()}

                                                                            control={<Checkbox
                                                                                onChange={(event) => handleCategoryClick(event, childed)}
                                                                                checked={isCategorySelected(childed)}
                                                                                checkedIcon={<span
                                                                                    className="check-box check-box-checked"/>}
                                                                                icon={<span className="check-box"/>}
                                                                            />}
                                                                            label={childed.title}
                                                                            key={childed.id}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </ExpansionPanelDetails>
                                                    ) : null}
                                                </ExpansionPanel>
                                            )

                                        })
                                    }
                                    {/*{item.children && item.children.length ? (*/}
                                    {/*    <ExpansionPanelDetails>*/}
                                    {/*        {*/}
                                    {/*            item.children.map(child => {*/}
                                    {/*                return (*/}
                                    {/*                    <FormControlLabel*/}
                                    {/*                        aria-label={child.title}*/}
                                    {/*                        onClick={event => event.stopPropagation()}*/}
                                    {/*                        onFocus={event => event.stopPropagation()}*/}

                                    {/*                        control={<Checkbox*/}
                                    {/*                            onChange={() => handleChildClick(child.id)}*/}
                                    {/*                            checked={isChildSelected(child.id)}*/}
                                    {/*                            checkedIcon={<span className="check-box check-box-checked" />}*/}
                                    {/*                            icon={<span className="check-box" />}*/}
                                    {/*                        />}*/}
                                    {/*                        label={child.title}*/}
                                    {/*                        key={child.id}*/}
                                    {/*                    />*/}
                                    {/*                )*/}
                                    {/*            })*/}
                                    {/*        }*/}
                                    {/*    </ExpansionPanelDetails>*/}
                                    {/*) : null}*/}
                                </ExpansionPanel>
                            )
                        })
                    }
                </div>
            </div>
            <div className='filter-btn-wrapper'>
                <button className='btn-primary' onClick={filterEvents}>Фільтрувати</button>
            </div>
        </div>
    )
};

export default DepartmentFilters;
