import axios from '../App.Axios';
import {AppConstants} from "../App.Constants";
import * as actionTypes from "../store/actions/actionTypes";

export class StatisticsApiService {
    static getSumStatistics(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `dispatching_service/statistics/statuses-count/?${data ? data : ''}&noPage=true`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getDistricts(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `dispatching_service/objects/districts/`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getStatistics(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `dispatching_service/statistics/statuses-count/?${data ? data : ''}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static eventsCount(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `dispatching_service/statistics/events-count/?${data ? data : ''}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getDistrictStatistics(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `dispatching_service/statistics/statuses-count/?${data}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static districtsStatsCount(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `dispatching_service/statistics/districts-statuses-events-count/?${data}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static districtsByClassifiers(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `dispatching_service/statistics/custom-districts-count/?${data}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
}

