import React from "react";
import {NavLink} from "react-router-dom";
import "./Footer.scss";

const Footer = ({footerNav}) => {
    return (
        <div className="Footer">
            <ul>
                {
                    footerNav.map(item => {
                        return (
                            <li key={item.text}>
                                <NavLink
                                    exact
                                    activeClassName="active"
                                    to={item.link} className={`footer-nav-link ${item.className}`}>
                                    {item.icon}
                                    {item.text}
                                </NavLink>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
};

export default Footer;
