import {createReducer} from "@reduxjs/toolkit";

import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

import {
    setSummaryStatisticsSuccess,
    setUser,
    setUserSettings,
    setSummaryStatisticsCount,
    setSummaryStatisticsByClassifiers,
    setDistrictStatistics,
    setDistrictsStatisticsByStatus,
    setDistrictsStatisticsByClassifiers,
    setDistrictId,
    setTabsView,
    setAccidentCount,
    setSummaryStatisticsDistrictsSuccess,
    setAccidentCountByClassifiers,
    setAccidentCountByDistrict,
    serInitialState,
    setAccidentState,
    setStatsEmpty,
    setSummaryStatisticsByClassifiersUpdate,
    addToFavoriteSuccess,
    statisticsLoadingStart,
    statisticsLoadingEnd,
    setGeneralStatisticTopLevel,
    setCategoryByStatus
} from './../actions/actionCreators'
import {func} from "prop-types";

const initialState = {
    tabValue: 0,
    topLevelStatistic: [],
    summaryStatistics: {
        count: null,
        next: null,
        previous: null,
        statistics: []
    },
    summaryStatisticsDistrict: {
        count: null,
        next: null,
        previous: null,
        statistics: []
    },
    accidentCount: {
        count: null
    },
    statisticsCounter: null,
    statisticsByClassifiers: [],
    classifiersNames: [],
    classifiersId: null,
    districtId: '',
    currentFilters: {
        districtIds: '',
        classifierIds: ''
    },
    statisticsByClass: [],
    statusId: null,
    districtStatistics: [],
    districtStatisticsByStatus: [],
    districtStatisticsByClassifiers: [],
    accidentCountByClassifiers: [],
    accidentCountByDistrict: [],
    accidentState: false,
    statsLoadedCount: 0,
    lastCount: 6,
    statisticsLoading: false,
    categoryByStatus: []
};

const reducer = createReducer(initialState, {
    [setTabsView]: (state, action) => {
        state.tabValue = action.payload
    },
    [statisticsLoadingStart]: (state, action) => {
        state.statisticsLoading = true
    },
    [statisticsLoadingEnd]: (state, action) => {
        state.statisticsLoading = false
    },
    [setSummaryStatisticsSuccess]: (state, action) => {
        state.summaryStatistics = {
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            statistics: action.payload.results
        }
    },
    [setSummaryStatisticsDistrictsSuccess]: (state, action) => {
        state.summaryStatisticsDistrict = {
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            statistics: action.payload.results
        }
    },
    [setAccidentCount]: (state, action) => {
        state.accidentCount = {
            count: action.payload.count
        }
    },
    [setAccidentCountByClassifiers]: (state, action) => {
        state.accidentCountByClassifiers = action.payload
    },
    [setAccidentCountByDistrict]: (state, action) => {
        state.accidentCountByDistrict = action.payload
    },
    [setSummaryStatisticsCount]: (state, action) => {
        state.statisticsCounter = action.payload.count
    },
    [setGeneralStatisticTopLevel]: (state, action) => {
        state.topLevelStatistic = action.payload
    },
    [setCategoryByStatus]: (state, action) => {
        state.categoryByStatus = action.payload
    },
    [setSummaryStatisticsByClassifiers]: (state, action) => {
        state.statisticsByClassifiers = action.payload;
        state.statsLoadedCount = state.statisticsByClassifiers.length
        state.lastCount = state.statisticsByClassifiers.length + 6
    },
    [setSummaryStatisticsByClassifiersUpdate]: (state, action) => {
        state.statisticsByClassifiers = [...state.statisticsByClassifiers,...action.payload];
        state.statsLoadedCount = state.statisticsByClassifiers.length
        state.lastCount = state.statisticsByClassifiers.length + 6
    },
    [setStatsEmpty]: (state, action) => {
        state.statisticsByClassifiers = [];
        state.statsLoadedCount = 0;
        state.lastCount = 6;
    },
    [setDistrictStatistics]: (state, action) => {
        state.districtStatistics = action.payload
    },
    [setDistrictsStatisticsByStatus]: (state, action) => {
        state.districtStatisticsByStatus = action.payload
    },
    [setDistrictsStatisticsByClassifiers]: (state, action) => {
        state.districtStatisticsByClassifiers = action.payload
    },
    [setDistrictId]: (state, action) => {
        state.districtId = action.payload
    },
    [setUser]: (state, action) => {
        state.currentFilters = {
            classifierIds: action.payload.settings.classifiers.toString()
        };
    },
    [setUserSettings]: (state, action) => {
        state.currentFilters = {
            classifierIds: action.payload.settings.classifiers.toString()
        }
    },
    [setAccidentState]: (state, action) => {
        state.accidentState = action.payload
    },
    [serInitialState]: (state, action) => {
        state.districtStatisticsByStatus = []
    },

})

export default reducer;
