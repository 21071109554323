import React, {useEffect} from "react";
import './../ServiceRequests.scss'
import {useParams} from "react-router-dom";
import TimeFilter from "../../../components/timeFilter/TimeFilter";
import {useDispatch, useSelector} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Footer from '../../../components/Footer/Footer'
import {footerNavServiceRequest} from '../../../textConstants/textConstants'
import {
    getDistrictStatistics,
    getSummaryStatisticsByClassifiers,
} from '../../../store/actions/stats';

import {
    getStatistics, getStatisticsByDistrict
} from '../../../store/selectors/stats'
import {
    setTabsView,
} from "../../../store/actions/actionCreators";
import Header from "../../../components/Header/Header";
import ByCategory from './byCategory';
import ByDistrict from './byDistrict';

const TopCategoryDetailsByStatus = () => {
    const params = useParams()
    const statisticsByCat = useSelector(getStatistics)
    const statisticsByDistrict = useSelector(getStatisticsByDistrict)

    const {
        tabValue
    } = useSelector(state => {
        return state.stats;
    });

    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });

    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });

    const dispatch = useDispatch();

    const [value, setValue] = React.useState(tabValue);
    useEffect(() => {
        dispatch(getSummaryStatisticsByClassifiers(params && params.district_id && params.district_id ? params.district_id :[1, 2, 3, 4, 5, 6, 7, 8, 9], params.classifiers, startDate ? startDate : params.startDate, endDate ? endDate : params.endDate, false))
    }, [settings]);

    const handleChange = (event, newValue) => {
        dispatch(setTabsView(newValue))
        setValue(newValue);
    };
    return (
        <div id='scrolled-cont' className='page-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Звернення до служби 1562'}/>
            <div className={`service-page`}>
                <div className="container">
                    <ByCategory
                        statisticsByCat={statisticsByCat}/>
                </div>
            </div>
            <Footer footerNav={footerNavServiceRequest}/>
        </div>
    )
};

export default TopCategoryDetailsByStatus;
