import {createReducer} from "@reduxjs/toolkit";
import {
    setReceptionTypes,
    setReception,
    setReceptionStatistic
} from './../actions/actionCreators'
const initialState = {
    receptionTypes: [],
    reseptions: [],
    receptionStatistic: []
};

const reducer = createReducer(initialState, {
    [setReceptionTypes]: (state, action) => {
        state.receptionTypes = action.payload
    },
    [setReception]: (state, action) => {
        state.reseptions = action.payload
    },
    [setReceptionStatistic]: (state, action) => {
        state.receptionStatistic = action.payload
    },
})

export default reducer;
