import React, {useEffect, useState} from "react";
import './ServiceRequests.scss'
import {Link} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import {addToFavorite, removeFavoriteDistict} from "../../store/actions/favorites";
import {useDispatch, useSelector} from "react-redux";
import When from "../../components/When";
import {
    getDistrictStatistics,
} from "../../store/actions/stats";
import {getFiltersIds, getParentCategories} from "../../store/selectors/filters";

const districts = {
    1: 'Немишлянський',
    2: 'Індустріальний',
    3: 'Шевченківський',
    4: 'Київський',
    5: 'Московський',
    6: 'Холодногірський',
    7: 'Новобаварський',
    8: 'Основ\'янський',
    9: 'Слобідський',
}

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}

const DistrictsTab = ({generalDistricts}) => {
    const dispatch = useDispatch();
    const parentIds = useSelector(getParentCategories)
    const ids = useSelector(getFiltersIds)
    const [isFavorite, updateFavorite] = useState(false);
    const [showFlash, showExistFavoriteFlash] = useState(false);

    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });

    const {
        accidentState
    } = useSelector(state => {
        return state.stats;
    });

    const {
        favoritesList
    } = useSelector(state => {
        return state.favorites;
    });
    const {
        user: {
            settings: {
                classifiers,
                timePeriod: {
                    period, startDate, endDate
                }
            }
        }
    } = useSelector(state => {
        return state.auth;
    });
    useEffect(() => {
        dispatch(getDistrictStatistics([1, 2, 3, 4, 5, 6, 7, 8, 9], startDate, endDate, classifiers && classifiers.length ? classifiers : ids.resultArraysId, accidentState === false ? null : true));
    }, [parentIds, accidentState, endDate, startDate, classifiers]);

    const toggleFavorite = (district) => {
        const data = {
            districtIds: district.results[0].district_ids[0],
            title: districts[district.results[0].district_ids[0]],
            type: 'district',
            classifiers: district.results[0].classifier_ids,
            isAccident: accidentState ? true : null,
            timePeriod: {
                period: period,
                startDate: startDate,
                endDate: endDate
            }
        }

        dispatch(addToFavorite(data))
        updateFavorite(!isFavorite)

        setTimeout(() => {
            updateFavorite(false)
        }, 2000)
    }
    const removeFromFavoriteDistirct = (item) => {
        dispatch(removeFavoriteDistict(item))
    }

    return (
        generalDistricts.length ? generalDistricts.slice().sort((a,b) => {
            return b.sumCount - a.sumCount
        }).map((district,index) => {
            const sum = district.results.length ? district.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : 0;
            return (
                <When key={index} is={district.results.length}>
                    <div key={index}
                         className="requests-summary">
                        <div className="card-details">
                            <When is={isFavorite}>
                                <div className='success-alert'>
                                    Картка добавлена в улюблене
                                </div>
                            </When>
                            <When is={showFlash}>
                                <div className='success-alert exist'>
                                    Эта карточка уже добавленна в избранное
                                </div>
                            </When>
                            <div className='card-header'>
                                <div className='card-title'>
                                    <Link
                                        to={`/dashboard/main-departments/service-requests/statistics-by-district/${district.results && district.results[0].classifier_ids}/${district.results.length ? district.results[0].district_ids[0] : null}/${district.results.length ? district.results[0].created_after : null}/${district.results.length ? district.results[0].created_before : null}/${false}/${accidentState === false ? null : true}`}>
                                        <span className='title-name'>
                                        {district.results.length ? districts[district.results[0].district_ids[0]] : null}
                                            <Icon style={{color: '#515151'}}>keyboard_arrow_right</Icon>
                                        </span>
                                    </Link>
                                </div>
                                {
                                    district.title.isFavorite ?
                                        <Icon onClick={() => removeFromFavoriteDistirct(district)}
                                              style={{color: '#DE8F00'}}>star</Icon>
                                        :
                                        <Icon onClick={() => toggleFavorite(district)}
                                              style={{color: '#D8D8D8'}}>star_border</Icon>
                                }
                            </div>
                            <Link
                                className='count general-count'
                                to={`/dashboard/main-departments/service-requests/statistics-by-district/${district.results && district.results[0].classifier_ids}/${district.results.length ? district.results[0].district_ids[0] : null}/${district.results.length ? district.results[0].created_after : null}/${district.results.length ? district.results[0].created_before : null}/${false}/${accidentState === false ? null : true}`}>
                                <span>Усього</span>
                                <span className='sum-arrow'>
                                    <span className='summary-text'>{sum}</span>
                                    <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                </span>
                            </Link>
                            {/*<div className='count'>*/}
                            {/*    <span>В тому числі аварій</span>*/}
                            {/*    <span className='summary-text'>{district.title.accidentCount}</span>*/}
                            {/*</div>*/}
                            <ul className='details-list'>
                                {
                                    district.results.length ? district.results.map((status, index) => {
                                        return (
                                            <li key={index}>
                                                <When is={status.events_count === 0}>
                                                    <div className='overlay'></div>
                                                </When>
                                                <Link
                                                    to={`/dashboard/main-departments/service-requests/category-district-details/district-category-status-classifiers/${status && status.district_ids[0]}/${status.id}/${status.classifier_ids}/${status.created_after}/${status.created_before}/${status.is_accident === null ? null : true}`}
                                                    className='list-item'>
                                                    {status.name} <span
                                                    style={{color: status.id === 11 ? '#138481' : '#FD0101'}}> {status.events_count}</span>
                                                    <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                                </Link>
                                                {/*<Link*/}
                                                {/*    to={`/dashboard/main-departments/service-requests/category-district-details/district-category-status/${district.results.length ? district.results[0].district_ids[0] : null}/${status.id}/${status.created_after}/${status.created_before}`}*/}
                                                {/*    className='list-item'>*/}
                                                {/*    {status.name} <span*/}
                                                {/*    style={{color: colors[status.id]}}> {status.events_count}</span>*/}
                                                {/*    <Icon className='arrow-right'>keyboard_arrow_right</Icon>*/}
                                                {/*</Link>*/}

                                                {/*<Link*/}
                                                {/*    to={classifiers.length ? `/dashboard/main-departments/service-requests/district-statistics-classifier/${district.results.length ? district.results[0].district_ids[0] : null}/classifiers/${classifiers}/status/${status.id}/startDate/${status.created_after}/endDate/${status.created_before}` : `/dashboard/main-departments/service-requests/district-statistics/${district.results.length ? district.results[0].district_ids[0] : null}/status/${status.id}/startDate/${status.created_after}/endDate/${status.created_before}`}*/}
                                                {/*    className='list-item'>*/}
                                                {/*    {status.name} <span*/}
                                                {/*    style={{color: colors[status.id]}}> {status.events_count}</span>*/}
                                                {/*    <Icon className='arrow-right'>keyboard_arrow_right</Icon>*/}
                                                {/*</Link>*/}
                                            </li>
                                        )
                                    }) : null
                                }
                            </ul>
                        </div>
                    </div>
                </When>
            )
        }) : null
    )
};

export default DistrictsTab;
