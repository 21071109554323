import React, {useState, useContext, useRef, useEffect} from "react";
import "./Picker.scss";
import {
    DatePicker,
} from '@material-ui/pickers';
import { MuiPickersContext } from '@material-ui/pickers'
import format from "date-fns/format";
import {
    startOfMonth,
    endOfMonth,
    startOfDay,
    endOfDay,isAfter} from "date-fns";
import { createStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    updateEndDate,
    updateStartDate
} from '../../store/actions/actionCreators'
import {updateUserSettings} from "../../store/actions/auth";

const Picker = ({value, autoOk,onChange, props, setShow}) => {
    const dispatch = useDispatch();
    const {
        classifiers
    } = useSelector(state => {
        return state.auth.user.settings;
    });
    const [begin, setBegin] = useState(startOfMonth(new Date()))
    const [end, setEnd] = useState(endOfMonth(new Date()))
    const [hover, setHover] = useState(null)
    const [twoDataSelected, setTwoDateSelected] = useState(false)
    const picker = useRef()
    const utils = useContext(MuiPickersContext)

    const min = Math.min(begin, end || hover)
    const max = Math.max(begin, end || hover)

    function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
        const style = {
            margin: 0,
            width: '40px'
        }

        if (day >= min && day <= max) {
            style.backgroundColor = 'rgba(67,89,245,0.2)'
            style.color = 'white'
        }

        if (utils.isSameDay(day, min)) {
            style.borderRadius = '5px 0 0 5px';
            style.backgroundColor = '#4359f5';
        }
        else if (utils.isSameDay(day, max)) {
            style.borderRadius = '0 5px 5px 0';
            style.backgroundColor = '#4359f5';
        }
        else {
            style.borderRadius = '0'
        }

        return React.cloneElement(dayComponent, {
            onClick: e => {
                e.stopPropagation()
                if (!begin) {
                    setBegin(day)
                    setTwoDateSelected(false)
                }
                else if (!end) {
                    setEnd(day)
                    setTwoDateSelected(true)
                    if (autoOk) {
                        onChange([begin, end].sort())
                        picker.current.close()
                    }
                } else {
                    setBegin(day)
                    setEnd(null)
                    setTwoDateSelected(false)

                }
            },
            onMouseEnter: e => setHover(day),
            style
        })
    }

    useEffect(() => {
        if(twoDataSelected ){
            if(isAfter(begin, end)){
                dispatch(updateStartDate(format(startOfDay(end), `yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(updateEndDate(format(endOfDay(begin), `yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'datePeriod',
                        startDate: format(startOfDay(new Date(end)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfDay(new Date(begin)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                setShow(false)
            }else{
                dispatch(updateStartDate(format(startOfDay(begin), `yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(updateEndDate(format(endOfDay(end), `yyyy-MM-dd'T'HH:mm:ss`)))
                dispatch(updateUserSettings({
                    classifiers: classifiers,
                    timePeriod: {
                        period: 'datePeriod',
                        startDate: format(startOfDay(new Date(begin)),`yyyy-MM-dd'T'HH:mm:ss`),
                        endDate: format(endOfDay(new Date(end)),`yyyy-MM-dd'T'HH:mm:ss`)
                    }
                }));
                setShow(false)
            }
        }
    }, [twoDataSelected]);




    const formatDate = date => utils.format(date, format || utils.dateFormat)

    return (

        <div className='picker-wrapper'>
            {
                value !== 'datePeriod' ? <div className='overlay'></div> : null
            }
            <DatePicker
                {...props}
                value={null}
                renderDay={renderDay}
                variant={"static"}
                disableToolbar={true}
                autoOk={true}
                // labelFunc={formatWeekSelectLabel}
            />
        </div>
        )
};
const styles = createStyles(theme => ({
    presentation:{
        margin: 1
    },
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: '#C4CDD5',
    },
    highlightNonCurrentMonthDay: {
        color: "#676767",
    },
    highlight: {
        background: theme.palette.primary.main,
        color: '#212B35',
        backgroundColor: 'rgba(67,89,245,0.2)',
        marginBottom: 1
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        backgroundColor: '#4359f5',
        color: '#ffffff'

    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        backgroundColor: '#4359f5',
        color: '#ffffff'
    },
}));
export default withStyles(styles)(Picker);






