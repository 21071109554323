import axios from '../App.Axios';
import {AppConstants} from "../App.Constants";
import * as actionTypes from "../store/actions/actionTypes";

export class MayorProgramApiService {
    static getReceptionTypes(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `mayor_service/reception_types/?noPage=true`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getReception(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `mayor_service/receptions/${data}` )
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getReceptionStatistic(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `mayor_service/reception_statistics/${data}` )
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
}

