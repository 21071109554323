import React, {useEffect} from "react";
import { Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import './Dashboard.scss';
import {useDispatch, useSelector} from "react-redux";
import routes from "../../textConstants/routes";
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import { getFavorite } from "../../store/actions/favorites";
import { getClassifiers } from '../../store/actions/filters'
import {getUser, updateUserSettings} from "../../store/actions/auth";
import {getReceptionTypes} from "../../store/actions/mayorProgram";
import {endOfMonth, startOfMonth} from "date-fns";
import format from "date-fns/format";

const Dashboard = () => {
    const dispatch = useDispatch();
    const showFilters = useSelector(state => {
        return state.filters.show;
    });

    useEffect((timePeriod) => {
        dispatch(getClassifiers());
        dispatch(getUser());
        dispatch(getFavorite())
        dispatch(getReceptionTypes())
        dispatch(updateUserSettings({
            classifiers: [],
            timePeriod: {
                period: 'month',
                startDate: format(startOfMonth(new Date()),`yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfMonth(new Date()),`yyyy-MM-dd'T'HH:mm:ss`)
            }
        }));
    }, []);

    return (
        <div className="Dashboard">
            <div className={'content ' + (showFilters ? 'fixed' : '')}>
                    <Switch>
                        {routes.map(({ path, name, Component }, key) => (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={props => {
                                    const crumbs = routes
                                        .filter(({ path }) => props.match.path.includes(path))
                                        .map(({ path, ...rest }) => ({
                                            path: Object.keys(props.match.params).length
                                                ? Object.keys(props.match.params).reduce(
                                                    (path, param) => path.replace(
                                                        `:${param}`, props.match.params[param]
                                                    ), path
                                                )
                                                : path,
                                            ...rest
                                        }));
                                    return (
                                        <div className="p-8">
                                            <Breadcrumbs crumbs={crumbs} />
                                            <Component {...props} />
                                        </div>
                                    );
                                }}
                            />
                        ))}
                    </Switch>
            </div>
        </div>
    )
};

export default Dashboard;
