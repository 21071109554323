import React from "react";
import "./Search.scss";

const Search = props => {
    return (
        <div className="search-wrapper">
            <input placeholder='Пошук за назвою' className="search" onChange={props.onChange}/>
        </div>
    )
};

export default Search;
