import React, {useState} from "react";
import RequestsSummaryStatusLine from "../../components/requestsSummaryStatusLine/RequestsSummaryStatusLine";
import {Link} from "react-router-dom";
import When from "../../components/When";
import Icon from "@material-ui/core/Icon";
import {removeFavoriteGeneralStatisticsFavoriteCard} from "../../store/actions/favorites";
import {useDispatch, useSelector} from "react-redux";
import {getFiltersTitles} from "../../store/selectors/filters";
import {format} from "date-fns";
import {setAccidentState, updateEndDate, updateStartDate} from "../../store/actions/actionCreators";

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}
const districts = {
    1: 'Немишлянський',
    2: 'Індустріальний',
    3: 'Шевченківський',
    4: 'Київський',
    5: 'Московський',
    6: 'Холодногірський',
    7: 'Новобаварський',
    8: 'Основ\'янський',
    9: 'Слобідський',
}
const FavoriteGeneralStatistics = ({data}) => {
    const dispatch = useDispatch();
    const filtersTitles = useSelector(getFiltersTitles)
    const [showAll, setShowAll] = useState({state: false, value: 3});

    const selectedClassifiers = (items) => {
        const classifiersData = []
        filtersTitles.forEach((item) => {
            items.results[0].classifier_ids && items.results[0].classifier_ids.forEach((classif) => {
                if (item.id === classif) {
                    classifiersData.push(item)
                }
            })
        })
        if (classifiersData.length) {
            return (
                <div className='selected-classifier'>
                    {
                        classifiersData && classifiersData.map((item) => {
                            return (
                                <span className='item'>{item.title}</span>
                            )
                        }).slice(0, !showAll.state ? 0 : classifiersData.length)
                    }
                    <When is={classifiersData && classifiersData.length >= 1}>
                        <span
                            style={{backgroundColor: '#DE8F00'}}
                            onClick={() => {
                                setShowAll({state: !showAll.state, value: !showAll.showAll ? 4 : classifiersData.length})
                            }}
                            className='item show-more'>
                            {
                                !showAll.state ? (`Показати обранi категорії (${classifiersData && classifiersData.length})`) : 'Сховати'
                            }
                        </span>
                    </When>
                </div>
            )
        } else {
            return null
        }
    }
    const removeFromGeneralStats = (statistics) =>{
        dispatch(removeFavoriteGeneralStatisticsFavoriteCard(statistics.results))
    }
    return (
        <div>
            {
                data && data.map((statistics, index) => {
                    const sum = statistics.results.length ? statistics.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : '--';
                    return (
                        <div key={index} className="requests-summary">
                            <div className="card-details">
                                <div className="card-date">
                                    <span>На основі даних від {format(new Date(statistics.results[0].created_after), `dd-MM-yyyy`)} до {format(new Date(statistics.results[0].created_before), `dd-MM-yyyy`)}</span>
                                </div>
                                <div className='card-header'>
                                    <div className='card-title'>
                                        <span className='title-name'>
                                           Загальна кiлькiсть звернень
                                            {
                                                statistics.results[0].district_ids && statistics.results[0].district_ids.length === 1 ? ' (' + districts[statistics.results[0].district_ids] + ' район)' : ''
                                            }
                                        </span>
                                    </div>
                                    <Icon onClick={() => removeFromGeneralStats(statistics)} style={{color: '#DE8F00'}}>star</Icon>
                                </div>
                                <When is={statistics.results[0].is_accident !== null}>
                                    <div className='accident-title'>Аварiйна</div>
                                </When>
                                <div className="card-status-line">
                                    <RequestsSummaryStatusLine
                                        summary={sum}
                                        data={statistics.results}/>
                                </div>
                                {selectedClassifiers(statistics)}
                                <div className='count general-count'>
                                    <span>Усього</span>
                                    <span className='summary-text'>{sum}</span>
                                </div>
                                {/*<div className='count'>*/}
                                {/*    <span>В тому числі аварій</span>*/}
                                {/*    <span className='summary-text'>{accidentCount.count}</span>*/}
                                {/*</div>*/}
                                <ul className='details-list'>
                                    {
                                        statistics.results.map((status) => {
                                            return (
                                                <li key={status.id}>
                                                    <When is={status.events_count === 0}>
                                                        <div className='overlay'></div>
                                                    </When>

                                                    <Link
                                                        onClick={() => {
                                                            dispatch(setAccidentState(status.is_accident ? true : false))
                                                            dispatch(updateStartDate(new Date(statistics.results[0].created_after).toISOString()));
                                                            dispatch(updateEndDate(new Date(statistics.results[0].created_before).toISOString()));
                                                        }}
                                                        // to={status.classifier_ids && status.classifier_ids.length ? `/dashboard/main-departments/service-requests/category-district-details/district-category-status-classifiers/${status.district_ids}/${status.id}/${status.classifier_ids}/${status.created_after}/${status.created_before}/` :`/dashboard/main-departments/service-requests/category-district-details/district-category-status/${status.district_ids}/${status.id}/${status.created_after}/${status.created_before}`}
                                                        to={status.classifier_ids && status.classifier_ids.length ?  `/dashboard/main-departments/service-requests/sub-category/${status.classifier_ids}/${status.created_after}/${status.created_before}/${status.is_accident}/general-districts/${status.district_ids}/${false}/${status.id}/` :`/dashboard/main-departments/service-requests/category-district-details/district-category-status/${status.district_ids}/${status.id}/${status.created_after}/${status.created_before}/${status.is_accident}`}

                                                        className='list-item'>
                                                        {status.name}
                                                        <span
                                                            style={{color: status.id === 11 ? '#138481' : '#FD0101'}}>{status.events_count}</span>
                                                        <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

export default FavoriteGeneralStatistics;
