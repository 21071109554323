import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as authActions from '../../store/actions/auth';
import './login.scss'
import Input from "../../components/inputs/Input";
import Error from "../../components/errors/Error";
import When from "../../components/When";
import { updateObject, checkValidity } from '../../shared/utility';
import {Link} from "react-router-dom";

const Login = () => {
    const dispatch = useDispatch();

    const [authForm, setAuthForm] = useState({
        email: {
            elementConfig: {
                type: 'email',
                label: 'Email'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            errorMessage: 'Email-адреса введена неправильно',
            valid: true,
            touched: false
        },
        password: {
            elementConfig: {
                type: 'password',
                label: 'Пароль'
            },
            value: '',
            validation: {
                required: true,
                minLength: 8
            },
            errorMessage: 'Пароль повинен бути не меньше 8 символів',
            valid: true,
            touched: false
        }
    });

    const inputChangedHandler = (event, controlName) => {
        const updatedControls = updateObject(authForm, {
            [controlName]: updateObject(authForm[controlName], {
                value: event.target.value,
                // valid: checkValidity(
                //     event.target.value,
                //     authForm[controlName].validation
                // )
            })
        });
        setAuthForm(updatedControls);
    };

    const inputBlurHandler = (controlName) => {
        const updatedControls = updateObject(authForm, {
            [controlName]: updateObject(authForm[controlName], {
                touched: false
            })
        });
        setAuthForm(updatedControls);
    };

    const touchAllControls = () => {
        let updatedControls = updateObject(authForm, {});
        for(let control of formElementsArray) {
            updatedControls[control.id] = updateObject(authForm[control.id], {touched: true});
        }
        setAuthForm(updatedControls);
    };

    const formElementsArray = [];
    for (let key in authForm) {
        formElementsArray.push({
            id: key,
            config: authForm[key]
        });
    }

    let form = formElementsArray.map(formElement => (
        <div autoComplete="off" key={formElement.id} className='input-container'>
            <Input
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                // invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                errorMessage={formElement.config.errorMessage}
                onChange={event => inputChangedHandler(event, formElement.id)}
                onBlur={() => inputBlurHandler(formElement.id)}
            />
        </div>
    ));

    const error = useSelector(state => {
        return state.auth.error;
    });

    const login = () => {
        touchAllControls();
        if (authForm.email.valid && authForm.password.valid) {
            dispatch(authActions.auth(authForm.email.value, authForm.password.value));
        }
    };

    return (
        <div className='login-wrapper'>
            <div className="login-container">
                <div className='logo'></div>
                <h1>Вхiд до системи</h1>
                <div className='form-wrapper'>
                    {form}
                </div>
                <div className="btn-container">
                    <Link onClick={login} className="btn-primary">Увiйти</Link>
                </div>
                <When is={error}>
                    <Error error={error}/>
                </When>
                {/*<div className="register-block">*/}
                {/*    <span>Не маєте облікового запису?</span>*/}
                {/*    <button className="btn-link">Зареєструватися</button>*/}
                {/*</div>*/}
            </div>
        </div>
    )
};

export default Login;
