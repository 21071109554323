import React, {useState} from "react";
import "./ProductList.scss";
import Icon from "@material-ui/core/Icon";
import When from '../../components/When';
import {Link, useParams} from "react-router-dom";
import { setProductStatistic } from '../../store/actions/actionCreators'
import {useDispatch} from "react-redux";
const ProductList = ({products, title, addToFavorite, favorite, removeFromFavorites, showFlash}) => {
    const params = useParams()
    const dispatch = useDispatch();
    const [sortBy, setSortBy] = useState(null);
    const [showPopUp, setShowPopUp] = useState(false);

    const checkPrice = (product) => {
        if (product.growth_percent === '0.00' || product.growth_percent === null) {
            return;
        } else if (product.growth_percent !== null && product.growth_percent.indexOf('-') !== -1) {
            return 'price-down'
        } else {
            return 'price-up'
        }
    }

    const checkArrow = (product) => {
        if (product.growth_percent === '0.00' || product.growth_percent === null) {
            return <span>--</span>;
        } else if (product.growth_percent !== null && product.growth_percent.indexOf('-') !== -1) {
            return <Icon>arrow_downward</Icon>
        } else {
            return <Icon>arrow_upward</Icon>
        }
    }

    const getSortedProductList = () => {
        const copiedProducts = [...products]

        if (sortBy === 'hight') {
            return copiedProducts.sort((a, b) => a.growth_percent - b.growth_percent)
        }

        if (sortBy === 'low') {
            return copiedProducts.sort((a, b) => b.growth_percent - a.growth_percent)
        }

        if (sortBy === 'alphabet') {
            return copiedProducts.sort((a, b) => a.title < b.title)
        }
        return copiedProducts;
    }

    const sortedProducts = getSortedProductList();

    const openProductStatistics = (item) => {
        dispatch(setProductStatistic(item))
        // setProductStatistics(item)
        // toggleProductStatistics(!showProductStatistics)
    }

    return (
        <div className='list-wrapper'>
            <When is={sortedProducts.length}>
                <div className='product-list-wrapper'>
                    <When is={showFlash}>
                        <div className='success-alert'>
                            Картка добавлена в улюблене
                        </div>
                    </When>
                    <div className='list-header'>
                        <h2>
                            {sortedProducts.length ? sortedProducts[0].type.name : title}
                        </h2>
                        <ul className='nav-list'>
                            <When is={sortedProducts.length}>
                                <li>
                                    <Icon onClick={() => setShowPopUp(!showPopUp)} className='sort-icon'>sort</Icon>
                                    <When is={showPopUp}>
                                        <div className='sort-popup'>
                                            <h5>Сортувати за</h5>
                                            <ul>
                                                <li onClick={() => {
                                                    setSortBy('low');
                                                    setShowPopUp(false)
                                                }}>Ростом цін
                                                </li>
                                                <li onClick={() => {
                                                    setSortBy('hight');
                                                    setShowPopUp(false)
                                                }}>Зниженням цін
                                                </li>
                                                <li onClick={() => {
                                                    setSortBy('alphabet');
                                                    setShowPopUp(false)
                                                }}>Абеткою
                                                </li>
                                            </ul>
                                        </div>
                                    </When>
                                </li>
                            </When>
                            <When is={!favorite}>
                                <li>
                                    {
                                        products.length && products[0].info && products[0].info.isFavorite ?
                                            // onClick={() => removeFromFavoriteClassifier(statistic)}
                                            <Icon
                                                onClick={() => removeFromFavorites(products)}
                                                style={{color: '#DE8F00'}}>star</Icon>
                                            :
                                            sortedProducts.length ? <Icon onClick={() => addToFavorite(products)}
                                                                          style={{color: '#D8D8D8'}}>star_border</Icon> : null

                                    }
                                </li>
                            </When>
                            <When is={favorite}>
                                <Icon
                                    onClick={() => removeFromFavorites(products)}
                                    style={{color: '#DE8F00', marginLeft: 10}}>star</Icon>
                            </When>
                        </ul>
                    </div>
                    <ul className='product-list'>
                        {
                            sortedProducts.length ? sortedProducts.map(product => {
                                return (
                                    <li onClick={() => openProductStatistics(product)} key={product.id}
                                        className='list-item'>
                                        <Link className='list-item-link'  to={`/dashboard/main-departments/economics-service/product-list/${product.id}/details`}>
                                            <div className='name'> {product.title}</div>
                                            <div className={`prices ${checkPrice(product)}`}>
                                                <span>{product.latest_price.price} грн</span>
                                                <span style={{padding: 5}}> / </span>
                                                <span>{product.growth_percent && product.growth_percent.indexOf('-') !== -1 ? Math.round(product.growth_percent.substr(1)) + '%' : (product.growth_percent === null ? '0%' : Math.round(product.growth_percent) + '%')}</span>
                                                <span className='arrow-block'>
                                                {checkArrow(product)}
                                            </span>
                                                <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            }) : <div className='no-data'>Немає даних</div>
                        }
                    </ul>
                    {/*<button className='show-more-btn'>Показати інші показники</button>*/}
                </div>
            </When>
        </div>
    )
};

export default ProductList;
