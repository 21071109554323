import {createSelector} from '@reduxjs/toolkit'

export const getFiltersTitles = (state) =>{
    const classifiers = state.filters.classifiers;
    const selectedFilters = [];
    classifiers.forEach((classifier)=>{
        selectedFilters.push(classifier)
        classifier.children && classifier.children.forEach((classifierChild)=>{
            selectedFilters.push(classifierChild)
            classifierChild.children && classifierChild.children.forEach((childOfChild)=>{
                selectedFilters.push(childOfChild)
            })
        })
    });
    return selectedFilters
}
const getClassifiers = (state) => state.filters.classifiers;

export const getFiltersIds = createSelector(getClassifiers, (classifiers) =>{
    const selectedFiltersIds = [];
    const parentsFiltersIds = [];
    const topLevelCategoryIds = [];

    classifiers.forEach((classifier)=>{
        topLevelCategoryIds.push(classifier.id)
        if(classifier.children && classifier.children.length){
            parentsFiltersIds.push(classifier.id)
        }
        selectedFiltersIds.push(classifier.id)
        classifier.children && classifier.children.forEach((classifierChild)=>{
            if(classifierChild.children && classifierChild.children.length){
                parentsFiltersIds.push(classifierChild.id)
            }
            selectedFiltersIds.push(classifierChild.id)
            classifierChild.children && classifierChild.children.forEach((childOfChild)=>{
                if(childOfChild.children && childOfChild.children.length){
                    parentsFiltersIds.push(childOfChild.id)
                }
                selectedFiltersIds.push(childOfChild.id)
            })
        })
    });

    // console.log('hightCategoryIds',topLevelCategoryIds)
    const resultArraysId = selectedFiltersIds.filter(id => !parentsFiltersIds.includes(id));
    // console.log('selectedFiltersIds',selectedFiltersIds.length)
    // console.log('parentsFiltersIds',parentsFiltersIds.length)
    // console.log('resultArraysId',resultArraysId.length)
    const ids = {
        resultArraysId: resultArraysId,
        topLevelCategoryIds: topLevelCategoryIds
    }
    return ids
})

const getUserSettingClassifiers = (state) => state.auth.user.settings.classifiers;
export const getParentCategories = createSelector([getClassifiers, getUserSettingClassifiers], (classifiers, userSelectedClassifiers) =>{
    const parentIds = classifiers.map(items => {
        const ids = {
            title: '',
            idsArray: []
        };
        ids.title = items.title

        if(!items.children){
            ids.idsArray.push(items.id)
        }
        items.children && items.children.map(child => {
            if(!child.children){
                ids.idsArray.push(child.id)
            }
            child.children && child.children.map(childOfChild => ids.idsArray.push(childOfChild.id))
        })
        return ids
    })
    const newParentsIds = []
    parentIds.forEach(parentId => {
        const item = {
            title: parentId.title,
            idsArray: []
        }
        parentId.idsArray.forEach(id => {
            userSelectedClassifiers.forEach(userItem => {
                if(id === userItem){
                    item.idsArray.push(id)
                }
            })
        })
        if(item.idsArray.length){
            newParentsIds.push(item)
        }
    })
    return userSelectedClassifiers.length ? newParentsIds : parentIds
})

