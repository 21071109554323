import React, {useEffect} from "react";
import './DistrictCategoryStatus.scss'
import {Link, useParams} from "react-router-dom";

import Header from "../../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {getStatisticByStatusAndCategory} from '../../../store/actions/stats'
import {getParentCategories, getFiltersIds} from "../../../store/selectors/filters";
import {getCategoryByStatus} from "../../../store/selectors/stats";
import Icon from "@material-ui/core/Icon";
import When from "../../../components/When";


const DistrictCategoryStatus = () => {
    const dispatch = useDispatch();
    const parentIds = useSelector(getParentCategories)
    const ids = useSelector(getFiltersIds)
    const statisticByStatus = useSelector(getCategoryByStatus)
    const params = useParams()
    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });
    const {
        user: {
            settings: {
                classifiers,
            }
        }
    } = useSelector(state => {
        return state.auth;
    });
    const {
        accidentState
    } = useSelector(state => {
        return state.stats;
    });
    useEffect(()=>{
        dispatch(getStatisticByStatusAndCategory(params, params.classifiers && params.classifiers ? JSON.parse("[" + params.classifiers + "]") :  classifiers && classifiers.length ? classifiers : ids.resultArraysId, params && params.isAccident ? params.isAccident : accidentState === false ? null : true, params.startDate, params.endDate ))
    }, [])
    return (
        <div id='scrolled-cont' className='page-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Звернення до служби 1562'}/>
            <div className={`service-page`}>
                <div className="container">
                {
                    statisticByStatus && statisticByStatus.length && statisticByStatus.slice().sort((a,b) => b.count - a.count).map(item => {
                        return (
                            <When is={item.count !== 0}>
                                <div className='category-status-line'>
                                    <Link to={`/dashboard/main-departments/service-requests/events/${params && params.id ? params.id : null}/${params.district_id}/${item.classifier_ids || item.classifiersId}/${params.startDate}/${params.endDate}/${params.isAccident}`}>
                                        <span className='title'>{item && item.title && item.title.length ? item.title[0].title : ''}</span>
                                        <span>
                                        {item.count}
                                            <Icon style={{color: '#4359f5'}}>keyboard_arrow_right</Icon>
                                    </span>
                                    </Link>
                                </div>
                            </When>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
};

export default DistrictCategoryStatus;
