import React, {useEffect} from "react";
import './Products.scss'
import './../MayorProgram.scss'
import {useParams, Link} from 'react-router-dom'
import Header from "../../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import Icon from "@material-ui/core/Icon";
import {getReceptionById, getReceptionStatistic, getReceptionTypes} from '../../../store/actions/mayorProgram'
import format from "date-fns/format";
import When from '../../../components/When'
const ProgramDetails = () => {
    const params = useParams()
    const {
        reseptions, receptionStatistic
    } = useSelector(state => {
        return state.mayorProgram;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getReceptionStatistic(params.programType))
        dispatch(getReceptionById(params.programType))

    }, [params.id])
    return (
        <div className='page-wrapper mayor-program'>
            <Header
                showFilters={false}
                headerTitle={'Звернення до міського голови - деталi'}/>
            <div className="container">
                <div className='product-statistic-list'>
                    <div className="product-list-wrapper">
                        <h3>Загальна статистика</h3>
                        <ul className='statistic-list'>
                            {
                                receptionStatistic && receptionStatistic.map(statistic => {
                                    return (
                                        <li>
                                            <span>{statistic.status_name ? statistic.status_name : '--'}</span>
                                            <span className='sum'>{statistic.sum}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <ul className='product-list'>
                            {
                                reseptions.map(reseption => {
                                    return (
                                        <li className='list-item'>
                                            <div className='name'>
                                                {reseption.name} {reseption && reseption.date ? `за ${format(new Date(reseption.date), 'dd.MM.yyyy')}` : null}
                                            </div>
                                            <div className='statuses'>
                                                <ul>
                                                    {
                                                        reseption.counters.map(item =>{
                                                            return (
                                                                <li><span>{item.status && item.status.name ? item.status.name : '---'}</span><span>{item && item.quantity ? item.quantity : '--'}</span></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            {/*<div className="prices"><span>{productDetails && productDetails.latest_price.price} грн</span></div>*/}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProgramDetails;
