import React, {useEffect} from "react";
import './../ServiceRequests.scss'
import {useParams} from "react-router-dom";
import TimeFilter from "../../../components/timeFilter/TimeFilter";
import {useDispatch, useSelector} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Footer from '../../../components/Footer/Footer'
import {footerNavServiceRequest} from '../../../textConstants/textConstants'
import {
    getDistrictStatistics,
    getSummaryStatisticsByClassifiers,
} from '../../../store/actions/stats';

import {
    getStatistics, getStatisticsByDistrict
} from '../../../store/selectors/stats'
import {
    setTabsView,
} from "../../../store/actions/actionCreators";
import Header from "../../../components/Header/Header";
import ByCategory from './byCategory';
import ByDistrict from './byDistrict';
import {getParentCategories} from "../../../store/selectors/filters";
import Spiner from "../../../assets/images/logo-spinner.gif";
import When from "../../../components/When";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const TopCategoryDetails = () => {
    const params = useParams()
    const statisticsByCat = useSelector(getStatistics)
    const statisticsByDistrict = useSelector(getStatisticsByDistrict)

    const {
        tabValue
    } = useSelector(state => {
        return state.stats;
    });

    const {
        startDate, endDate
    } = useSelector(state => {
        return state.auth.datePeriod;
    });

    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });

    const dispatch = useDispatch();

    const [value, setValue] = React.useState(tabValue);
    useEffect(() => {
        dispatch(getSummaryStatisticsByClassifiers(params && params.district_id && params.district_id ? params.district_id : [1, 2, 3, 4, 5, 6, 7, 8, 9], params.classifiers, startDate ? startDate : params.startDate, endDate ? endDate : params.endDate, params.isAccident))
        dispatch(getDistrictStatistics([1, 2, 3, 4, 5, 6, 7, 8, 9], params.startDate, params.endDate, params.classifiers, params.isAccident));
    }, [settings]);
    const handleChange = (event, newValue) => {
        dispatch(setTabsView(newValue))
        setValue(newValue);
    };
    return (
        <div id='scrolled-cont' className='page-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Звернення до служби 1562'}/>
            <div className={`service-page`}>
                <div className="container">
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Категорії"/>
                            <Tab label="Райони"/>
                        </Tabs>
                    </AppBar>
                    <TimeFilter/>
                </div>
                <div className="container">
                    <div className="line"></div>
                </div>
                <div className="container">
                    <TabPanel value={value} index={0}>
                        <ByCategory
                            statisticsByCat={statisticsByCat}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ByDistrict statisticsByDistrict={statisticsByDistrict}/>
                    </TabPanel>
                </div>
            </div>
            <Footer footerNav={footerNavServiceRequest}/>
            <When is={!statisticsByCat.length}>
                <div className="loading-spinner-overlay">
                    <img src={Spiner} alt="Spiner"/>
                </div>
            </When>
        </div>
    )
};

export default TopCategoryDetails;
