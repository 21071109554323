import {FavoriteApiService} from "../../api/favorites.api";
import {
    addToFavoriteSuccess,
    getFavoriteSuccess,
    setFavorites,
    removeFavoriteSuccess,
    setFavoritesDataClassifier,
    setFavoritesDataDistrict,
    setFavoritesDataEvents,
    setFavoritesDataProducts, httpLoadingEnd, httpLoadingStart,
    setFavoritesGeneralStatistic,
    setFavoritesGeneralStatisticDistrict,
    setFavoritesDataClassifierTop
} from './actionCreators'
import {StatisticsApiService} from "../../api/stats.api";
import {EventsApiService} from "../../api/events.api";
import {EconomicsApiService} from "../../api/economics";
import {
    endOfDay,
    endOfMonth,
    endOfWeek, endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear
} from "date-fns";
import {logout} from "./auth";

const date = new Date()

const selectDatePeriod = (period) => {
    switch (period.period) {
        case 'month':
            return {
                startDate: format(startOfMonth(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfMonth(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'week':
            return {
                startDate: format(startOfWeek(date, {weekStartsOn: 1}), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfWeek(date, {weekStartsOn: 1}), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'day':
            return {
                startDate: format(startOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'year':
            return {
                startDate: format(startOfYear(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfYear(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'allTime':
            return {
                startDate: format(new Date('1970-01-01T03:00:00'), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'datePeriod':
            return {
                startDate: period.startDate,
                endDate: period.endDate
            }
        default:
            return {
                startDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`)
            }
    }
}

export const addToFavorite = (data) => {
    return (dispatch, getState) => {
        dispatch(httpLoadingStart());
        FavoriteApiService.addToFavorite(data)
            .then(response => {
                dispatch(httpLoadingEnd());
                dispatch(addToFavoriteSuccess(response));
            })
            .catch(err => {
                dispatch(httpLoadingEnd());
            });
    };
};

export const removeFavoriteClassifier = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        const favorite = state.favorites.favoritesList.results.find(favoriteItem => {
            const dataStartDate = data && data.results && data.results[0].created_after
            const dataEndDate = data && data.results && data.results[0].created_before
            const dates = selectDatePeriod(favoriteItem.data.timePeriod)
            const endDate = favoriteItem.data.timePeriod ? dates.endDate : null
            const startDate = favoriteItem.data.timePeriod ? dates.startDate : null
            return favoriteItem.data.classifierIds && favoriteItem.data.classifierIds.toString() === data.results[0].classifier_ids.toString() && dataStartDate === dates.startDate && dataEndDate === dates.endDate && data.results[0].is_accident === favoriteItem.data.isAccident
        })
        FavoriteApiService.removeFavorite(favorite.id)
            .then(response => {
                dispatch(removeFavoriteSuccess(favorite.id));
                dispatch(httpLoadingEnd());
            })
            .catch(err => {
                dispatch(httpLoadingEnd());
            });
    };
};
export const removeFavoriteTopLevelClassifier = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        const favorite = state.favorites.favoritesList.results.find(favoriteItem => {
            const dataStartDate = data.statistics[0].created_after
            const dataEndDate = data.statistics[0].created_before
            const dates = selectDatePeriod(favoriteItem.data.timePeriod)
            return favoriteItem.data.classifierIds && favoriteItem.data.classifierIds.length ? favoriteItem.data.classifierIds.toString() : favoriteItem.data.classifiers.toString() === data.title.idsArray.toString() && dataStartDate === dates.startDate && dataEndDate === dates.endDate && data.statistics[0].is_accident === favoriteItem.data.isAccident
        })
        FavoriteApiService.removeFavorite(favorite.id)
            .then(response => {
                dispatch(removeFavoriteSuccess(favorite.id));
                dispatch(httpLoadingEnd());
            })
            .catch(err => {
                dispatch(httpLoadingEnd());
            });
    };
};
export const removeFavoriteDistict = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        let favorite = {}
        if (data.results[0].classifier_ids !== null) {
            favorite = state.favorites.favoritesList.results.find(favoriteItem => {
                const dataStartDate = data.results[0].created_after
                const dataEndDate = data.results[0].created_before
                const dates = selectDatePeriod(favoriteItem.data.timePeriod)
                const endDate = favoriteItem.data.timePeriod ? dates.endDate : null
                const startDate = favoriteItem.data.timePeriod ? dates.startDate : null
                return favoriteItem.data.districtIds === data.title.id && favoriteItem.data.classifiers.toString() === data.results[0].classifier_ids.toString() && data.results[0].is_accident === favoriteItem.data.isAccident && dataEndDate === dates.endDate && dataStartDate === dates.startDate
            })
        } else {
            favorite = state.favorites.favoritesList.results.find(favoriteItem => {
                const dataStartDate = data.results[0].created_after
                const dataEndDate = data.results[0].created_before
                const dates = selectDatePeriod(favoriteItem.data.timePeriod)
                const endDate = favoriteItem.data.timePeriod ? dates.endDate : null
                const startDate = favoriteItem.data.timePeriod ? dates.startDate : null
                if (favoriteItem.data.classifiers && !favoriteItem.data.classifiers.length) {
                    return favoriteItem.data.districtIds === data.title.id && data.results[0].is_accident === favoriteItem.data.isAccident && dataEndDate === dates.endDate && dataStartDate === dates.startDate
                }

            })
        }
        FavoriteApiService.removeFavorite(favorite.id)
            .then(response => {
                dispatch(removeFavoriteSuccess(favorite.id));
                dispatch(httpLoadingEnd());
            })
            .catch(err => {
                dispatch(httpLoadingEnd());
            });
    };
};
export const removeFavoriteEvents = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        const favorite = state.favorites.favoritesList.results.find(favoriteItem => {
            if(favoriteItem.data.type === 'event-details'){
                return favoriteItem.data.eventId === data.event_id
            }

        })
        FavoriteApiService.removeFavorite(favorite.id)
            .then(response => {
                dispatch(removeFavoriteSuccess(favorite.id));
                dispatch(httpLoadingEnd());
            })
            .catch(err => {
                dispatch(httpLoadingEnd());
            });
    };
};
export const removeFavoriteProducts = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        const favorite = state.favorites.favoritesList.results.find(favoriteItem => favoriteItem.data.productId === data[0].type.id)
        FavoriteApiService.removeFavorite(favorite.id).then(response => {
            dispatch(removeFavoriteSuccess(favorite.id));
            dispatch(httpLoadingEnd());
        })
            .catch(err => {
                dispatch(httpLoadingEnd());
            });
    };
};
export const removeFavoriteGeneralStatistics = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        const favorite = state.favorites.favoritesList.results.find(favoriteItem => {
            const dataStartDate = data[0].created_after
            const dataEndDate = data[0].created_before
            const dates = selectDatePeriod(favoriteItem.data.timePeriod)
            const endDate = favoriteItem.data.timePeriod ? dates.endDate : null
            const startDate = favoriteItem.data.timePeriod ? dates.startDate : null
            const dataClassifiers = data[0].classifier_ids && data[0].classifier_ids.length ? data[0].classifier_ids.toString() : null;
            const favoriteClassifiers = favoriteItem.data.classifiers && favoriteItem.data.classifiers.length ? favoriteItem.data.classifiers.toString() : null;
            const dataDistrictId = data[0].district_ids && data[0].district_ids.length ? data[0].district_ids.toString() : null
            const favoriteDistrictId = favoriteItem.data.district_id && favoriteItem.data.district_id.length ? favoriteItem.data.district_id.toString() : [1,2,3,4,5,6,7,8,9].toString()
            if(favoriteItem.data.type === 'general-statistics' || favoriteItem.data.type === 'general-statistics-district'){
                return dataClassifiers === favoriteClassifiers && data[0].is_accident === favoriteItem.data.isAccident && dataDistrictId === favoriteDistrictId && dataStartDate === dates.startDate && dates.endDate === dataEndDate
            }
        })
        FavoriteApiService.removeFavorite(favorite.id).then(response => {
            dispatch(removeFavoriteSuccess(favorite.id));
            dispatch(httpLoadingEnd());
        }).catch(err => {
            dispatch(httpLoadingEnd());
        });
    };
};
export const removeFavoriteGeneralStatisticsFavoriteCard = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        const favorite = state.favorites.favoritesList.results.find(favoriteItem => {
            const dataStartDate = data[0].created_after
            const dataEndDate = data[0].created_before
            const dates = selectDatePeriod(favoriteItem.data.timePeriod)
            const endDate = favoriteItem.data.timePeriod ? dates.endDate : null
            const startDate = favoriteItem.data.timePeriod ? dates.startDate : null
            const dataClassifiers = data[0].classifier_ids && data[0].classifier_ids.length ? data[0].classifier_ids.toString() : null;
            const favoriteClassifiers = favoriteItem.data.classifiers && favoriteItem.data.classifiers.length ? favoriteItem.data.classifiers.toString() : null;
            const dataDistrictId = data[0].district_ids && data[0].district_ids.length === 1 ? data[0].district_ids.toString() : null
            const favoriteDistrictId = favoriteItem.data.district_id && favoriteItem.data.district_id.length ? favoriteItem.data.district_id.toString() : null
            return dataClassifiers === favoriteClassifiers && data[0].is_accident === favoriteItem.data.isAccident && dataDistrictId === favoriteDistrictId && dataStartDate === dates.startDate && dates.endDate === dataEndDate
        })
        FavoriteApiService.removeFavorite(favorite.id).then(response => {
            dispatch(removeFavoriteSuccess(favorite.id));
            dispatch(httpLoadingEnd());
        }).catch(err => {
            dispatch(httpLoadingEnd());
        });
    };
};

export const getFavorite = (data) => {
    return (dispatch, getState) => {
        const state = getState()
        FavoriteApiService.getFavoriteList('noPage=true')
            .then(response => {
                dispatch(getFavoriteSuccess(response));
            })
            .catch(err => {
                if(err.response.status === 401){
                    dispatch(logout())
                }
                dispatch(httpLoadingEnd());
            });
    };
};

export const getFavoriteStatsClassifier = (data) => {
    return (dispatch, getState) => {
        dispatch(setFavorites([]));
        // format(new Date(id.datePeriod.endDate), `yyyy-MM-dd'T'HH:mm:ss`)
        const promises = data && data.map(id => StatisticsApiService.getStatistics(`classifierIds=${id.data.classifierIds}&districtIds=${[1,2,3,4,5,6,7,8,9]}&isAccident=${id.data.isAccident ? 'true' : 'null'}&createdAfter=${id.datePeriod.startDate}&createdBefore=${id.datePeriod.endDate}&noPage=true`))
        Promise.all(promises).then((responses) => {
            const array = []
            responses.forEach(res => {
                const obj = {results: res}
                array.push(obj)
            })
            dispatch(setFavoritesDataClassifier(array));
        }).catch(() => {

        })
    };
};
export const getFavoriteStatsClassifierTop = (data) => {
    return (dispatch, getState) => {
        dispatch(setFavorites([]));
        // format(new Date(id.datePeriod.endDate), `yyyy-MM-dd'T'HH:mm:ss`)
        const promises = data && data.map(id => StatisticsApiService.getStatistics(`classifierIds=${id.data.classifierIds}&districtIds=${[1,2,3,4,5,6,7,8,9]}&isAccident=${id.data.isAccident ? 'true' : 'null'}&createdAfter=${id.datePeriod.startDate}&createdBefore=${id.datePeriod.endDate}&noPage=true`))
        Promise.all(promises).then((responses) => {
            const array = []
            responses.forEach(res => {
                const obj = {results: res}
                array.push(obj)
            })
            dispatch(setFavoritesDataClassifierTop(array));
        }).catch(() => {

        })
    };
};
export const getFavoriteStatsDistrict = (data) => {
    return (dispatch, getState) => {
        dispatch(setFavorites([]));
        const promises = data && data.map(id => StatisticsApiService.getStatistics(`districtIds=${id.data.districtIds}&classifierIds=${id.data.classifiers.toString()}&isAccident=${id.data.isAccident ? 'true' : 'null'}&createdAfter=${id.datePeriod.startDate}&createdBefore=${id.datePeriod.endDate}&noPage=true`))
        Promise.all(promises).then((responses) => {
            const array = []
            responses.forEach(res => {
                const obj = {results: res}
                array.push(obj)
            })
            dispatch(setFavoritesDataDistrict(array));
            // dispatch(setFavoritesDistrict(responses));
            // dispatch(httpLoadingEnd());
        }).catch(() => {

        })
        // const promisesForEventsCount = data.map(id => StatisticsApiService.eventsCount(`isAccident=true&districtIds=${id.data.districtIds}&classifierIds=${id.data.classifiers.toString()}`))
        //     Promise.all(promisesForEventsCount).then((responses)=>{
        //         dispatch(setAccidentCountByDistrict(responses));
        //     }).catch(()=>{
        //
        //     })
    };
};
export const getFavoriteEvents = (data) => {
    return (dispatch, getState) => {
        dispatch(setFavorites([]));
        const promises = data && data.map(id => EventsApiService.getEventsById(id.data.eventId))
        Promise.all(promises).then((responses) => {
            dispatch(setFavoritesDataEvents(responses));
            // dispatch(setFavoritesDistrict(responses));
            // dispatch(httpLoadingEnd());
        }).catch((err) => {
            console.log('err',err.message)
        })
    };
};

export const getFavoriteProducts = (data) => {
    return (dispatch, getState) => {
        dispatch(setFavorites([]));
        const promises = data && data.map(id => EconomicsApiService.getProducts(id.data.productId))
        Promise.all(promises).then((responses) => {
            dispatch(setFavoritesDataProducts(responses));
            // dispatch(setFavoritesDistrict(responses));
            // dispatch(httpLoadingEnd());
        }).catch(() => {

        })
    };
};
export const getFavoriteGeneralStatistics = (data) => {
    return (dispatch, getState) => {
        dispatch(setFavorites([]));
        const promises = data && data.map(id => StatisticsApiService.getSumStatistics(`classifierIds=${id.data.classifiers}&isAccident=${id.data.isAccident ? 'true' : 'null'}&createdAfter=${id.datePeriod.startDate}&createdBefore=${id.datePeriod.endDate}&districtIds=${[1,2,3,4,5,6,7,8,9]}&noPage=true`))
        Promise.all(promises).then((responses) => {
            // dispatch(setFavoritesDataProducts(responses));
            const array = []
            responses.forEach(res => {
                const obj = {results: res}
                array.push(obj)
            })
            dispatch(setFavoritesGeneralStatistic(array));

            // dispatch(httpLoadingEnd());
        }).catch(() => {

        })
        // StatisticsApiService.eventsCount('isAccident=true')
        //     .then(response => {
        //         dispatch(setAccidentCount(response));
        //     })
        //     .catch(err => {
        //         console.log('err',err)
        //     });
    };
};
export const getFavoriteGeneralStatisticsDistricts = (data) => {
    return (dispatch, getState) => {
        dispatch(setFavorites([]));
        const promises = data && data.map(id => StatisticsApiService.getSumStatistics(`classifierIds=${id.data.classifiers}&districtIds=${id.data.district_id}&isAccident=${id.data.isAccident ? 'true' : 'null'}&createdAfter=${id.datePeriod.startDate}&createdBefore=${id.datePeriod.endDate}`))
        Promise.all(promises).then((responses) => {
            // dispatch(setFavoritesDataProducts(responses));
            const array = []
            responses.forEach(res => {
                const obj = {results: res}
                array.push(obj)
            })
            dispatch(setFavoritesGeneralStatisticDistrict(array));
            // dispatch(httpLoadingEnd());
        }).catch(() => {

        })
    };
};


