import {
    setReceptionTypes,
    setReception,
    setReceptionStatistic,
    httpLoadingStart,
    httpLoadingEnd
} from './actionCreators'
import {MayorProgramApiService} from "../../api/mayorProgram.api";

export const getReceptionTypes = () => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(httpLoadingStart());
        MayorProgramApiService.getReceptionTypes()
            .then((response) => {
                dispatch(httpLoadingEnd());
                dispatch(setReceptionTypes(response));
            })
            .catch(err => {
            });
    };
};
export const getReceptionById = (id) => {
    return (dispatch, getState) => {
        dispatch(httpLoadingStart());
        MayorProgramApiService.getReception(`?typeId=${id}&noPage=true`)
            .then((response) => {
                dispatch(httpLoadingEnd());
                dispatch(setReception(response));
            })
            .catch(err => {
            });
    };
};
export const getReceptionStatistic = (id) => {
    return (dispatch, getState) => {
        dispatch(httpLoadingStart());
        MayorProgramApiService.getReceptionStatistic(`?typeId=${id}&noPage=true`)
            .then((response) => {
                dispatch(httpLoadingEnd());
                dispatch(setReceptionStatistic(response));
            })
            .catch(err => {
            });
    };
};
