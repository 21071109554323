import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom"
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Store from './store/store';
import { uk } from 'date-fns/locale'


const app = (
    <Provider store={Store}>
        <BrowserRouter>
            <MuiPickersUtilsProvider locale={uk} utils={DateFnsUtils}>
                <App />
            </MuiPickersUtilsProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
serviceWorker.register();
