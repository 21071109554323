import React, {useState} from "react";
import "./Input.scss";
import {TextField} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import When from "../When"
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
const Input = props => {

    const { elementConfig, onChange, onBlur, value, errorMessage, invalid, shouldValidate, touched } = props;
    const [type, updateType] = useState(elementConfig.type);
    const [showPassword, updateShowPassword] = useState(false);

    let error = false;
    if (invalid && shouldValidate && touched) {
        error = true;
    }

    const togglePassword = () => {
        const newValue = !showPassword;
        updateShowPassword(newValue);
        updateType(newValue ? 'text' : 'password');
    };

    return (
        <div className='input-wrapper'>
            <TextField
                error={error}
                icon={'time'}
                className="input"
                onChange={onChange}
                onBlur={onBlur}
                {...elementConfig}
                type={type}
                value={value}
                helperText={error ? errorMessage : null}>
            </TextField>
            <When is={elementConfig.type === 'password'}>
                <button className='show-password' onClick={togglePassword}>
                    {
                        showPassword ? <VisibilityOffOutlinedIcon style={{color: '#768489'}}/> :  <VisibilityOutlinedIcon style={{color: '#768489'}}/>
                    }

                </button>
            </When>
        </div>
    )
};

export default Input;
