import {createReducer} from "@reduxjs/toolkit";
import {
    httpLoadingStart,
    httpLoadingEnd
} from '../actions/actionCreators'

const initialState = {
    loading: false
};

const reducer = createReducer(initialState, {
    [httpLoadingStart]: (state) => {
        state.loading = true
    },
    [httpLoadingEnd]: (state) => {
        state.loading = false
    },
})

export default reducer;
