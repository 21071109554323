import {createSelector} from '@reduxjs/toolkit'
import {setFavoritesDataEvents} from "../actions/actionCreators";
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear
} from "date-fns";

const favoriteListSelector = state => state.favorites.favoritesList;
const date = new Date()
const selectDatePeriod = (period) => {
    switch (period.period) {
        case 'month':
            return {
                startDate: format(startOfMonth(date),`yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfMonth(date),`yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'week':
            return {
                startDate: format(startOfWeek(date, {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfWeek(date, {weekStartsOn: 1}),`yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'day':
            return {
                startDate: format(startOfDay(date),`yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date),`yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'year':
            return {
                startDate: format(startOfYear(date),`yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfYear(date),`yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'allTime':
            return {
                startDate: format(new Date('1970-01-01T03:00:00'),`yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`),
            }
        case 'datePeriod':
            return {
                startDate: period.startDate,
                endDate: period.endDate
            }
        default:
            return {
                startDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                endDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss')
            }
    }
}
export const sortFavorite = createSelector([favoriteListSelector],(favorites) => {

    const clFav = favorites.results && favorites.results.filter(res=> res.data.type === 'classifier')
    const clFavTop = favorites.results && favorites.results.filter(res=> res.data.type === 'classifier-top')
    const distrFav = favorites.results && favorites.results.filter(res=> res.data.type === 'district')
    const eventFav = favorites.results && favorites.results.filter(res=> res.data.type === 'event-details')
    const productsFav = favorites.results && favorites.results.filter(res=> res.data.type === 'products')
    const generalStatisticsFav = favorites.results && favorites.results.filter(res=> res.data.type === 'general-statistics')
    const generalStatisticsDistrictFav = favorites.results && favorites.results.filter(res=> res.data.type === 'general-statistics-district')

    const clFavWIthDate = clFav.map(favorite => {
        return {
            ...favorite,
            datePeriod: selectDatePeriod(favorite.data.timePeriod)
        }
    })
    const clFavTopWIthDate = clFavTop.map(favorite => {
        return {
            ...favorite,
            datePeriod: selectDatePeriod(favorite.data.timePeriod)
        }
    })

    const distrFavWIthDate = distrFav.map(favorite => {
        return {
            ...favorite,
            datePeriod: selectDatePeriod(favorite.data.timePeriod)
        }
    })

    const generalStatisticsFavWIthDate = generalStatisticsFav.map(favorite => {
        return {
            ...favorite,
            datePeriod: selectDatePeriod(favorite.data.timePeriod)
        }
    })
    const generalStatisticsDistrictFavWIthDate = generalStatisticsDistrictFav.map(favorite => {
        return {
            ...favorite,
            datePeriod: selectDatePeriod(favorite.data.timePeriod)
        }
    })

    const favoritesData = {
        classifiersFavorite: clFavWIthDate,
        classifiersTopFavorite: clFavTopWIthDate,
        districtsFavorite: distrFavWIthDate,
        eventsFavorite: eventFav,
        productsFavorite: productsFav,
        generalStatsFavorite: generalStatisticsFavWIthDate,
        generalStatsFavoriteDistrict: generalStatisticsDistrictFavWIthDate
    }
    return favoritesData
})

export const mergeFavorite =  (state) => {
    const { favoritesListClassifier, favoritesListDistrict, favoritesListEvents, favoritesListProducts, favoritesListGeneralStatistics, favoritesListGeneralStatisticsDistrict, favoritesListClassifierTop } = state.favorites;
    const classifiers = state.filters.classifiers;
    const accidentCountByClassifiers = state.stats.accidentCountByClassifiers;
    const accidentCountByDistrict = state.stats.accidentCountByDistrict;
    const classifierChildrenTitleById = {};
    const classifiersDistrictIds = {};
    const eventsFavoriteId = {};
    const favoritesListIds = state.favorites.favoritesList;
    const accidentClassifiersIds = {}
    const accidentDistrictIds = {}
    accidentCountByClassifiers && accidentCountByClassifiers.forEach((accident)=>{
        accidentClassifiersIds[accident.classifier_ids[0]] = accident.count
    })
    accidentCountByDistrict.forEach((accident)=>{
        accidentDistrictIds[accident.district_ids[0]] = accident.count
    })
    const districts = {
        1: 'Немишлянський',
        2: 'Індустріальний',
        3: 'Шевченківський',
        4: 'Київський',
        5: 'Московський',
        6: 'Холодногірський',
        7: 'Новобаварський',
        8: 'Основ\'янський',
        9: 'Слобідський',
    }
    classifiers.forEach((classifier)=>{
        classifier.children && classifier.children.forEach((classifierChild)=>{
            classifierChildrenTitleById[classifierChild.id] = classifierChild.title
            classifierChild.children && classifierChild.children.forEach((childrenOfChildren)=>{
                classifierChildrenTitleById[childrenOfChildren.id] = childrenOfChildren.title
            })
        })
    })
    // favoritesListIds.length && favoritesListIds.results.filter((id)=> {
    //     if(id.data.type === 'district'){
    //         classifiersDistrictIds[id.data.districtIds] = id.data.classifiers
    //     }
    // })
    const districtsList = favoritesListDistrict.map((statistic, i) => {
        const mergedArray = {
            results: []
        }
        const notSolved = []
        const statusesArray = []

        statistic.results.forEach(status => {
            if(status.id === 11){
                mergedArray.results.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.results.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})

        return {
            ...mergedArray, title: {
                type: 'district',
                id: statistic.results[0].district_ids[0],
                text: districts[statistic.results[0].district_ids[0]],
                classifiers: classifiersDistrictIds[statistic.results[0].district_ids[0]],
                accidentCount: accidentDistrictIds[statistic.results[0].district_ids[0]],
            }
        }
    })
    const classifiersList = favoritesListClassifier.map((statistic, i) => {
        const mergedArray = {
            results: []
        }
        const notSolved = []
        const statusesArray = []

        statistic.results.forEach(status => {
            if(status.id === 11){
                mergedArray.results.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.results.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})
        const classifierId = statistic.results[0].classifier_ids[0];
        return {
            ...mergedArray, title: {
                type: 'classifier',
                id: classifierId,
                text: classifierChildrenTitleById[classifierId],
                accidentCount: accidentClassifiersIds[classifierId],
                // datePeriod: selectDatePeriod()
            }
        }
    })
    const parentIds = classifiers.map(items => {
        const ids = {
            title: '',
            idsArray: []
        };
        ids.title = items.title

        if(!items.children){
            ids.idsArray.push(items.id)
        }
        items.children && items.children.map(child => {
            if(!child.children){
                ids.idsArray.push(child.id)
            }
            child.children && child.children.map(childOfChild => ids.idsArray.push(childOfChild.id))
        })
        return ids
    })

    const classifiersListTop = favoritesListClassifierTop.map((statistic, i) => {
        const mergedArray = {
            results: []
        }
        const notSolved = []
        const statusesArray = []

        statistic.results.forEach(status => {
            if(status.id === 11){
                mergedArray.results.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.results.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})
            const classifierId = statistic.results[0].classifier_ids[0];

        function topCategoryTitle(stats){
            const title =  parentIds.find(item => {
                if(item.idsArray.slice().sort().toString() === stats.results[0].classifier_ids.slice().sort().toString()){
                    return item.title
                }
            })
            return title.title
        }

        return {
            ...mergedArray, title: {
                type: 'classifier-top',
                id: classifierId,
                text: topCategoryTitle(statistic),
                // accidentCount: accidentClassifiersIds[classifierId],
                // datePeriod: selectDatePeriod()
            }
        }
    })

    const favoritesListGeneralStatisticsDistrictList = favoritesListGeneralStatisticsDistrict.map((statistic, i) => {
        const mergedArray = {
            results: []
        }
        const notSolved = []
        const statusesArray = []

        statistic.results.forEach(status => {
            if(status.id === 11){
                mergedArray.results.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.results.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})
        return {
            ...mergedArray
        }
    })
    const eventFav = favoritesListIds.results && favoritesListIds.results.filter(res=> res.data.type === 'event-details')
    const favoriteEventIds = {}

    eventFav && eventFav.forEach((fav)=>{
        favoriteEventIds[fav.data.eventId] = fav.id
    })

    const eventsList = favoritesListEvents.map((event, i) => {
        return {
            ...event, title: {
                type: 'events',
                favoriteId: favoriteEventIds[event.id]
            }
        }
    })


    const productsFav = favoritesListIds.results && favoritesListIds.results.filter(res=> res.data.type === 'products')
    const favoriteProductsFavIds = {}

    productsFav && productsFav.forEach((product)=>{
        favoriteProductsFavIds[product.data.productId] = product.id
    })
    const productList = favoritesListProducts.map((product, i) => {
        return {
            productList: product,
            title: {
                type: 'events',
                favoriteId: favoriteProductsFavIds[product[0].type.id],
            }
        }
    })

    const favoritesList = {
        districts: districtsList,
        classifiers: classifiersList,
        classifiersTop: classifiersListTop,
        events: eventsList,
        productsList: productList,
        generalStatisticsList: favoritesListGeneralStatistics,
        generalStatisticsDistrictList: favoritesListGeneralStatisticsDistrictList
    }
    return favoritesList
}

