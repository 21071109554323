import {createReducer} from "@reduxjs/toolkit";
import {
    setProductsList,
    setProductsTypes,
    setType,
    setProductStatistic
} from './../actions/actionCreators'
const initialState = {
    productsTypes: [],
    products: {
        productsList: [],
        count: null
    },
    typeId: null,
    typeName: '',
    productDetails: {
        id: null,
        title: "",
        growth_rate: null,
        growth_percent: null,
        type: {},
        latest_price: {
            market_price: null,
            supermarket_price: null,
            price: null,
            date: null
        }
    }

};

const reducer = createReducer(initialState, {
    [setProductsTypes]: (state, action) => {
        state.productsTypes = action.payload.results
    },
    [setProductsList]: (state, action) => {
        state.products = {
            productsList: action.payload,
            count: action.payload.count
        }
    },
    [setType]: (state, action) => {
        state.typeId = action.payload.typeId
        state.typeName = action.payload.typeName
    },
    [setProductStatistic]: (state, action) => {
        state.productDetails = action.payload
    },
})

export default reducer;
