import {createSelector} from '@reduxjs/toolkit'

const eventsSelector = state => state.events.events;
const favoritesListSelector = state => state.favorites.favoritesList;

export const getEvents = createSelector([eventsSelector,favoritesListSelector],(events, favoritesList) => {
    const favoriteListEventsIds = {};
    const favoriteIds = {};
    favoritesList && favoritesList.results.forEach((favorite)=>{
        favoriteListEventsIds[favorite.data.eventId] = true;
        favoriteIds[favorite.data.eventId] = favorite.eventId
    })
    return events.result.map((event, i) => {
        return {
            ...event, title: {
                favoriteId: favoriteIds[event.event_id],
                id: event.id,
                isFavorite: favoriteListEventsIds[event.event_id]
            }
        }
    })
})
