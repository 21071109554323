import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import './MayorProgram.scss'
import Header from "../../components/Header/Header";
import Search from "../../components/search/Search";
import {useDispatch, useSelector} from "react-redux";
import {getReceptionTypes, getReceptionById} from "../../store/actions/mayorProgram";
import {setType} from '../../store/actions/actionCreators'
import * as _ from "lodash";

const MayorProgram = () => {
    const dispatch = useDispatch();

    const {
        receptionTypes
    } = useSelector(state => {
        return state.mayorProgram;
    });

    useEffect(() => {
        dispatch(getReceptionTypes())
    }, []);

    const [types, updateTypes] = useState([]);

    // const search = (event) => {
    //     const value = event.target.value.toLowerCase();
    //     let clonedProductsTypes = _.cloneDeep(productsTypes);
    //     clonedProductsTypes = clonedProductsTypes.filter(type => {
    //         const title = type.name.toLowerCase();
    //         const typeMatch = value.length ? title.indexOf(value) > -1 : true;
    //         return typeMatch;
    //     });
    //     updateTypes(clonedProductsTypes);
    // };
    return (
        <div className='page-wrapper mayor-program'>
            <Header
                showFilters={false}
                headerTitle={'Звернення до міського голови'}/>
            <div className="container">
                {/*<div className="search-container">*/}
                {/*    <Search onChange={search}/>*/}
                {/*</div>*/}
                <div>
                    <ul className='list-content'>
                        {
                            receptionTypes && receptionTypes.length ? receptionTypes.map((type,i)=> {
                                return (
                                    <li key={i}>
                                        <Link to={`/dashboard/main-departments/mayor-program/${type.id}`} className='item-link'>
                                            {type.name }
                                            <div className='counter-arrow'>
                                                {/*<span>{type.count}</span>*/}
                                                <img className='arrow-right' src={require(`../../assets/images/arrow-right-green.svg`)} alt=""/>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            }) : null
                        }

                    </ul>
                </div>
            </div>
        </div>
    )
};

export default MayorProgram;
