import { createReducer } from "@reduxjs/toolkit";
import {
    authStart,
    authSuccess,
    authFail,
    logoutSuccess,
    setUser,
    setUserSettings,
    setChangePasswordSuccess, updateStartDate, updateEndDate, setPickerState
} from '../actions/actionCreators'
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    format
} from "date-fns";
import {updateUserSettings} from "../actions/auth";

const initialState = {
    token: null,
    refreshToken: null,
    user: {
        email: null,
        settings:{
            classifiers: [],
            timePeriod: {},
        }
    },
    error: null,
    passwordChanged: false,
    datePeriod: {
        startDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
        endDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`)
    }
};
const date = new Date()

const selectDatePeriod = (period) => {
    switch (period.period) {
        case 'month':
            return {
                startDate: format(startOfMonth(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfMonth(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'week':
            return {
                startDate: format(startOfWeek(date, {weekStartsOn: 1}), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfWeek(date, {weekStartsOn: 1}), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'day':
            return {
                startDate: format(startOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'year':
            return {
                startDate: format(startOfYear(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfYear(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'allTime':
            return {
                startDate: format(new Date('1970-01-01T03:00:00'), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'datePeriod':
            return {
                startDate: period.startDate,
                endDate: period.endDate
            }
        default:
            return {
                startDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`)
            }
    }
}
const reducer = createReducer(initialState, {
    [authStart]: (state) => {
        state.error = null;
    },
    [authSuccess]: (state, action) => {
        state.token = action.payload.token;
        state.refreshToken = action.payload.refreshToken;
        state.error = null;
    },
    [setUser]: (state, action) => {
        state.user = action.payload;
        state.datePeriod = selectDatePeriod(action.payload.settings.timePeriod)
    },
    [setUserSettings]: (state, action) => {
        state.user = {
            ...action.payload.user,
            settings: {...action.payload.settings},
            timePeriod: {...action.payload.timePeriod},
        }
        state.datePeriod = selectDatePeriod(action.payload.settings.timePeriod)
    },
    [authFail]: (state, action) => {
        state.error = action.payload
    },
    [logoutSuccess]: (state) => {
        state.token = null;
        state.refreshToken = null;
        state.passwordChanged = false;
    },
    [setChangePasswordSuccess]: (state) => {
        state.passwordChanged = true;
    },
    [updateStartDate]: (state, action) => {
        state.datePeriod.startDate = action.payload
    },
    [updateEndDate]: (state, action) => {
        state.datePeriod.endDate = action.payload
    },
})

export default reducer;
