import React, {useEffect, useState} from "react";
import Header from "../../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    getCurrentFilters,
    getStatistics,
    getGeneralStatisticsDistricts
} from "../../../store/selectors/stats";
import {
    getSummaryStatisticsByClassifiers,
    getSummaryStatisticsDistricts, getTopLevelStatistics
} from "../../../store/actions/stats";
import GeneralStatistic from './GeneralStatistic'
import StatisticByClassifiers from './StatisticByClassifiers'
import When from "../../../components/When";
import * as _ from "lodash";
import {getClassifiers} from "../../../store/actions";
import {getFiltersIds, getParentCategories} from "../../../store/selectors/filters";

const StatisticByDistricts = () => {
    const dispatch = useDispatch();
    const statistics = useSelector(getStatistics)
    const generalDistrictStatistics = useSelector(getGeneralStatisticsDistricts)
    const filters = useSelector(getCurrentFilters)
    const parentIds = useSelector(getParentCategories)
    const ids = useSelector(getFiltersIds)

    const filtersIds = useSelector(getFiltersIds)
    const params = useParams()
    const [generalStatistics, updateStatistics] = useState([]);
    const [currentFilters, setCurrentFilters] = React.useState([]);
    const [showAll, setShowAll] = useState(false);

    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });
    const {
        currentFilters: {classifierIds}, statisticsCounter, topLevelStatistic
    } = useSelector(state => {
        return state.stats;
    });
    const {
        classifiers
    } = useSelector(state => {
        return state.filters;
    });
    useEffect(() => {
            dispatch(getSummaryStatisticsDistricts(params.district_id, params.classifiers ? params.classifiers : parentIds, params.startDate, params.endDate, params.isAccident))
            dispatch(getTopLevelStatistics(params.district_id, params.isSecondLevel === 'true' ? JSON.parse("[" + params.classifiers + "]") : parentIds, params.startDate, params.endDate, params.isAccident ))
    }, [params, parentIds]);

    useEffect(() => {
        updateStatistics(statistics);
    }, [statistics]);


    useEffect(() => {
        setCurrentFilters(filters);
    }, [filters]);
    const visibleFilters = currentFilters.slice(0, showAll ? currentFilters.length : 5);

    return (
        <div id='scrolled' className='page-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Загальна статистика за районом'}/>
            <div className="content service-page">
                <div className="container">
                    {/*<ul className='current-filters-list'>*/}
                    {/*    {*/}
                    {/*        visibleFilters.map((filter) => {*/}
                    {/*            return (*/}
                    {/*                <li key={filter.title}>*/}
                    {/*                    <span className='item no-drop'>*/}
                    {/*                        {filter.title}*/}
                    {/*                    </span>*/}
                    {/*                </li>*/}
                    {/*            )*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*    <When is={currentFilters.length > 5}>*/}
                    {/*        <li>*/}
                    {/*            <span*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowAll(!showAll)*/}
                    {/*                }}*/}
                    {/*                className='item show-more'>*/}
                    {/*                {*/}
                    {/*                    !showAll ? ('Показати ще ' + (currentFilters.length - visibleFilters.length)) : 'Сховати'*/}
                    {/*                }*/}
                    {/*            </span>*/}
                    {/*        </li>*/}
                    {/*    </When>*/}
                    {/*</ul>*/}
                    <GeneralStatistic
                        statisticsCount={statisticsCounter}
                        statistics={generalDistrictStatistics}/>
                    <StatisticByClassifiers district_id={params.district_id} parentIds={parentIds}/>
                </div>
            </div>
        </div>
    )
};

export default StatisticByDistricts;
