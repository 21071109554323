import React  from "react";
import "./ListDetails.scss";
import Icon from "@material-ui/core/Icon";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import When from '../When'

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}

const ListDetails = ({events,addToFavorite, removeFromFavorite}) => {
    return (
        events.length ? events.map((item, i) => {
            return (
                <div key={i.id} className="card-details events">
                    <div className='card-header'>
                        <span className='card-title'>
                            <h3 className='alert-title'>{item.classifiers.length ? item.classifiers[0].title : '--'}</h3>
                            <When is={item.is_accident}>
                                 <span className='opened-time'>Аварiйна заявка</span>
                            </When>
                        </span>
                        {
                            item.title.isFavorite ?
                                <Icon onClick={() => removeFromFavorite(item)}
                                      style={{color: '#DE8F00'}}>star</Icon>
                                :
                                <Icon onClick={()=> addToFavorite(item)}
                                      style={{color: '#D8D8D8'}}>star_border</Icon>
                        }
                    </div>
                    <ul className='details-list'>
                        <li>Id <span>{item.id}</span></li>
                        <li>Дата та час <span>{format(parseISO(item.created_at), 'yyyy-MM-dd hh:mm:ss')}</span></li>
                        <li>Адрес <span className='adress'>{item.address.district && item.address.district.name ? item.address.district.name + ' район,' : '--'} {item.address && item.address.street && item.address.street.name ? item.address.street.name : '--'} {item.address.house && item.address.house  ? item.address.house : ''} {item.address.entrance !== null ? `під'їзд ${item.address.entrance}` : null}</span></li>
                        <li>Тип житла <span>{item.housing_type ? item.housing_type.name : '--'}</span></li>
                        <li>Мiсце проблеми <span>{item.place ? item.place.name && item.place.name : '--'}</span></li>
                        <li>Тип заявки <span className='alert-color adress'>{item.request_type ? item.request_type.name : '--'}</span></li>
                        <li>Статус <span style={{color: colors[item.status && item.status.id]}}>{item.status ? item.status.name : '--'}</span></li>
                        <li>Джерело  <span>{item.source ? item.source.name : '--'}</span></li>
                        <li>NPS <span className='alert-color'>2</span></li>
                        <li>Вiдповiдальний <span className='adress'>{item.owner ? item.owner.name : '--'}</span></li>
                        <li>Категорія <span className='category-title'>{item.classifiers.length ? item.classifiers[0].title : '--'}</span></li>
                    </ul>
                </div>
            )

        }) : null
    )
};

export default ListDetails;
