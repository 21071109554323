import {AppConstants} from "../../App.Constants";
import {AuthApiService} from "../../api/auth.api";
import { authStart,
    authSuccess,
    authFail,
    logoutSuccess,
    setUser,
    setUserSettings,
    httpLoadingStart,
    httpLoadingEnd,
    setChangePasswordSuccess} from './actionCreators'
import { getFavorite } from '../actions/favorites'

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        dispatch(httpLoadingStart());
        AuthApiService.login(email, password)
            .then(response => {
                const token = response.access;
                const refreshToken = response.refresh;
                localStorage.setItem(AppConstants.keys.authToken, token);
                localStorage.setItem(AppConstants.keys.refreshToken, refreshToken);
                dispatch(authSuccess({token, refreshToken}));
                dispatch(getUser());
                dispatch(getFavorite());
            })
            .catch(err => {
                dispatch(authFail(err.response.data.detail));
                dispatch(httpLoadingEnd());
            });
    };
};

export const refresh = (refreshToken) => {
    return dispatch => {
        dispatch(authStart());
        dispatch(httpLoadingStart());
        AuthApiService.refresh(refreshToken)
            .then(response => {
                const token = response.access;
                localStorage.setItem(AppConstants.keys.authToken, token);
                dispatch(authSuccess({token, refreshToken}));
                dispatch(getUser());
            })
            .catch(err => {
                // dispatch(authFail(err.response.data.detail));
                dispatch(httpLoadingEnd());
            });
    };
};
export const logout = () => {
    return dispatch => {
        localStorage.removeItem(AppConstants.keys.authToken);
        localStorage.removeItem(AppConstants.keys.refreshToken);
        localStorage.removeItem(AppConstants.keys.email);
        dispatch(logoutSuccess())
        dispatch(httpLoadingEnd());
    }
}
export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem(AppConstants.keys.authToken);
        const refreshToken = localStorage.getItem(AppConstants.keys.refreshToken);
        if (!token || !refreshToken) {
            dispatch(logout());
        } else {
            dispatch(refresh(refreshToken));
            dispatch(httpLoadingEnd());
        }
    };
};

export const getUser = () => {
    return dispatch => {
        AuthApiService.getUser()
            .then(response => {
                localStorage.setItem(AppConstants.keys.email,response.email);
                dispatch(setUser(response));
            })
            .catch(err => {
                // dispatch(authFail(err.response.data.detail));
                dispatch(httpLoadingEnd());
            }).finally(() => dispatch(httpLoadingEnd()));
    };
};

export const updateUserSettings = (settings) => {
    return dispatch => {
        dispatch(httpLoadingStart());
        AuthApiService.updateUserSettings(settings)
            .then(response => {
                dispatch(setUserSettings(response));

            })
            .catch(err => {

            }).finally(() => dispatch(httpLoadingEnd()));
    };
};
export const changeUserPassword = (data) => {
    return dispatch => {
        dispatch(httpLoadingStart());
        AuthApiService.changePassword(data)
            .then(response => {
                dispatch(setChangePasswordSuccess(response));
            })
            .catch(err => {

            }).finally(() => dispatch(httpLoadingEnd()));
    };
};
