import {createSelector} from '@reduxjs/toolkit'
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear
} from 'date-fns'
import {func} from "prop-types";
const statisticsSelector = state => state.stats.statisticsByClassifiers;
const accidentCountByClassifiersSelector = state => state.stats.accidentCountByClassifiers;
const accidentCountByDistrictSelector = state => state.stats.accidentCountByDistrict;
const generalStatisticsSelector = state => state.stats.summaryStatistics;
const generalDistrictStatisticsSelector = state => state.stats.summaryStatisticsDistrict;
const statisticsByDistrictSelector = state => state.stats.districtStatistics;
const classifiersSelector = state => state.filters.classifiers;
const favoritesListSelector = state => state.favorites.favoritesList;
const timePeriodFromSettingsSelector = state => state.auth.user.settings.timePeriod;

const districts = {
    1: 'Немишлянський',
    2: 'Індустріальний',
    3: 'Шевченківський',
    4: 'Київський',
    5: 'Московський',
    6: 'Холодногірський',
    7: 'Новобаварський',
    8: 'Основ\'янський',
    9: 'Слобідський',
}
const date = new Date();

const selectDatePeriod = (period) => {
    switch (period.period) {
        case 'month':
            return {
                startDate: format(startOfMonth(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfMonth(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'week':
            return {
                startDate: format(startOfWeek(date, {weekStartsOn: 1}), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfWeek(date, {weekStartsOn: 1}), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'day':
            return {
                startDate: format(startOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'year':
            return {
                startDate: format(startOfYear(date), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfYear(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'allTime':
            return {
                startDate: format(new Date('1970-01-01T03:00:00'), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(endOfDay(date), `yyyy-MM-dd'T'HH:mm:ss`)
            }
        case 'datePeriod':
            return {
                startDate: period.startDate,
                endDate: period.endDate
            }
        default:
            return {
                startDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
                endDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`)
            }
    }
}
export const getStatisticsByDistrict = createSelector([statisticsByDistrictSelector, favoritesListSelector, accidentCountByDistrictSelector, timePeriodFromSettingsSelector],(statistics, favoritesList, accidentCountByDistrict, timePeriodFromSettingsSelector) => {
    const favoriteListDistrictsIds = []
    const accidentDistrictIds = {}
    accidentCountByDistrict.forEach((accident)=>{
        accidentDistrictIds[accident.district_ids[0]] = accident.count
    })
    favoritesList && favoritesList.results.forEach((favorite, i)=>{
        const selectFavorite = {
            districtId: favorite.data.districtIds,
            classifiers: favorite.data.classifiers,
            favoriteId: favorite.id,
            isAccident: favorite.data.isAccident,
            timePeriod: favorite.data.timePeriod
        }
        favoriteListDistrictsIds.push(selectFavorite)
    })
    return statistics.slice().sort((a,b)=>{
        return b.results[0].events_count - a.results[0].events_count
    }).map((statistic, i) => {
        const sumCount = statistic && statistic.results && statistic.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)

        const mergedArray = {
            results: []
        }
        const notSolved = []
        const statusesArray = []

        statistic.results.forEach(status => {
            if(status.id === 11){
                mergedArray.results.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.results.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})

        const districtId = statistic && statistic.results.length ? statistic.results[0].district_ids[0] : null;
        const classiiersArray = statistic && statistic.results.length && statistic.results[0].classifier_ids ? statistic.results[0].classifier_ids : null;
        const statsAccident = statistic.results.length ? statistic.results[0].is_accident : null
        const classifiersArr = []
        if(classiiersArray !== null){
            for (let i in classiiersArray){
                classifiersArr.push(classiiersArray[i])
            }
        }
        const sertedClassifiers = classifiersArr.sort()
        function isFavorite() {
            let isFavorite = favoriteListDistrictsIds.some((favorite)=>{
                const startDate = timePeriodFromSettingsSelector ? timePeriodFromSettingsSelector.startDate : null;
                const dates = selectDatePeriod(favorite.timePeriod)
                const endDate = timePeriodFromSettingsSelector.endDate;
                const period = timePeriodFromSettingsSelector.period;
                const periodFavorite = favorite.timePeriod ? favorite.timePeriod.period : null;

                const favoriteClassifiers = favorite.classifiers && favorite.classifiers.length ? favorite.classifiers : null;
                const favoriteClassifierArr = []

                if(favoriteClassifiers !== null){
                    for (let i in favoriteClassifiers){
                        favoriteClassifierArr.push(favoriteClassifiers[i])
                    }
                }

                const sortedFavoriteArray = favoriteClassifierArr.sort()
                return districtId === favorite.districtId && sertedClassifiers.toString() === sortedFavoriteArray.toString() && statsAccident === favorite.isAccident && startDate === dates.startDate && endDate === dates.endDate && period === periodFavorite
            })
            return isFavorite
        }

        return {
            ...mergedArray,
            sumCount: sumCount,
            title: {
                id: districtId,
                accidentCount: accidentDistrictIds[districtId],
                text: districts[districtId],
                isFavorite:  isFavorite(),
            }
        }
    })
})

export const getGeneralStatistics = createSelector([generalStatisticsSelector, favoritesListSelector, timePeriodFromSettingsSelector],(summaryStatistics, favoritesList, timePeriodFromSettingsSelector) => {
    const favoriteLisIds = []
    favoritesList && favoritesList.results.forEach((favorite, i)=>{
        const selectFavorite = {
            districtId: favorite.data.districtIds,
            classifiers: favorite.data.classifiers,
            favoriteId: favorite.id,
            isAccident: favorite.data.isAccident,
            type: favorite.data.type,
            timePeriod: favorite.data.timePeriod ? favorite.data.timePeriod : null
        }
        favoriteLisIds.push(selectFavorite)
    })

    return summaryStatistics.statistics.map((statistic, i) => {
        const classiiersArray = statistic.classifier_ids && statistic.classifier_ids.length ? statistic.classifier_ids : null;
        const classifiersArr = []
        if(classiiersArray !== null){
            for (let i in classiiersArray){
                classifiersArr.push(classiiersArray[i])
            }
        }
        const sertedClassifiers = classifiersArr.sort()
        function isFavorite() {
            let isFavorite = favoriteLisIds.some((favorite)=>{
                const startDate = timePeriodFromSettingsSelector.startDate;
                const dates = selectDatePeriod(favorite.timePeriod)
                const endDateFavorite = favorite.timePeriod ? dates.endDate : null
                const startDateFavorite = favorite.timePeriod ? dates.startDate : null
                const endDate = timePeriodFromSettingsSelector.endDate;
                const period = timePeriodFromSettingsSelector.period;
                const periodFavorite = favorite.timePeriod ? favorite.timePeriod.period : null;

                if(favorite.type === 'general-statistics'){
                    const favoriteClassifier = favorite.classifiers && favorite.classifiers.length ? favorite.classifiers : null;
                    const favoriteClassifierArr = []
                    if(favoriteClassifier !== null){
                        for (let i in favoriteClassifier){
                            favoriteClassifierArr.push(favoriteClassifier[i])
                        }
                    }

                    const sortedFavoriteArray = favoriteClassifierArr.sort()

                    return sertedClassifiers.toString() === sortedFavoriteArray.toString() && favorite.isAccident === statistic.is_accident && startDate === dates.startDate && endDate === dates.endDate && period === periodFavorite
                }
            })
            return isFavorite
        }
        return {
            ...statistic, title: {
                isFavorite: isFavorite()
            }
        }
    })
})
export const getGeneralStatisticsDistricts = createSelector([generalDistrictStatisticsSelector, favoritesListSelector, timePeriodFromSettingsSelector],(summaryStatisticsDistrict, favoritesList, timePeriodFromSettingsSelector) => {
    const favoriteLisIds = []
    favoritesList && favoritesList.results.forEach((favorite, i)=>{
        const selectFavorite = {
            districtId: favorite.data.district_id,
            classifiers: favorite.data.classifiers,
            isAccident: favorite.data.isAccident,
            timePeriod: favorite.data.timePeriod
        }
        favoriteLisIds.push(selectFavorite)
    })
    return summaryStatisticsDistrict.statistics.map((statistic, i) => {

        const districtId = statistic.district_ids.toString();
        const is_accident = statistic.is_accident;
        const classiiersArray = statistic.classifier_ids && statistic.classifier_ids.length ? statistic.classifier_ids : null;
        const classifiersArr = []
        if(classiiersArray !== null){
            for (let i in classiiersArray){
                classifiersArr.push(classiiersArray[i])
            }
        }
        const sertedClassifiers = classifiersArr.sort()

        function isFavorite() {
            let isFavorite = favoriteLisIds.some((favorite)=>{
                const startDate = timePeriodFromSettingsSelector.startDate;
                const dates = selectDatePeriod(favorite.timePeriod)
                const endDateFavorite = favorite.timePeriod ? dates.endDate : null
                const startDateFavorite = favorite.timePeriod ? dates.startDate : null
                const endDate = timePeriodFromSettingsSelector.endDate;
                const period = timePeriodFromSettingsSelector.period;
                const periodFavorite = favorite.timePeriod ? favorite.timePeriod.period : null;
                const favoriteClassifier = favorite.classifiers && favorite.classifiers.length ? favorite.classifiers : null;
                const favoriteClassifierArr = []
                if(favoriteClassifier !== null){
                    for (let i in favoriteClassifier){
                        favoriteClassifierArr.push(favoriteClassifier[i])
                    }
                }
                const sortedFavoriteArray = favoriteClassifierArr && favoriteClassifierArr.sort()
                return sertedClassifiers.toString() === sortedFavoriteArray.toString() && districtId === favorite.districtId && is_accident === favorite.isAccident && startDate === dates.startDate && endDate === dates.endDate && period === periodFavorite
            })
            return isFavorite
        }

        return {
            ...statistic, title: {
                isFavorite: isFavorite()
            }
        }
    })
})
export const getStatistics = createSelector([statisticsSelector, classifiersSelector, favoritesListSelector, generalStatisticsSelector, accidentCountByClassifiersSelector, timePeriodFromSettingsSelector],(statistics, classifiers, favoritesList, summaryStatistics, accidentCountByClassifiersSelector, timePeriodFromSettingsSelector) => {
    const classifierChildrenTitleById = {};
    const favoriteListClassifiersIds = {};
    // const favoriteListClassifiersIdsFavorite = {};
    const accidentClassifiersIds = {}
    const favoriteStatsArray = [];
    favoritesList && favoritesList.results.forEach((favorite, i)=>{
        const selectFavorite = {
            classifiers: favorite.data.classifierIds,
            favoriteId: favorite.id,
            isAccident: favorite.data.isAccident,
            timePeriod: favorite.data.timePeriod
        }
        favoriteStatsArray.push(selectFavorite)
    })
    classifiers && classifiers.forEach((classifier)=>{
        classifierChildrenTitleById[classifier.id] = classifier.title
        classifier.children && classifier.children.forEach((classifierChild)=>{
            classifierChildrenTitleById[classifierChild.id] = classifierChild.title
            classifierChild.children && classifierChild.children.forEach(childOfChild => {
                classifierChildrenTitleById[childOfChild.id] = childOfChild.title
            })
        })
    })
    accidentCountByClassifiersSelector.forEach((accident)=>{
        accidentClassifiersIds[accident.classifier_ids[0]] = accident.count
    })
    favoritesList && favoritesList.results.forEach((favorite)=>{
        favoriteListClassifiersIds[favorite.data.classifierIds] = true
    })
    return statistics.slice().sort((a,b)=>{
        return b.results[0].events_count - a.results[0].events_count
    }).map((statistic, i) => {
        const sumCount = statistic && statistic.results && statistic.results.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        const mergedArray = {
            results: []
        }
        const notSolved = []
        const statusesArray = []
        statistic.results.forEach(status => {
            if(status.id === 11){
                mergedArray.results.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.results.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})
        const classifierId = statistic.results && statistic.results[0].classifier_ids[0];
        function isFavorite() {
            let isFavorite = favoriteStatsArray.some((favorite)=>{
                const startDate = timePeriodFromSettingsSelector.startDate;
                const dates = selectDatePeriod(favorite.timePeriod)
                const endDateFavorite = favorite.timePeriod ? dates.endDate : null
                const startDateFavorite = favorite.timePeriod ? dates.startDate : null
                const endDate = timePeriodFromSettingsSelector.endDate;
                const period = timePeriodFromSettingsSelector.period;
                const periodFavorite = favorite.timePeriod ? favorite.timePeriod.period : null;
                return classifierId === favorite.classifiers && favorite.isAccident === statistic.results[0].is_accident && startDate === dates.startDate && endDate === dates.endDate && period === periodFavorite
            })
            return isFavorite
        }
        return {
            ...mergedArray,
            sumCount: sumCount,
            title: {
                id: classifierId,
                text: classifierChildrenTitleById[classifierId],
                isFavorite: isFavorite(),
                accidentCount: accidentClassifiersIds[classifierId]
            }
        }
    })
})
export const getCurrentFilters = (state) => {
    const selected = state.auth.user.settings.classifiers;
    const classifiers = state.filters.classifiers;
    const selectedFilters = [];
    classifiers && classifiers.length && classifiers.forEach((classifier)=>{
        selected.length && selected.forEach((selectedId)=> {
            if(selectedId === classifier.id){
                selectedFilters.push(classifier)
            }
            classifier.children && classifier.children.forEach((classifierChild)=>{
                if(classifierChild.id === selectedId){
                    selectedFilters.push(classifierChild)
                }
                classifierChild.children && classifierChild.children.forEach((childOfChild)=>{
                    if(childOfChild.id === selectedId){
                        selectedFilters.push(childOfChild)
                    }

                })
            })
        })

    });
    return selectedFilters;
}
const getClassifiers = (state) => state.filters.classifiers;
const GetTopLevelStatistics = (state) => state.stats.topLevelStatistic;
export const GetTopLevelStats = createSelector([getClassifiers,GetTopLevelStatistics], (classifiers, statistic) =>{
    const selectedFilters = [];
    classifiers.forEach((classifier)=>{
        selectedFilters.push(classifier)
        classifier.children && classifier.children.forEach((classifierChild)=>{
            selectedFilters.push(classifierChild)
            classifierChild.children && classifierChild.children.forEach((childOfChild)=>{
                selectedFilters.push(childOfChild)
            })
        })
    });
    function getTitle(stats) {
        return selectedFilters.filter(filter => {
            if(filter.id.toString() === stats[0].classifier_ids[0].toString()){
                return filter.title
            }
        })
    }
    const statsWithTitles = statistic.map(stats => {
        const mergedArray = []
        const notSolved = []
        const statusesArray = []

        stats.forEach(status => {
            if(status.id === 11){
                mergedArray.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})
        const sum = stats.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        return {
            arr: [...mergedArray],
            sumCount: sum,
            title: getTitle(stats)
        }
    })
    return statsWithTitles
})

const GetCategoryByStatus = (state) => state.stats.categoryByStatus
export const getCategoryByStatus = createSelector([getClassifiers, GetCategoryByStatus], (classifiers, statistic) =>{
    const selectedFilters = [];
    classifiers.forEach((classifier)=>{
        selectedFilters.push(classifier)
        classifier.children && classifier.children.forEach((classifierChild)=>{
            selectedFilters.push(classifierChild)
            classifierChild.children && classifierChild.children.forEach((childOfChild)=>{
                selectedFilters.push(childOfChild)
            })
        })
    });
    const parentIds = classifiers.map(items => {
        const ids = {
            title: '',
            idsArray: []
        };
        ids.title = items.title

        if(!items.children){
            ids.idsArray.push(items.id)
        }
        items.children && items.children.map(child => {
            ids.idsArray.push(child.id)
            child.children && child.children.map(childOfChild => ids.idsArray.push(childOfChild.id))
        })
        return ids
    })
    function getTitle(item) {
        if(item.classifier_ids.length === 1){
            return selectedFilters && selectedFilters.filter(filter => {
                if(filter.id.toString() === item.classifier_ids.toString()){
                    return filter.title
                }
            })
        }else{
            const newParentsIds = []
            parentIds && parentIds.forEach(parentId => {
                const titleItem = {
                    title: parentId.title,
                    idsArray: []
                }
                parentId.idsArray.forEach(id => {
                    item && item.classifier_ids && item.classifier_ids.forEach(userItem => {
                        if(id === userItem){
                            titleItem.idsArray.push(id)
                        }
                    })
                })
                if(titleItem.idsArray.length){
                    newParentsIds.push(titleItem)
                }
            })
            return newParentsIds;
            // return parentIds && parentIds.filter(filter => {
            //     console.log('filter.idsArray',filter.idsArray)
            //     console.log('item.classifier_ids',item.classifier_ids)
            //     if(filter.idsArray.toString() === item.classifier_ids.toString()){
            //         console.log('filter',filter)
            //         return filter.title
            //     }
            // })
        }

    }
    return  statistic.map((item)=> {
        return {
            ...item,
            title: getTitle(item)
        }
    })

})
const GetTopLevelStatistic = (state) => state.stats.topLevelStatistic
export const getTopLevelStatisticsData = createSelector([getClassifiers, GetTopLevelStatistic, favoritesListSelector, timePeriodFromSettingsSelector], (classifiers, statistic, favoritesList, timePeriodFromSettingsSelector) =>{
    const parentIds = classifiers.map(items => {
        const ids = {
            title: '',
            idsArray: []
        };
        ids.title = items.title

        if(!items.children){
            ids.idsArray.push(items.id)
        }
        items.children && items.children.map(child => {
            if(!child.children){
                ids.idsArray.push(child.id)
            }
            child.children && child.children.map(childOfChild => ids.idsArray.push(childOfChild.id))
        })
        return ids
    })

    function getTitle(statisticItem) {
        return parentIds.find(item => {
            if(item.idsArray.toString() === statisticItem[0].classifier_ids.toString()){
                return item.title
            }
        })
    }

    return statistic.map((item)=> {

        const mergedArray = []
        const notSolved = []
        const statusesArray = []

        item.forEach(status => {
            if(status.id === 11){
                mergedArray.push({...status, name: 'Вирішено'})
            }else{
                notSolved.push(status)
            }
        })
        const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        mergedArray.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})

        function isFavorite() {
            const classifierId = item && item[0].classifier_ids;
            const favoriteStatsArray = [];
            favoritesList && favoritesList.results.forEach((favorite, i)=>{
                const selectFavorite = {
                    classifiers: favorite.data.classifierIds && favorite.data.classifierIds ? favorite.data.classifierIds : favorite.data.classifiers,
                    favoriteId: favorite.id,
                    isAccident: favorite.data.isAccident,
                    timePeriod: favorite.data.timePeriod
                }
                favoriteStatsArray.push(selectFavorite)
            })
            let isFavorite = favoriteStatsArray.some((favorite)=>{
                const startDate = timePeriodFromSettingsSelector.startDate;
                const dates = selectDatePeriod(favorite.timePeriod)
                const endDateFavorite = favorite.timePeriod ? dates.endDate : null
                const startDateFavorite = favorite.timePeriod ? dates.startDate : null
                const endDate = timePeriodFromSettingsSelector.endDate;
                const period = timePeriodFromSettingsSelector.period;
                const periodFavorite = favorite.timePeriod ? favorite.timePeriod.period : null;

                return classifierId.toString() === favorite.classifiers.toString() && favorite.isAccident === item[0].is_accident && startDate === dates.startDate && endDate === dates.endDate && period === periodFavorite
            })
            return isFavorite
        }
        const sum = item.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
        return {
            statistics: mergedArray,
            title: getTitle(item),
            sumCount: sum,
            isFavorite: isFavorite(item)
        }
    })
})
