import React from "react";
import "./RequestsSummaryStatusLine.scss";
const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}
const zIndexs = {
    1: 10,
    2: 9,
    3: 8,
    4: 7,
    5: 6,
    6: 5,
    7: 4,
    8: 3,
    9: 2,
    10: 1
}

const RequestsSummaryStatusLine = props => {
    let { data } = props;
    const sum = data.length && data.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next);
    return (
        <div className="requests-summary-status-line-wrapper">
            {
                sum !== 0 ?
                    <div className='requests-summary-status-line'>
                        {data.length ? data.map(item => {
                            const val = item.events_count
                            const percent = (val*100)/sum
                            return (
                                percent !== 0 ?  <div key={item.id} style={{backgroundColor: item.id === 11 ? '#138481' : '#FD0101',height: 20, zIndex: zIndexs[item.id], width: `${percent}%`}}></div> : null
                            )
                        }) : null}
                    </div> : <div className='line-overlay'></div>
            }
        </div>
    )
};

export default RequestsSummaryStatusLine;
