import axios from '../App.Axios';
import {AppConstants} from "../App.Constants";

export class AuthApiService {
    static login(email, password) {
        return new Promise((resolve, reject) => {
            axios.post(AppConstants.api.apiBaseUrl + 'users/token/', {email, password})
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };

    static refresh(refresh) {
        return new Promise((resolve, reject) => {
            axios.post(AppConstants.api.apiBaseUrl + 'users/token-refresh/', {refresh})
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };

    static getUser() {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + 'users/details/')
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };

    static updateUserSettings(settings) {
        return new Promise((resolve, reject) => {
            axios.patch(AppConstants.api.apiBaseUrl + 'users/partial-update/', {settings})
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static changePassword(data) {
        return new Promise((resolve, reject) => {
            axios.put(AppConstants.api.apiBaseUrl + 'users/update/', {"password": data})
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
}

