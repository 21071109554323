import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as authActions from '../../store/actions/auth';
import {
    Link
} from "react-router-dom";
import './Profile.scss';
import Icon from "@material-ui/core/Icon";
import Header from "../../components/Header/Header";
import {setAccidentState} from "../../store/actions/actionCreators";
import {AppConstants} from "../../App.Constants";
const Profile = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setAccidentState(false))
    }, [])
    const logout = () => {
        dispatch(authActions.logout());
    };
    const { user } = useSelector(state => {
        return state.auth;
    });
    const userEmail = localStorage.getItem(AppConstants.keys.email);

    return (
        <div className='profile-page'>
            <Header
                showFilters={false}
                headerTitle={'Профайл користувача'}/>
            <div className='content'>
                <div className='user-info'>
                    {/*<span className='user-name'>{user.first_name} {user.last_name}</span>*/}
                    <span className='user-email'>Електронна пошта: </span>
                    <span className='user-email'>{userEmail}</span>
                </div>
                <ul className='profile-list'>
                    <li>
                        <Link to={'/dashboard/main-departments/favorites'} className='link'>
                            Улюбленi
                            <Icon style={{color: '#4359f5'}}>keyboard_arrow_right</Icon></Link>
                    </li>
                    <li>
                        <Link to={'/dashboard/main-departments/profile/changePassword'} className='link'>
                            Змiнити пароль
                            <Icon style={{color: '#4359f5'}}>keyboard_arrow_right</Icon></Link>
                    </li>
                    <li>
                        <Link className='link logout' variant="contained" color="secondary" onClick={logout}>
                            Вихід з облікового запису
                        </Link>
                    </li>
                </ul>
            </div>
            {/*<div className='app-version'>Версiя 1.0</div>*/}
        </div>  
    )
};

export default Profile;
