import { createMuiTheme } from '@material-ui/core/styles';

export const MaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4359f5',
        },
        secondary: {
            main: '#FF0000',
        },
    },
    typography: {
        fontFamily: [
            'San Francisco Text Regular',
            'SF Pro Display',
        ].join(','),
    },
});
