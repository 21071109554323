import React from "react";
import "./LoadingSpinner.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSelector} from "react-redux";
import When from "../When";
import Spiner from '../../assets/images/logo-spinner.gif'

const LoadingSpinner = () => {

    const isLoading = useSelector(state => {
        return state.http.loading;
    });

    return (
        <When is={isLoading}>
            <div className="loading-spinner-overlay">
                <img src={Spiner} alt="Spiner"/>
                {/*<CircularProgress size={90}/>*/}
            </div>
        </When>
    )
};

export default LoadingSpinner;
