import React, {useState} from "react";
import RequestsSummaryStatusLine from "../../../components/requestsSummaryStatusLine/RequestsSummaryStatusLine";
import {Link, useParams} from "react-router-dom";
import When from "../../../components/When";
import Icon from "@material-ui/core/Icon";
import {useDispatch, useSelector} from "react-redux";
import {addToFavorite, removeFavoriteGeneralStatistics} from "../../../store/actions/favorites";
import {getFiltersIds} from "../../../store/selectors/filters";

const districts = {
    1: 'Немишлянський',
    2: 'Індустріальний',
    3: 'Шевченківський',
    4: 'Київський',
    5: 'Московський',
    6: 'Холодногірський',
    7: 'Новобаварський',
    8: 'Основ\'янський',
    9: 'Слобідський',
}

const colors = {
    1: '#FD0101',
    2: '#D21626',
    3: '#B4233E',
    4: '#953358',
    5: '#744273',
    6: '#54508C',
    7: '#3560A8',
    8: '#1D7698',
    9: '#1A9099',
    10: '#138481',
    11: '#0E8D74',
    12: '#0D9051',
    13: '#0D9026',
    14: '#23A80F'
}

const GeneralStatistic = ({statisticsCount, statistics}) => {
    const params = useParams()
    const dispatch = useDispatch();
    const filtersIds = useSelector(getFiltersIds)
    const [isFavorite, updateFavorite] = useState(false);
    const sum = statistics.length ? statistics.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next) : '--';

    const {
        accidentCount, accidentState
    } = useSelector(state => {
        return state.stats;
    });
    const {
        user: {
            settings: {
                classifiers,
                timePeriod: {
                    period, startDate, endDate
                }
            }
        }
    } = useSelector(state => {
        return state.auth;
    });
    const toggleFavorite = (title, districtId) => {
        const data = {
            title: title,
            district_id: districtId,
            district: true,
            type: 'general-statistics-district',
            classifiers: classifiers && classifiers.length ? classifiers : filtersIds.resultArraysId,
            isAccident: accidentState ? true : null,
            timePeriod: {
                period: period,
                startDate: startDate,
                endDate: endDate
            }

        }
        dispatch(addToFavorite(data))
        updateFavorite(!isFavorite)

        setTimeout(() => {
            updateFavorite(false)
        }, 2000)
    }
    const removeFromGeneralStats = (statistics) =>{
        dispatch(removeFavoriteGeneralStatistics(statistics))
    }
    const mergedArray = []
    const notSolved = []
    const statusesArray = []

    statistics.forEach(status => {
        if(status.id === 11){
            mergedArray.push({...status, name: 'Вирішено'})
        }else{
            notSolved.push(status)
        }
    })
    const summaryCount = notSolved.length && notSolved.map(sumItem => sumItem.events_count).reduce((prev, next) => prev + next)
    mergedArray.push({...notSolved[0], id: [1,2,3,4,5,6,7,8,9,10,12,13,14], name: 'У роботі', events_count: summaryCount})
    return (
        <div className="requests-summary">
            <div className="card-details">
                <div className='card-header'>
                    <When is={isFavorite}>
                        <div className='success-alert'>
                            Картка добавлена в улюблене
                        </div>
                    </When>
                    <div className='card-title'>
                        <span className='title-name'>
                           Загальна кiлькiсть звернень ({districts[params.district_id].toLowerCase()} район)
                        </span>
                    </div>
                    {
                        statistics.length && statistics[0].title.isFavorite ?
                            <Icon onClick={() => removeFromGeneralStats(statistics)}
                                  style={{color: '#DE8F00'}}>star</Icon>
                            :
                            <Icon onClick={() => toggleFavorite(districts[params.district_id].toLowerCase(),params.district_id )}
                                  style={{color: '#D8D8D8'}}>star_border</Icon>
                    }
                </div>
                <div className="card-status-line">
                    <RequestsSummaryStatusLine summary={statisticsCount} data={mergedArray}/>
                </div>
                <div className='count general-count'>
                    <span>Усього</span>
                    <span className='summary-text'>{sum}</span>
                </div>
                <ul className='details-list'>
                    {
                        mergedArray && mergedArray.map((status) => {
                            return (
                                <li>
                                    <When is={status.events_count === 0}>
                                        <div className='overlay'></div>
                                    </When>
                                    <Link
                                        // to={params.classifier_ids ? `/dashboard/main-departments/service-requests/category-district-details/district-category-status-classifiers/${params.district_id}/${status.id}/${params.classifier_ids}/${status.created_after}/${status.created_before}/${params && params.isAccident ? params.isAccident : accidentState === false ? null : true}` :`/dashboard/main-departments/service-requests/category-district-details/district-category-status/${params.district_id}/${status.id}/${status.created_after}/${status.created_before}/${params && params.isAccident ? params.isAccident : accidentState === false ? null : true}`}
                                        to={params.classifiers ? `/dashboard/main-departments/service-requests/sub-category/${params.classifiers}/${status.created_after}/${status.created_before}/${params.isAccident}/general-districts/${params.district_id}/${params.isSecondLevel}/${status.id}/` :`/dashboard/main-departments/service-requests/category-district-details/district-category-status/${params.district_id}/${status.id}/${status.created_after}/${status.created_before}/${params.isAccident}`}
                                        className='list-item'>
                                        {status.name}
                                        <span
                                            style={{color: status.id === 11 ? '#138481' : '#FD0101'}}>{status.events_count}</span>
                                        <Icon className='arrow-right'>keyboard_arrow_right</Icon>
                                    </Link>
                                </li>

                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
};

export default GeneralStatistic;
