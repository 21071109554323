import {createReducer} from "@reduxjs/toolkit";
import {
    getFavoriteSuccess, setFavoritesDistrict, setFavoritesDataClassifier, setFavoritesDataClassifierTop,
    setFavoritesDataDistrict, removeFavoriteSuccess, setFavoritesDataEvents, addToFavoriteSuccess,
    setFavoritesDataProducts, setFavoritesGeneralStatistic, setFavoritesGeneralStatisticDistrict
} from "../actions/actionCreators";

const initialState = {
    favoritesList: {
        results: []
    },
    favoritesListData: [],
    favoritesListDataDistrict: [],
    favoritesListClassifier: [],
    favoritesListClassifierTop: [],
    favoritesListDistrict: [],
    favoritesListEvents: [],
    favoritesListProducts: [],
    favoritesListGeneralStatistics: [],
    favoritesListGeneralStatisticsDistrict: []
};

const reducer = createReducer(initialState, {
    [getFavoriteSuccess]: (state, action) => {
        state.favoritesList = {
            results: action.payload
        }
    },
    [setFavoritesDataClassifier]: (state, action) => {
        state.favoritesListClassifier = action.payload
    },
    [setFavoritesDataClassifierTop]: (state, action) => {
        state.favoritesListClassifierTop = action.payload
    },
    [setFavoritesDataDistrict]: (state, action) => {
        state.favoritesListDistrict = action.payload
    },
    [setFavoritesDataEvents]: (state, action) => {
        state.favoritesListEvents = action.payload
    },
    [setFavoritesDataProducts]: (state, action) => {
        state.favoritesListProducts = action.payload
    },
    [setFavoritesGeneralStatistic]: (state, action) => {
        state.favoritesListGeneralStatistics = action.payload
    },
    [setFavoritesGeneralStatisticDistrict]: (state, action) => {
        state.favoritesListGeneralStatisticsDistrict = action.payload
    },
    [removeFavoriteSuccess]: (state, action) => {
        state.favoritesList.results = state.favoritesList.results.filter((favorite)=> favorite.id !== action.payload)
    },
    [setFavoritesDistrict]: (state, action) => {
        state.favoritesListDataDistrict = action.payload
    },
    [addToFavoriteSuccess]: (state, action) => {
        state.favoritesList.results.push(action.payload)
    },
})


export default reducer;
