import axios from '../App.Axios';
import {AppConstants} from "../App.Constants";
import * as actionTypes from "../store/actions/actionTypes";

export class FavoriteApiService {
    static addToFavorite(data) {
        return new Promise((resolve, reject) => {
            axios.post(AppConstants.api.apiBaseUrl + `favorites/`, {data})
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static removeFavorite(id) {
        return new Promise((resolve, reject) => {
            axios.delete(AppConstants.api.apiBaseUrl + `favorites/${id}/` )
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
    static getFavoriteList(data) {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + `favorites/?${data}`)
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
}

