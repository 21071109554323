import React, {useEffect} from "react";
import '../../assets/scss/main.scss'
import DepartmentCard from "../../components/departmentCard/DepartmentCard";

import Footer from '../../components/Footer/Footer'
import './MainDepartments.scss';
import {useDispatch, useSelector} from "react-redux";
import { footerNavMainDepartment } from '../../textConstants/textConstants'
import Header from "../../components/Header/Header";
import {getFavorite} from "../../store/actions/favorites";
import {setAccidentState} from "../../store/actions/actionCreators";
import {getSummaryStatistics, getDistrictsFn} from '../../store/actions/stats';
import {getGeneralStatistics} from "../../store/selectors/stats";
import {getUser} from "../../store/actions/auth";
import http from "../../store/reducers/http";
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear
} from "date-fns";
import {getClassifiers} from "../../store/actions";
import {getProductsTypes} from "../../store/actions/economics";
const serviceDepartment = [
    {
        link: '/dashboard/main-departments/service-requests',
        departmentName: '1562',
        completedValue: 23
    },
    {
        link: '/',
        departmentName: 'Мiський голова',
        completedValue: 58
    }
]
const economicsDepartment = [
    {
        link: '/dashboard/main-departments/economics-service',
        departmentName: 'Продукти',
        completedValue: 78
    },
]

const MainDepartments = () => {
    const dispatch = useDispatch();
    const generalStats = useSelector(getGeneralStatistics)

    const {
        user: {
            settings
        }
    } = useSelector(state => {
        return state.auth;
    });

    const {
        loading
    } = useSelector(state => {
        return state.http;
    });
    useEffect(() => {
        dispatch(setAccidentState(false))
        dispatch(getDistrictsFn())
        dispatch(getFavorite())
        dispatch(getProductsTypes())

    }, [dispatch]);
    useEffect(() => {
        dispatch(getSummaryStatistics([1, 2, 3, 4, 5, 6, 7, 8, 9], settings.classifiers))
    }, [settings]);
    return (
        <div id='scrolled-cont' className='page-wrapper'>
            <Header
                showFilters={false}
                headerTitle={'Показники департаментiв'}/>
            <div className='content'>
                <div className="content-inner">
                    <DepartmentCard
                        settings={settings.timePeriod}
                        loading={loading}
                        className={'statistic'}
                        showStats={true}
                        generalStatistics={generalStats}
                        link={'/dashboard/main-departments/service-requests'}
                        departmentTitle='Харківська міська диспетчерська служба 1562'
                        // background={'service-department'}
                        department={serviceDepartment}/>
                    <DepartmentCard
                        link={'/dashboard/main-departments/economics-service/product-list/'}
                        departmentTitle='Ціни на соціальні товари'
                        // background={'economic-department'}
                        department={economicsDepartment}/>
                    <DepartmentCard
                        link={'/dashboard/main-departments/mayor-program'}
                        departmentTitle='Звернення до міського голови'
                        // background={'service-department'}
                        department={economicsDepartment}/>
                </div>
            </div>
            <Footer footerNav={footerNavMainDepartment}/>
        </div>
    )
};

export default MainDepartments;
