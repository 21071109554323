import React from "react";
import "./DepartmentList.scss";
import {
    Link, useParams
} from "react-router-dom";
import Icon from "@material-ui/core/Icon";

const DepartmentList = ({districtStatistics, isDistrict}) => {
    const params = useParams()
    return (
        <div className='department-list'>
            <ul>
                {districtStatistics.length && districtStatistics.map(item => {
                    if(item.district_id !== null){
                        return (
                            <li key={item.district_id} className='department-list-item'>
                                <Link
                                    to={params.isDistrict === 'true' ? `/dashboard/main-departments/service-requests/sub-category/${params.classifiers}/${params.startDate}/${params.endDate}/classifier/${params.id}/district/${true}/sub-categories/${item.district_id}/${params.isAccident}/` : `/dashboard/main-departments/service-requests/sub-category/${params.classifiers}/${params.startDate}/${params.endDate}/classifier/${params.id}/district/${false}/${item.district_id}/events-details/${params.isAccident}/`}
                                >
                                    <div className='department-list-header'>
                                        <span className='category-name'>{item.district_name}</span>
                                        <span className='info-col'>
                                        <span className='summary'>
                                                <span className='sum'>{item.events_count}</span>
                                        </span>
                                        <Icon style={{color: '#4359f5'}}>keyboard_arrow_right</Icon>
                                    </span>
                                    </div>
                                </Link>
                            </li>
                        )
                    }
                })}
            </ul>
        </div>
    )
};

export default DepartmentList;
