import {AppConstants} from "../../App.Constants";
import {createReducer} from "@reduxjs/toolkit";
import {
    toggleFilters,
    closeFilters,
    updateStartDate,
    updateEndDate,
    setClassifiers,
    setPickerState
} from '../actions/actionCreators'
import {
    startOfWeek,
    endOfWeek,
    endOfMonth,
    startOfMonth,
    startOfDay,
    endOfDay,
    startOfYear,
    endOfYear, format
} from "date-fns";

const initialState = {
    show: false,
    // startDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
    // endDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
    classifiers: [],
    selected: [],
    pickerState: ''
};

const reducer = createReducer(initialState, {
    [toggleFilters]: (state, action) => {
        state.show = action.payload
    },
    [closeFilters]: (state) => {
        state.show = false
    },
    [setPickerState]: (state, action) => {
        state.pickerState = action.payload
    },
    [setClassifiers]: (state, action) => {
        state.classifiers = action.payload
    },
})
export default reducer;
