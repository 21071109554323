import axios from '../App.Axios';
import {AppConstants} from "../App.Constants";

export class FiltersApiService {
    static getAll() {
        return new Promise((resolve, reject) => {
            axios.get(AppConstants.api.apiBaseUrl + 'dispatching_service/objects/classifiers/?pageSize=9999')
                .then((data) => {
                        resolve(data.data);
                    }
                )
                .catch(error => reject(error))
        });
    };
}

